/*
 * ResultMatrixDateOptions Messages
 *
 * This contains all the text for the ResultMatrixDateOptions component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  setstartDate: {
    id: 'app.components.ResultMatrixDateOptions.setstartDate',
    defaultMessage: 'Set Start Date',
  },
  setendDate: {
    id: 'app.components.ResultMatrixDateOptions.setendDate',
    defaultMessage: 'Set End Date',
  },
  saveFromTo: {
    id: 'app.components.ResultMatrixDateOptions.save',
    defaultMessage: 'Confirm',
  },
  startDate: {
    id: 'app.components.ResultMatrixDateOptions.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.components.ResultMatrixDateOptions.endDate',
    defaultMessage: 'End Date',
  },
  granularity: {
    id: 'app.components.ResultMatrixDateOptions.granularity',
    defaultMessage: 'Granularity',
  },
  week: {
    id: 'app.components.ResultMatrixDateOptions.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.components.ResultMatrixDateOptions.day',
    defaultMessage: 'Day',
  },
  wzp: {
    id: 'app.components.ResultMatrixDateOptions.wzp',
    defaultMessage: 'WZP',
  },
  hour: {
    id: 'app.components.ResultMatrixDateOptions.hour',
    defaultMessage: 'Hour',
  },
  month: {
    id: 'app.components.ResultMatrixDateOptions.month',
    defaultMessage: 'Month',
  },
  shift: {
    id: 'app.components.ResultMatrixDateOptions.shift',
    defaultMessage: 'Shift',
  },
  includeMhe: {
    id: 'app.components.ResultMatrixDateOptions.includeMhe',
    defaultMessage: 'Calculate MHE (slower)',
  },
  includeBaseLine: {
    id: 'app.components.ResultMatrixDateOptions.includeBaseLine',
    defaultMessage: 'Calculate baseline',
  },
  includeForecast: {
    id: 'app.components.ResultMatrixDateOptions.includeForecast',
    defaultMessage: 'Calculate forecast',
  },
  includeSmartVolume: {
    id: 'app.components.ResultMatrixDateOptions.includeSmartVolume',
    defaultMessage: 'Calculate SmartVolume forecast',
  },
  includeRole: {
    id: 'app.components.ResultMatrixDateOptions.includeRole',
    defaultMessage: 'Calculate role',
  },
  includeWeekStartAsSunday: {
    id: 'app.components.ResultMatrixDateOptions.includeWeekStartAsSunday',
    defaultMessage: 'Calculate week from Sunday',
  },
  includeVolumeCategory: {
    id: 'app.components.ResultMatrixDateOptions.includeVolumeCategory',
    defaultMessage: 'Calculate Volume Category',
  },
  shiftWzp:{
    id: 'app.components.ResultMatrixDateOptions.shiftWzp',
    defaultMessage: 'WZP/Shift',
  }
});
