import { toast } from 'react-toastify';
import uniqid from 'uniqid';

import { apiCallEndedAction, apiCallStartedAction } from 'containers/App/actions';

import { checkAndHandleTokenExpired, extractReadableMessage } from './api';
import { fetchAccessToken } from './azure';
import { ACTIVE_ENV } from './activeEnv';


export async function fetchData(options, dispatch) {
  const callId = uniqid();
  dispatch(apiCallStartedAction(callId));
  const fullUrl = ACTIVE_ENV.basePathBe + options.url;
  const fetchOptions = { method: options.method };
  if (options.addToken) {
    const token = options.token || (await fetchAccessToken());
    if (!token) {
      // should not happen with Azure
      return { isOk: false, isForward: true };
    }
    fetchOptions.headers = { Authorization: `Bearer ${token}` };
  }
  if (options.body) {
    fetchOptions.body = options.body;
  }
  if (options.contentType) {
    fetchOptions.headers = { ...fetchOptions.headers, 'Content-Type': options.contentType };
  }

  const res = await fetch(fullUrl, fetchOptions);

  dispatch(apiCallEndedAction(callId));

  if (options.isFileDownload) {
    return res.blob().then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = options.isFileDownload;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); // afterwards we remove the element again
      return {};
    });
  }

  if (res.status >= 200 && res.status < 300) {
    const response = options.rawResponse ? res : await res.json();
    return { data: response, isOk: true, isForward: false };
  }

  if (res.status === 401) {
    return { ...res, isOk: false, isForward: true };
  }
  if (res.json) {
    try {
      const value = await res.json();
      if (checkAndHandleTokenExpired(res.status, value)) {
        return { ...res, isOk: false, isForward: true };
      }
      toast.error(extractReadableMessage(value));
    } catch (error) {
      toast.error('Failed to load the data, please refresh the page and try again');
    }
  } else if (res.message) {
    toast.error(res.message);
  } else {
    toast.error('Failed to load the data, please refresh the page and try again');
  }
  console.error('Fetch error', res);
  return { ...res, isOk: false, isForward: false };
}
