/**
 *
 * PlanDetailPage
 *
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Button from 'components/Button';
import { Form, Formik, getIn } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from 'components/IconButton';
import ActivitySettingsDirect from 'components/ActivitySettingsDirect';
import ActivitySettingsIndirect from 'components/ActivitySettingsIndirect';
import ActualVolume from 'components/ActualVolume';
import BasePage from 'components/BasePage';
import DayOfWeekTransformation, { isDayOfWeekTransformationActivity } from 'components/DayOfWeekTransformation';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import DialogDHL from 'components/Dialog';
import DialogDHLWarning from 'components/Dialog/DialogWarning';
import EffortForecast from 'components/EffortForecast';
import { PlanMheAvailability } from 'components/MheAvailability';
import PlanDetailActivitySettings from 'components/PlanDetailActivitySettings';
import PlanDetailLabourAvailability from 'components/PlanDetailLabourAvailability';
import PlanDetailPlannedVolume from 'components/PlanDetailPlannedVolume';
import PlanDetailWarning from 'components/PlanDetailWarning';
import PlanDetailShifts from 'components/PlanDetailShifts';
import PlanDetailToolbar from 'components/PlanDetailToolbar';
import { PlanDetailWorkzonePeriod } from 'components/PlanDetailWorkzonePeriod';
import { ProductivityRate } from 'components/ProductivityRate';
import { LabourAvailabilityOverviewPlan } from 'components/LabourAvailabilityOverview';
import { MHEAvailabilityOverviewPlan } from 'components/MHEAvailabilityOverview';
import { AdjustmentOverviewPlan } from 'components/AdjustmentsOverview';
import ShiftDefinition from 'components/ShiftDefinition';
import WZPDefinition from 'components/ShiftDefinition/WZPDefination';
import StaticSection from 'components/StaticSection';
import ToggleSection from 'components/ToggleSection';
import { UnproductiveLocationPlan } from 'components/UnproductiveActivities/location.tsx';
import { UnproductiveStaffPlan } from 'components/UnproductiveActivities/staff';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { SECTION, T_TYPE } from 'utils/constants';
import injectSaga from 'utils/injectSaga';
import withSecurity, { PERMISSIONS, ROLES } from 'utils/security';
import { loadSearch } from 'utils/url';

import styled from 'styled-components';
import { ShiftDistributionWarningDialog } from '../../components/ShiftDistributionWarningDialog';
import { areSomeShiftDaysRemovedAndUsed, areSomeWzpDaysRemovedAndUsed } from '../../utils/shiftUtils';
import { DayToHourTransformationPlan } from '../DayToHourTransformation';
import { DayToWzpTransformationPlan } from '../DayToWzpTransformation';
import { paValidationSchema } from '../PlanningAreaDetailPage/saga';
import { PlanTabs } from '../Tabs';
import createUploadModal from '../UploadModal';
import {
  clearMasterPlanConflict,
  clearPlanMHEAction,
  loadPlanAction,
  loadPlanDetails,
  loadPlanOverviewData,
  resetPlanBudgetAction,
  savePlan,
  savePlanBasicInfo,
  startNewAction,
  togglePlanSection,
  planDetailMasterPlanDetails,
  planDetailSmartProdVolumeRuns,
  saveLabourOverView,
  saveMheOverview,
  saveAdjustmentOverview,
  planDetailSchedules,
  openMasterPlanDialog,
  closeMasterPlanDialog,
  clearplanDetailMasterPlanDetails,
  omChangeHistory,
  saveomsChangeHistory,
} from './actions';
import BasicSettings from './BasicSettings';
import messages from './messages';
import saga from './saga';
import {
  selectGuiState,
  selectPlan,
  selectPlanningParameters,
  selectPlanDetailSmartRuns,
  selectPlanDetailMasterPlan,
  selectOffDays,
  selectPlanDetailSmartRunsLoader,
  selectPlanDetailsLoader,
  selectPlanDetailsLoaderFlag,
  selectPlanOverviewLoaderFlag,
  selectplanRationWarningFlag,
  selectplanRoles,
  selectPlanSmartCodeMappings,
  selectActualShowValue,
  selectActualShowFormula,
  selectPlanDetailSchedule,
  selectomsChangeHistory,
} from './selectors';
import { PlanAdjustments } from '../../components/Adjustments';
import { checkFormula } from './utils';
import { ACTIVE_ENV } from '../../utils/activeEnv';
import { PATHS } from '../App/constants';

const UploadModal = createUploadModal('planDetail');

const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  > span {
    padding: 10px;
    min-width: 200px;
    text-align: center;
  }
`;

const LoadingIndicatorWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  > span {
    padding: 10px;
    min-width: 200px;
    text-align: center;
  }
`;

const StyledButton = styled(IconButton)`
background: none;
box-shadow: none;
display: flex;
flex-direction: row-reverse;
padding: 16px 0px;
`;

const Space = styled.div`
  margin-left: 20px;
`;

const DialogNoPlansWarning = ({ open, closeDialog, openMasterPlanDialog, isAdmin, history }) => {
  const handleBackToListOfPlans = () => {
    history.push(PATHS.planList)
  }
  return (
    <DialogDHLWarning
      style={{ width: '250px' }}
      open={!!open}
      maxWidth="xs"
      headerLabel={messages.emptyHeader}
      CustomButtons={
        <React.Fragment key={1}>
          {
            isAdmin &&
            <Button onClick={() => closeDialog()}>
              <FormattedMessage {...messages.cancelButton} />
            </Button>
          }
          {
            !isAdmin &&
            <Button onClick={handleBackToListOfPlans}>
              <FormattedMessage {...messages.backToListOfPlans} />
            </Button>
          }
          <Button onClick={openMasterPlanDialog}>
            <FormattedMessage {...messages.masterPlanUpdateCreation} />
          </Button>
        </React.Fragment>
      }
    >
      <DialogTitle style={{padding: '16px 0px'}} id="nomasterplanswarning">
        {
          isAdmin &&
          <StyledButton icon="times" onClick={() => closeDialog()} />
        }
      </DialogTitle>
      <Space />
      <FormattedMessage {...messages.noMasterPlansWarning} />
    </DialogDHLWarning>
  )
}

export const Loading = () => {
  return (
    <LoadingWrap>
      <div className='loader'></div>
      <FormattedMessage {...messages.plandetailsLoader} />
    </LoadingWrap>
  );
};

export const LoadingIndicator = () => {
  return (
    <LoadingIndicatorWrap>
      <div className='loader'></div>
      <FormattedMessage {...messages.plandetailsLoaderFlag} />
    </LoadingIndicatorWrap>
  );
};

/* eslint-disable react/prefer-stateless-function */
class PlanDetailPage extends React.PureComponent {
  componentWillUnmount() {
    this.props.clearPlanMHEAction();
    this.props.closeMasterPlanDialog();
    this.props.clearplanDetailMasterPlanDetails();
    this.props.saveomsChangeHistory([]);
    sessionStorage.removeItem('businessDateChangedIds');
  }

  getUrlId = () => {
    const {
      props: {
        match: {
          params: { id },
        },
      },
    } = this;
    return Number(id);
  };

  componentDidMount() {
    const query = loadSearch(this.props);
    const isEdit = query.get('isEdit') === 'true';
    const id = Number(this.props.match.params.id);
    this.props.planDetailMasterPlanDetails(id);
    this.props.loadPlanAction({ id, edit: isEdit });
    this.props.planDetailSchedules(id);
    sessionStorage.removeItem('businessDateChangedIds');
  }

  componentDidUpdate(prevProps) {
    const query = loadSearch(this.props);
    const isEdit = query.get('isEdit') === 'true';
    const editMode = (this.props.guiState && this.props.guiState.get('edit')) || false;
    const id = Number(this.props.match.params.id);
    if ((prevProps.plansLoader !== this.props.plansLoader) && this.props.plansLoader === false) {
      this.props.loadPlanDetails({ id, edit: editMode });
    };
    if ((prevProps.plansLoaderFlag !== this.props.plansLoaderFlag) && this.props.plansLoaderFlag === false) {
      this.props.loadPlanOverviewData();
      if(this.props && this.props.plan && this.props.plan.planningParametersId){
          this.props.planDetailSmartProdVolumeRuns(this.props.plan.planningParametersId);
          this.props.omChangeHistory(this.props.plan.planningParametersId);
      }
    }
  }

  render() {
    const { intl, plan } = this.props;
    const urlId = this.getUrlId();
    const planId = plan && plan.id;
    const title = plan && plan.name ? plan.name : intl.formatMessage(messages.newPlan);

    return (
      <BasePage labelMessage={plan ? messages.header : messages.headerOnly} labelValues={{ name: title }}>
        <Helmet>
          <title>{intl.formatMessage(messages.header, { name: title })}</title>
          <meta name="description" content="Plan detail page" />
        </Helmet>
        {
          (planId === urlId && <FormDetail {...this.props} title={title} toggleProps={this.toggleProps} />)
        }
      </BasePage>
    );
  }

  toggleProps = sectionName => {
    const expanded = this.props.guiState.get('expanded');
    return {
      expanded: expanded.get(sectionName),
      onToggle: () => this.props.toggleSection(sectionName),
    };
  };
}

const FormDetail = props => {
  const { intl, title, toggleProps, user, planDetailMasterPlans } = props;
  const isAdmin = user.user.roles.find(r => r.role === ROLES.ADMIN.name);
  const masterPlanConflict = props.guiState.get('masterPlanConflict');
  const plan = useMemo(() => cloneDeep(props.plan), [props.plan]);
  const [shiftDaysRemovedWarning, setShiftDaysRemovedWarning] = useState(false);
  const [masterPlanWarning, setMasterPlanWarning] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (planDetailMasterPlans !== null) {
        if (planDetailMasterPlans?.length !== 0) {
          setMasterPlanWarning(false);
        }
        if (planDetailMasterPlans?.length === 0) {
          setMasterPlanWarning(true);
        }
      }
    }, 1000)
  }, [planDetailMasterPlans])

  const handleOpenMasterPlanWarningDialog = () => {
    setMasterPlanWarning(false);
    props.openMasterPlanDialog();
  }

  return (
    <Formik
      initialValues={plan}
      enableReinitialize
      validateOnChange={false}
      validationSchema={paValidationSchema(intl)}
      onSubmit={(values, formikBag) => {
        const shiftCollisionDecided = !!values.planningParameters.redistributeActivityTransformation;
        const isShift =  values.planningParameters.transformationType === 'SHIFT';
        if (
          shiftCollisionDecided ||
          (isShift ? !areSomeShiftDaysRemovedAndUsed(plan.planningParameters, values.planningParameters) : !areSomeWzpDaysRemovedAndUsed(plan.planningParameters, values.planningParameters)) 
        ) {
          props.save(values);
        } else {
          setShiftDaysRemovedWarning(true);
        }
        formikBag.setSubmitting(false);
      }}
      render={formikBag => (
        <Form>
          <EditableArea {...props} title={title} formikBag={formikBag} toggleProps={toggleProps} shiftDaysRemovedWarning={shiftDaysRemovedWarning}/>
          <Prompt when={formikBag.dirty} message={intl.formatMessage(messages.confirmDirty)} />
          <UploadModal
            entity="plans"
            currentId={props.plan.id}
            intl={intl}
            user={user}
            isAuditor={props.hasPerm(PERMISSIONS.HISTORY_PLAN)}
          />
          <FormikErrorsPopup />
          <DialogNoPlansWarning open={masterPlanWarning} openMasterPlanDialog={handleOpenMasterPlanWarningDialog} closeDialog={()=>setMasterPlanWarning(false)} isAdmin={isAdmin} history={props.history}/>
          {/* If there is a conflict with another master plan, user must confirm whether to overwrite it */}
          <DialogDHL
            open={masterPlanConflict != null}
            headerLabel={messages.masterPlanConflict}
            confirmLabel={messages.changeAnyway}
            onCancel={props.clearMasterPlanConflict}
            onConfirm={() => {
              props.clearMasterPlanConflict();
              formikBag.setFieldValue('forceUpdateMaster', 'true');
              formikBag.submitForm();
            }}
          >
            <FormattedMessage
              {...messages.masterPlanConflictDescription}
              values={{ names: masterPlanConflict && masterPlanConflict.masterPlansInCollision.join(', ') }}
            />
          </DialogDHL>
          <ShiftDistributionWarningDialog
            stateHook={[shiftDaysRemovedWarning, setShiftDaysRemovedWarning]}
            formikBag={formikBag}
          />
        </Form>
      )}
    />
  );
};

const EditableArea = props => {
  const { title, formikBag, toggleProps, guiState, hasPerm, save, saveLabourOverView, saveMheOverview, saveAdjustmentOverview, offDays, plansLoaderFlag, plansOverviewFlag, toggleActualVolumeFormula, toggleActualVolumeValue, showFormula, showValue, planDetailSchedulesData, shiftDaysRemovedWarning } = props;
  const editMode = (guiState && guiState.get('edit')) || false;
  const guiStateObject = guiState ? guiState.toJS() : {};
  const submitError = (guiState && guiState.get('submitError')) || false;
  const tTypeValue = formikBag && getIn(formikBag.values, 'planningParameters.transformationType', T_TYPE.WZP);
  const volumeGranularity = formikBag && getIn(formikBag.values, 'planningParameters.volumeGranularity');
  const startDay = formikBag && getIn(formikBag.values, 'planningParameters.startDay');
  const endDay = formikBag && getIn(formikBag.values, 'planningParameters.endDay');
  const planningParametersId = getIn(formikBag.values, 'planningParameters.id');
  const isAdjustmentsChecked = formikBag && getIn(formikBag.values, 'planningParameters.hasActivityAdjustments');
  const initialAdjustmentsChecked = formikBag && getIn(formikBag.initialValues, 'planningParameters.hasActivityAdjustments');
  const isForecastEditor = hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  const viewSettings = useSelector(selectViewModeStoredData);
  const activityForecastList =
    formikBag &&
    getIn(formikBag.values, 'planningParameters.activityForecastTable.rowData', []).map(activity => ({
      label: activity.activity,
      value: activity.id,
      uom: activity.uom,
      ForecastOutOfPlanDateInterval: activity?.ForecastOutOfPlanDateInterval,
    }));
  const activityForecastListWaring = [];
  const activityForecastListWaringZeroRatio = [];
  const activityForecastListWaringData = formikBag && getIn(formikBag.values, 'planningParameters.activityForecastTable.rowData', []).filter((activity) => activity?.ForecastOutOfPlanDateInterval);
  activityForecastListWaringData && activityForecastListWaringData.map((activity) => {
    const activityFoundZero = formikBag && formikBag.values && formikBag.values.effortForecast && formikBag.values.effortForecast.filter((ef) => ((ef.activityForecastId == activity.id) && ef.isOutOfPeriodForecasting));
    const activityFound = formikBag && formikBag.values && formikBag.values.effortForecast && formikBag.values.effortForecast.filter((ef) => ((ef.activityForecastId == activity.id) && !ef.isOutOfPeriodForecasting));
    if (activityFoundZero && activityFoundZero.length > 0) {
      activityForecastListWaringZeroRatio.push({activity: activity.activity, dateInterval: activityFoundZero[0].dateInterval});
    }
    if(activityFound && activityFound.length > 0){
      activityForecastListWaring.push({activity : activity.activity, dateInterval: activityFound[0].dateInterval});
    }
  }
  );
  // TODO: take from formikBag
  const isShift = tTypeValue === T_TYPE.SHIFT;
  const getFormikDataPathFun = periodIndex => `planningParameters.periods.${periodIndex}.labourCategoryParameters`;
  const getFormikDataPath = () => getFormikDataPathFun(guiState.get('periodIndex'));
  const calculateUsedLabourCategoriesIds = props => {
    const shiftsPath = `planningParameters.periods.${props.periodIndex}.realShiftsWithShift`;
    const shifts = getIn(props.formikBag.values, shiftsPath, []);
    const result = new Set();
    shifts.forEach(shift => {
      const transitions = shift.labourCategoryTransitions || [];
      transitions.forEach(transition => {
        if (transition && transition.labourCategory) {
          result.add(transition.labourCategory.id);
        }
      });
    });
    return [...result];
  };
  const usedLabourCategories = calculateUsedLabourCategoriesIds(props);
  const labourAvailabilityData = cloneDeep(getIn(props.formikBag.values, getFormikDataPath(), [])).map(row => ({
    ...row,
    used: usedLabourCategories.includes(row.labourCategory.id),
  })) || [];
  const fieldPath = (periodIndex) => `planningParameters.periods.${periodIndex}.mheAvailabilities`;
  let mheAvailablilityData = (getIn(formikBag.values, fieldPath(guiState.get('periodIndex')), []) || []).map((it, index) => ({ ...it, index })) || [];
  const getShiftFormikDataPath = (periodIndex) => `planningParameters.periods.${periodIndex}.realShiftsWithShift`;
  const ShiftWeeklyvalue = getIn(props.formikBag.values, getShiftFormikDataPath(guiState.get('periodIndex')));
  const ShiftWeeklyData = ShiftWeeklyvalue && ShiftWeeklyvalue.every(s => s && s.labourCategoryTransitions && s.labourCategoryTransitions.length == 0);
  const activitySettingsData = props.formikBag.values && props.formikBag.values.planningParameters && props.formikBag.values.planningParameters.periods && props.formikBag.values.planningParameters.periods.filter((ap) => ap.apCalculated && ap.apCalculated.direct && ap.apCalculated.direct.length > 0);
  const activityWarnings = checkFormula(props);
  const omsChangeHistoryWarnings = props?.omsChangeHistory?.filter((o)=>o.jobCodeName);
  return (
    <>
      <PlanDetailToolbar title={title} plansLoaderFlag={plansOverviewFlag}/>
      <BasicSettings formik={formikBag} submitError={submitError} planDetailSchedulesData={planDetailSchedulesData}/>
      <PlanDetailWarning planDetailSmartRuns={props.planDetailSmartRuns} planDetailMasterPlans={props.planDetailMasterPlans} smartProdRunsDetailLoader={props.planDetailSmartRunsLoader} planRatioWarning={props.planRatioWarning} planDetailPage={true} activityWarnings={activityWarnings} activityForecastListWaring={activityForecastListWaring} activityForecastListWaringZeroRatio={activityForecastListWaringZeroRatio} omsChangeHistory={omsChangeHistoryWarnings}/>
      {viewSettings.showVolumeSections && (
        <>
          <ToggleSection message={messages.plannedVolume} {...toggleProps(SECTION.PLANNED)}>
          { (plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> :
            <PlanDetailPlannedVolume editMode={editMode} />
          }
          </ToggleSection>
          <ToggleSection message={messages.actualVolume} {...toggleProps(SECTION.ACTUAL)}>
          { (plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> :
            <ActualVolume edit={guiState.get('edit')}/>
          }
          </ToggleSection>
        </>
      )}
      {viewSettings.showHoursSections && (
        <>
          {!isDayOfWeekTransformationActivity(formikBag) && isForecastEditor && (
            <ToggleSection message={messages.effortForecast} {...toggleProps(SECTION.EFFORT_FORECAST)}>
              { (plansOverviewFlag === true || plansOverviewFlag === undefined)  ? <LoadingIndicator/> :
              <EffortForecast
                formik={formikBag}
                startDay={startDay}
                endDay={endDay}
                planningParametersId={planningParametersId}
                activityForecastList={activityForecastList}
                edit={guiState.get('edit')}
                planningParameters={formikBag.values.planningParameters}
              />
              }
            </ToggleSection>
          )}
        </>
      )}

      <PlanTabs>
        {!isDayOfWeekTransformationActivity(formikBag) && volumeGranularity !== 'DAY' && (
          <ToggleSection message={messages.volumeCategoryTimeTrans} {...toggleProps(SECTION.VC_TIME_TRANS)} subSection>
            <DayOfWeekTransformation />
          </ToggleSection>
        )}
        {isShift ? (
          <ToggleSection message={messages.shiftDefinition} {...toggleProps(SECTION.SHIFT)} subSection>
            <ShiftDefinition values={formikBag.values} periodIndex={guiState.get('periodIndex')} formik={formikBag} shiftDaysRemovedWarning={shiftDaysRemovedWarning}/>
          </ToggleSection>
        ) : (
          <ToggleSection message={messages.workZonePeriod} {...toggleProps(SECTION.WZP)} subSection>
            <WZPDefinition values={formikBag.values} periodIndex={guiState.get('periodIndex')} formik={formikBag} shiftDaysRemovedWarning={shiftDaysRemovedWarning}/>
          </ToggleSection>
        )}
        <ToggleSection message={messages.activitySettings} {...toggleProps(SECTION.ACTIVITY)} subSection>
          { (plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> :
          <PlanDetailActivitySettings>
            <StaticSection message={messages.directActivities} {...toggleProps(SECTION.ACTIVITY)} subSection>
              <ActivitySettingsDirect formik={formikBag} roles={props.planRoles} />
            </StaticSection>
            <StaticSection message={messages.indirectActivities} {...toggleProps(SECTION.ACTIVITY)} subSection>
              <ActivitySettingsIndirect formik={formikBag} roles={props.planRoles}/>
              {!ACTIVE_ENV.shiftFillingDisabled && (
                <UnproductiveLocationPlan formik={formikBag} guiState={guiStateObject} />
              )}
              {!ACTIVE_ENV.shiftFillingDisabled && (
                <UnproductiveStaffPlan formik={formikBag} guiState={guiStateObject} />
              )}
            </StaticSection>
          </PlanDetailActivitySettings>
          }
        </ToggleSection>
        <ToggleSection message={messages.ttTables} {...toggleProps(SECTION.TIME)} subSection>
          {isDayOfWeekTransformationActivity(formikBag) && <DayOfWeekTransformation />}
          {formikBag.values.planningParameters.transformationType === 'HOUR' && <DayToHourTransformationPlan />}
          {(formikBag.values.planningParameters.transformationType === 'WZP' || isShift) && (
            <DayToWzpTransformationPlan />
          )}
        </ToggleSection>
        {isAdjustmentsChecked && initialAdjustmentsChecked &&
          <ToggleSection message={messages.activityAdjustments} {...toggleProps(SECTION.ADJUSTMENTS)} subSection>
            { (plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> :
            <PlanAdjustments />
           }
          </ToggleSection>
        }
        {viewSettings.checkboxLabourAvailable && (
          <ToggleSection message={messages.labourAvailabilityTitle} {...toggleProps(SECTION.LABOUR)} subSection>
            { (plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> :
            <PlanDetailLabourAvailability formik={formikBag} />
            }
            <PlanDetailShifts
              values={formikBag.values}
              periodIndex={guiState.get('periodIndex')}
              formik={formikBag}
              isShift={isShift}
              roles={props.planRoles}
            />
          </ToggleSection>
        )}
        {viewSettings.checkboxMHE && (
          <ToggleSection message={messages.mheAvailability} {...toggleProps(SECTION.MHE)} subSection>
            { (plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> : <PlanMheAvailability />}
          </ToggleSection>
        )}
      </PlanTabs>
      <ToggleSection subtitleToShow={activitySettingsData.length > 0 ? '' : messages.productivityError} message={messages.unitsPerHour} {...toggleProps(SECTION.PROD_RATE)}>
        {(plansLoaderFlag === true || plansLoaderFlag === undefined)  ? <LoadingIndicator/> :
        <ProductivityRate isEdit={editMode} formik={formikBag} save={save} />
        }
      </ToggleSection>
      <ToggleSection subtitleToShow={(labourAvailabilityData.length > 0 && !ShiftWeeklyData) ? '' : messages.subTiTleError} message={messages.labourAvailabilityOverview} {...toggleProps(SECTION.LABOUR_AVAILABILITY_OVERVIEW)}>
        {(plansOverviewFlag === true || plansOverviewFlag === undefined) ? <LoadingIndicator /> :
          <LabourAvailabilityOverviewPlan isEdit={editMode} formik={formikBag} offDays={offDays} save={save} saveLabourOverView={saveLabourOverView} />
        }
      </ToggleSection>
      <ToggleSection subtitleToShow={mheAvailablilityData && mheAvailablilityData.length > 0 ? '' : messages.mheError} message={messages.mheAvailabilityOverview} {...toggleProps(SECTION.MHE_AVAILABILITY_OVERVIEW)}>
        {(plansOverviewFlag === true || plansOverviewFlag === undefined) ? <LoadingIndicator /> :
          <MHEAvailabilityOverviewPlan isEdit={editMode} formik={formikBag} save={save} offDays={offDays} saveMheOverview={saveMheOverview} periodIndex={guiState.get('periodIndex')} />
        }
      </ToggleSection>
      {isAdjustmentsChecked && initialAdjustmentsChecked &&
        <ToggleSection message={messages.adjustmentOverview} {...toggleProps(SECTION.ADJUSTMENTS_OVERVIEW)}>
          {(plansOverviewFlag === true || plansOverviewFlag === undefined) ? <LoadingIndicator /> :
            <AdjustmentOverviewPlan isEdit={editMode} formik={formikBag} save={save} offDays={offDays} saveAdjustmentOverview={saveAdjustmentOverview} isForecastEditor={isForecastEditor} />
          }
        </ToggleSection>
      }
    </>
  );
};

PlanDetailPage.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
  plan: PropTypes.object,
  guiState: PropTypes.object,
  startNewAction: PropTypes.func,
  save: PropTypes.func,
  loadPlanAction: PropTypes.func,
  loadPlanDetails: PropTypes.func,
  loadPlanOverviewData: PropTypes.func,
  clearPlanMHEAction: PropTypes.func,
  closeMasterPlanDialog: PropTypes.func,
  clearplanDetailMasterPlanDetails: PropTypes.func,
  planningParameters: PropTypes.object,
  toggleSection: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  plan: selectPlan,
  guiState: selectGuiState,
  planningParameters: selectPlanningParameters,
  planDetailMasterPlans: selectPlanDetailMasterPlan,
  planDetailSmartRuns: selectPlanDetailSmartRuns,
  planDetailSmartRunsLoader: selectPlanDetailSmartRunsLoader,
  offDays: selectOffDays,
  plansLoader: selectPlanDetailsLoader,
  plansLoaderFlag: selectPlanDetailsLoaderFlag,
  plansOverviewFlag: selectPlanOverviewLoaderFlag,
  planRatioWarning: selectplanRationWarningFlag,
  planRoles: selectplanRoles,
  planSmartCodeMappings: selectPlanSmartCodeMappings,
  planDetailSchedulesData: selectPlanDetailSchedule,
  omsChangeHistory: selectomsChangeHistory,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      savePlanBasicInfo,
      startNewAction,
      resetPlanBudgetAction,
      save: savePlan,
      clearPlanMHEAction,
      clearMasterPlanConflict,
      loadPlanAction,
      loadPlanDetails,
      loadPlanOverviewData,
      toggleSection: togglePlanSection,
      planDetailMasterPlanDetails,
      planDetailSmartProdVolumeRuns,
      saveLabourOverView,
      saveMheOverview,
      saveAdjustmentOverview,
      planDetailSchedules,
      openMasterPlanDialog,
      closeMasterPlanDialog,
      clearplanDetailMasterPlanDetails,
      omChangeHistory,
      saveomsChangeHistory
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'planDetailPage', saga });

export default compose(injectIntl, withConnect, withSecurity(PERMISSIONS.VIEW_PLAN_DETAILS), withSaga)(PlanDetailPage);
