import {
  ApiActivityParametersCalculationResultDTO, ApiActivityParametersMultipleDaysCalculationResultDTO,
} from "types/drep-backend.d";
import { toNumber, dateFormat } from 'utils/utils';

import {
  DATASET,
  GranulartiyTree,
  LEVEL,
  LEVELS_ORDER,
  ParsedColumnName,
  Props,
  UNIT,
} from './types';

export function roundToFive(num: number): number {
  return +(Math.round(Number(`${num}e+5`)) + 'e-5');
}

function findLevelIndex(level: LEVEL): number {
  let foundIndex: number = 0;
  LEVELS_ORDER.find((item, index) => {
    foundIndex = index;
    return item === level;
  });
  return foundIndex;
}

export function getNextLevel(level: LEVEL): LEVEL {
  const index = findLevelIndex(level);
  return LEVELS_ORDER[index + 1];
}

export function getPrevLevel(level: LEVEL): LEVEL {
  const index = findLevelIndex(level);
  return LEVELS_ORDER[index - 1];
}

export function headerClassFromDataset(dataset: DATASET) {
  switch (dataset) {
    case DATASET.actuals:
      return 'column-actuals';
    case DATASET.budget:
      return 'column-budget';
    case DATASET.planned:
      return 'column-planned';
    case DATASET.original:
      return 'column-origPlanned';
    case DATASET.forecast:
      return 'column-forecast';
    case DATASET.smartVolume:
      return 'column-smartVolume'; 
  }
}

export function cellClassFromDataset(dataset: DATASET) {
  switch (dataset) {
    case DATASET.actuals:
      return 'cell-actuals';
    case DATASET.budget:
      return 'cell-budget';
    case DATASET.planned:
      return 'cell-planned';
    case DATASET.original:
      return 'cell-origPlanned';
    case DATASET.forecast:
      return 'cell-forecast';
    case DATASET.smartVolume:
      return 'cell-smartVolume';   
  }
}

export function cellClassFromUnit(unit: UNIT) {
  switch (unit) {
    case UNIT.v:
    case UNIT.pr:
      return 'cell-overridable';
    case UNIT.e:
    case UNIT.sah:  
      return 'cell-effort';
    case UNIT.effAdju:
    case UNIT.diffAdju:   
      return 'cell-adjustable';
    case UNIT.fte:
    case UNIT.saf:  
    case UNIT.fteDiff:  
    case UNIT.fteAdju:  
      return 'cell-fte';
    case UNIT.heads:
      return 'cell-heads';
    case UNIT.headsOpt:
      return 'cell-heads-opt';
    case UNIT.nos:
    case UNIT.sas:  
    case UNIT.staffDiff: 
    case UNIT.nosAdju: 
      return 'cell-nos';
    case UNIT.diff:  
    case UNIT.variance:
      return 'cell-variance';
  }
}

export function headerByLevel(level: LEVEL, item, props: Props): string {
  switch (level) {
    case LEVEL.DAY:
      let day = item.day;
      if (typeof day === 'string') day = new Date(day + 'T00:00:00');
      return `${props.messages[item.dayOfWeek.toLowerCase()]} ${dateFormat(day)}`;
    case LEVEL.HOUR:
      return `${item.hourOfDay} h`;
    case LEVEL.WEEK:
      const weekTitle = props.messages.week;
      let weekStartDay = item.startDay;
      if (typeof weekStartDay === 'string') weekStartDay = new Date(weekStartDay + 'T00:00:00');
      return `${weekTitle} ${dateFormat(weekStartDay)}`;
    case LEVEL.WZP:
      return `${item.workZonePeriodName}`;
    case LEVEL.MONTH:
      const monthTitle = props.messages.month;
      let startDay = item.startDay;
      if (typeof startDay === 'string') startDay = new Date(startDay + 'T00:00:00');
      return `${monthTitle} ${dateFormat(startDay)}`;
  }
}

export function round(n: number) {
  return n;
  //return Math.round(n * 100) / 100;
}

export function columnName(dataset: DATASET, unit: UNIT, granulartiyTree?: GranulartiyTree, byHour: boolean = false) {
  const { period, day, wzp, hour, cellType, cellTypeSource, isSum } = granulartiyTree;
  if (isSum) {
    return `sum_${dataset}_${unit}`;
  }
  const result = [];
  if (period) {
    result.push(`w${period.startDay}`);
  }
  if (day) {
    result.push(`d${day.day}`);
  }
  if (wzp && !byHour) {
    result.push(`wzp${wzp.workZonePeriodId}`);
  }
  if (hour) {
    result.push(`h${hour.hourOfDay}`);
  }

  result.push(`${dataset}_${unit}`);

  if (cellType) {
    result.push(`ovrSrc${cellType}`);
  }
  if(cellTypeSource){
    result.push(`ovrSrc${cellTypeSource}`);
  }
  return result.join('_');
}

export function parseColumnName(
  name: string,
  extractUnit: boolean,
  extractDataset: boolean,
  hoverOverWzp: boolean,
  isWeekStart: boolean,
): ParsedColumnName {
  const result: ParsedColumnName = {};
  if (name) {
    const split = name.split('_');
    split.map(item => {
      const matchWzp = item.match(/^wzp(.*)$/);
      if (matchWzp && (hoverOverWzp ? result.hourOfDay === undefined : true)) {
        result.workZonePeriodId = toNumber(matchWzp[1]);
        return;
      }
      const matchWeek = item.match(/^w(.*)$/);
      if (matchWeek) {
        if (isWeekStart) {
          result.weekStart = matchWeek[1];
        } else {
          result.week = matchWeek[1];
        }
      }
      const matchDay = item.match(/^d(.*)$/);
      if (matchDay) {
        result.day = matchDay[1];
      }
      const matchHour = item.match(/^h(.*)$/);
      if (matchHour) {
        result.hourOfDay = toNumber(matchHour[1]);
      }
    });
    const matchDataSet = name.match(/^.*_([^_]*)_([^_]*)$/);
    if (matchDataSet) {
      if (extractDataset) {
        result.dataSet = DATASET[matchDataSet[1]];
      }
      if (extractUnit) {
        result.dataUnit = UNIT[matchDataSet[2]];
      }
    }
  }
  return result;
}

/**
 * We must find longest period to show correct number of columns.
 * @param activities
 */
export function findBestPeriod(activities: ApiActivityParametersCalculationResultDTO[]): ApiActivityParametersMultipleDaysCalculationResultDTO[] {
  //if (activities && activities.length > 0) {
    //let longest: ApiActivityParametersMultipleDaysCalculationResultDTO[];
    // activities.forEach(activity => {
    //   if (longest === undefined || activity.periods.length > longest.length) {
    //     longest = activity.periods;
    //   }
    // });
    let periodsToSend = [];
    if (activities && activities.length > 0) {
      activities.forEach(activity => {
       if(periodsToSend.length === 0){
        periodsToSend.push(...activity.periods)
       }else{
        activity.periods.forEach((p)=>{
          const doesPeriodAlreadyExists = periodsToSend.some((ps) => (ps.startDay === p.startDay) && (ps.endDay === p.endDay));
          if(!doesPeriodAlreadyExists){
            periodsToSend.push(p)
          }
        })
       }
      });
      periodsToSend && periodsToSend.sort((a, b) => +new Date(a.startDay) - +new Date(b.startDay))
      return periodsToSend;
    }
  //}
  return undefined;
}
