/**
 *
 * PlanDetailWorkzonePeriod
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FastField, FieldArray, getIn, setIn } from 'formik';
import { injectIntl } from 'react-intl';
import { Duration } from 'luxon';
import uniqid from 'uniqid';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import messages from './messages';
import { selectGuiState } from '../../containers/PlanDetailPage/selectors';
import Label from '../Label';
import { AddButton } from '../IconButton';
import { selectGuiStateForPa } from '../../containers/PlanningAreaDetailPage/selectors';
import { EditableListItemWrapper } from '../DetailPageShared';
import EditableWzp from './EditableWzp';
import directMessages from '../LabourAvailabilityCategoryTable/messages';
import { DeleteDialog, withDeleteDialog } from '../Dialog';

const Value = styled.div``;

export const WzpListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  min-height: 30px;
  padding-top: 25px;
  ${AddButton} {
    top: 0px;
    position: absolute;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class DetailEntityWorkzonePeriod extends React.PureComponent {
  render() {
    return this.props.guiState.get('edit') ? this.renderForm() : this.renderReadOnly();
  }

  renderReadOnlyItem(wzp) {
    return (
      <EditableListItemWrapper key={wzp ? wzp.name : -1}>
        <Label {...messages.name} />
        <Value>{wzp && wzp.name}</Value>
        <Label {...messages.startTime} />
        <Value>{wzp && wzp.startTime.toFormat('h:mm')}</Value>
        <Label {...messages.endTime} />
        <Value>{wzp && wzp.endTime.toFormat('h:mm')}</Value>
        <Label {...messages.breakTime} />
        <Value>{wzp && wzp.breakTime}</Value>
        <Label {...messages.minutes} />
      </EditableListItemWrapper>
    );
  }

  renderReadOnly() {
    return (
      <WzpListWrapper>
        <FastField
          name={this.wzpPath()}
          key={this.wzpPath()}
          render={({ field }) => (field.value ? field.value.map(wzp => this.renderReadOnlyItem(wzp)) : null)}
        />
      </WzpListWrapper>
    );
  }

  wzpPath = () => `planningParameters.periods.${this.props.guiState.get('periodIndex')}.realShiftsWithWZP`;

  mheAvailabilityPath = () => `planningParameters.periods.${this.props.guiState.get('periodIndex')}.mheAvailabilities`;

  renderForm() {
    let lastEndTime = Duration.fromObject({ hours: 0, minutes: 0, seconds: 0 });
    const wzpPath = this.wzpPath();
    return (
      <div>
        <FieldArray
          name={wzpPath}
          render={arrayProps => (
            <WzpListWrapper>
              {(getIn(arrayProps.form.values, arrayProps.name) || []).map((item, index) => {
                const wzpId = item.id || item.generatedId;
                if (item.endTime && item.endTime.as('milliseconds') > lastEndTime.as('milliseconds')) {
                  lastEndTime = item.endTime;
                }

                const handleDeleteWZP = () =>{
                  let formikValues = arrayProps.form.values;
                  const mheAvails = getIn(formikValues, this.mheAvailabilityPath(), []);
                  const deletionFlag = mheAvails && mheAvails.filter((mhea) => mhea.wzp && mhea.wzp.id === item.id);
                  if (deletionFlag && deletionFlag.length > 0) {
                      toast.error(`Cannot delete work zone period ${item.name} because it is used as Maintenance WZP in Mhe Availability`)
                    return;
                  }else{
                    this.props.openDeleteDialog(doDelete)
                  }
                }
                const doDelete = () => {
                  let formikValues = arrayProps.form.values;
                  const mheAvails = getIn(formikValues, this.mheAvailabilityPath(), []);
                  mheAvails.forEach((mhea, mheIndex) => {
                    if (mhea.wzp && mhea.wzp.id === item.id) {
                      formikValues.planningParameters.periods[this.props.guiState.get('periodIndex')].mheAvailabilities[
                        mheIndex
                      ].wzp = undefined;
                    }
                  });
                  const wzpsCopy = [...getIn(formikValues, this.wzpPath(), [])];
                  wzpsCopy.splice(index, 1);
                  formikValues = setIn(formikValues, this.wzpPath(), wzpsCopy);
                  arrayProps.form.setValues(formikValues);
                  // can not use arrayProps.remove(index) because we are changing several formik's values
                  // this have to be done in one step (using setValues) otherwise agGrid will fail
                  // as it's re-rendering because of setFieldValue (to remove deleted wzp in mhe avail)
                  // and another render update comes because of arrayProps.remove
                };
                return (
                  <EditableWzp
                    key={`${wzpPath}_${wzpId}`}
                    wzpPath={wzpPath}
                    index={index}
                    item={item}
                    {...arrayProps}
                    deleteHandler={() => handleDeleteWZP()}
                  />
                );
              })}
              <AddButton
                disabled={arrayProps.form.isSubmitting}
                onClick={() => {
                  arrayProps.push(this.createNewItem(lastEndTime));
                }}
              />
              <DeleteDialog {...this.props} text={messages.wzpConfirmDelete} />
            </WzpListWrapper>
          )}
        />
      </div>
    );
  }

  createNewItem(lastEndTime) {
    const generatedId = uniqid();
    const startTime = lastEndTime
      ? Duration.fromObject(lastEndTime.toObject())
      : Duration.fromObject({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
    return { generatedId, name: '', startTime, endTime: Duration.fromObject(startTime.toObject()), breakTime: 0 };
  }
}

DetailEntityWorkzonePeriod.propTypes = {
  guiState: PropTypes.object,
  intl: PropTypes.object,
};

const mapPlanStateToProps = createStructuredSelector({
  guiState: selectGuiState,
});

const mapPaStateToProps = createStructuredSelector({
  guiState: selectGuiStateForPa,
});

const withPlanConnect = connect(mapPlanStateToProps);

const withPaConnect = connect(mapPaStateToProps);

export const PlanDetailWorkzonePeriod = compose(
  injectIntl,
  withPlanConnect,
  withDeleteDialog,
)(DetailEntityWorkzonePeriod);

export const PlaningAreaDetailWorkzonePeriod = compose(
  injectIntl,
  withPaConnect,
  withDeleteDialog,
)(DetailEntityWorkzonePeriod);
