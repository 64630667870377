import React from 'react';
import UIIconButton from '@material-ui/core/IconButton';
import SContent from '@material-ui/core/SnackbarContent';
import styled from 'styled-components';

import IconButton from 'components/IconButton';

export const TableInfo = styled.div`
  color: ${props => props.theme.color.red};
  font-weight: normal;

  #subtitle-help {
    padding: 0;
    border: 1px solid #337ab7;
    opacity: 1;
  }

  #subtitle-help > img {
    border-radius: 3px;
  }
`;
export const CloseButton = styled(UIIconButton)`
  font-size: 16px !important;
  width: 40px !important;
`;

export const SnackbarContent = styled(SContent)`
  background-color: ${props => props.theme.color.yellow} !important;
  color: ${props => props.theme.color.black} !important;
  min-width: 400px !important;
`;
export const UndoButton = styled(IconButton)`
  background-color: ${props => props.theme.color.red} !important;
  color: ${props => props.theme.color.white} !important;
  margin-right: 15px !important;
`;

export const ButtonBar = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
  > * {
    margin: 0 2px;
  }
  ${IconButton} {
    margin-left: auto;
    font-size: 14px;
    font-weight: normal;
  }

  flex-wrap: wrap;
`;

export const TableWrap = styled.div`
  .cell-overridable {
    background-color: rgba(196, 223, 185, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable {
    color: black !important;
    background-color: rgba(223, 195, 208, 0.44) !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable-sum{
    color: black !important;
    font-weight: bold;
    background-color: rgba(223, 195, 208, 0.44) !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable-dept{
    color: black !important;
    font-weight: bold;
    background-color: white !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable-cust{
    color: black !important;
    font-weight: normal;
    background-color: white !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable .ag-header-cell-label {
    color: rgba(0, 0, 0, 0.54) !important;
    justify-content: flex-end;
  }

  .cell-adjustable-sum .ag-header-cell-label {
    color: rgba(0, 0, 0, 0.54) !important;
    justify-content: flex-end;
  }

  .cell-overridable .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-data-source-not-default {
    color: rgba(0, 0, 195, 1);
  }

  .cell-smartVolumeEvent {
    color: #B22222;
  }

  .cell-smartProd {
    color: #4169E1;
  }

  .cell-smartProdEvent {
    color: #8B4513
  }

  .cell-smartProdEventAdjustment {
    color: #5F9F9F
  }

  .cell-effort {
    background-color: rgba(223, 195, 208, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-effort .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-fte {
    background-color: rgba(223, 195, 133, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-fte .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-heads {
    background-color: #fffec6;
    background-color: rgba(236, 234, 117, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-heads-opt {
    background-color: #fffec6;
    background-color: rgba(255, 203, 41, 0.49);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-nos {
    background-color: #ffe3c6;
    background-color: rgb(236, 178, 117, 0.49);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-variance {
    background-color: rgba(146, 183, 135, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-variance .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-variance.ag-cell-value:not(:empty):after {
    content: ' %';
  }

  .cell-heads .ag-header-cell-label,
  .cell-heads-opt .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-empty {
    background-color: inherit;
  }

  .sum {
    font-weight: bold;
  }

  .total-column {
    font-weight: bold;
  }

  .cell-overStaff {
    font-weight: bold;
    color: red !important;
  }

  .cell-underStaff {
    font-weight: bold;
    color: green !important;
  }

  .cell-editable {
    background-color: ${props => props.theme.color.blue};
    color: ${props => props.theme.color.white};
  }
  .override-smartProd-ovrd {
    background-color: ${props => props.theme.color.blue};
    color: ${props => props.theme.color.white};
  }

  .override-smartEvent-ovrd {
    background-color: #6A5ACD;
    font-weight: bold;
    color: ${props => props.theme.color.yellow3};
  }
  .override-prodEvent-ovrd {
    background-color: #836FFF;
    color: ${props => props.theme.color.white};
  }

  .override-stateVolume-ovrd {
    background-color: ${props => props.theme.color.blueDark1};
    font-weight: bold;
    color: ${props => props.theme.color.yellow3};
  }

  .override-state-ovrd {
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }

  .cell-editable.override-state-ovrd {
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }

  .override-state-MHE {
    font-weight: bold;
    color: ${props => props.theme.color.yellowOrange};
  }

  .override-state-USER {
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }

  .cell-editable.override-state-USER {
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }

  .cell-ratio-not-100-percent {
    background-color: ${props => props.theme.color.red} !important;
    color: ${props => props.theme.color.white} !important;
  }

  .override-state-ovrdcal {
    color: ${props => props.theme.color.red};
    background-color: ${props => (props.edit ? props.theme.color.blueLight : 'inherited')};
  }

  .cell-editable.override-state-ovrdcal {
    color: ${props => props.theme.color.citrusOrange};
    background-color: ${props => (props.edit ? props.theme.color.blueLight : 'inherited')};
  }

  .cell-editable.ag-cell-inline-editing {
    color: ${props => props.theme.color.black};
  }

  .override-state-edit {
    background-color: ${props => props.theme.color.violetDark};
  }

  .column-budget {
    background-color: ${props => props.theme.color.blueLL};
  }

  .column-planned {
    background-color: ${props => props.theme.color.greenLL};
  }

  .column-actuals {
    background-color: ${props => props.theme.color.violetLL};
  }

  .column-forecast {
    background-color: ${props => props.theme.color.blueLight};
  }

  .column-smartVolume {
    background-color: ${props => props.theme.color.yellowPepa};
  }

  .pre-sum-row .cell-effort {
    background-color: ${props => props.theme.color.white};
  }

  .pre-sum-row .cell-fte {
    background-color: ${props => props.theme.color.white};
  }

  .sum-row div[col-id='activity'] {
    font-weight: bold;
  }

  div[ref='ePinnedLeftHeader'] .ag-theme-balham .ag-header-cell::after,
  .ag-theme-balham .ag-header-group-cell::after {
    border-right: none;
  }

  div[ref='ePinnedLeftHeader'] .ag-header-row:not(:nth-last-child(2)) {
    border-bottom: none;
  }
`;
