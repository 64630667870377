import { Period, PlanningParameters, Shift, WorkZonePeriod } from 'types/formik-common';

import { LOG_SHIFTS } from './logger';

export type RemovedDaysInfo = {
  shift: Shift;
  removedDays: string[];
};

export type RemovedWzpDaysInfo = {
  wzp: WorkZonePeriod;
  removedDays: string[];
};

function UpdateWeekDays(days, name){
    const weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
    return days.map(day => {
      let index = weekDays.indexOf(day);
      return name?.includes('-a') ? weekDays[(index - 1) % 7] : weekDays[(index + 1) % 7];
    })
}

/*
  Return object with shift and days
 */
export const getRemovedShiftDays = (originalShifts: Shift[], newShifts: Shift[]): RemovedDaysInfo[] => {
  const result = [];
  originalShifts.forEach(shift => {
    const newShift = newShifts?.find(ns => ns.id === shift.id);
    const selectedBusinessDays = newShift.shifts?.filter((w) => w.businessDay);
    const isBusinessDayChanged = JSON.stringify(selectedBusinessDays[0]?.days) !== JSON.stringify(newShift?.days);
    if (newShift && isBusinessDayChanged) {
      const removedDays = shift.days.filter(day => !newShift.days.includes(day));
      if (removedDays?.length) {
        result.push({ shift, removedDays });
      }
    }
  });
  return result;
};

export const getRemovedWzpDays = (originalWzps: WorkZonePeriod[], newWzps: WorkZonePeriod[]): RemovedWzpDaysInfo[] => {
  const result = [];
  originalWzps.forEach(wzp => {
    const newWzp = newWzps?.find(ns => ns.id === wzp.id);
    const selectedBusinessDays = newWzp.workZonePeriods?.filter((w) => w.businessDay);
    const isBusinessDayChanged = JSON.stringify(selectedBusinessDays[0]?.days) !== JSON.stringify(newWzp?.days);
    if (newWzp && isBusinessDayChanged) {
      const removedDays = wzp.days.filter(day => !newWzp.days.includes(day));
      if (removedDays?.length) {
        result.push({ wzp, removedDays });
      }
    }
  });
  return result;
};

const sumWzpTransformation = (period: Period, removedInfo: RemovedDaysInfo): number => {
  let result = 0;
  period.activityParameters.forEach(activityParameter => {
    const removedShift = removedInfo.shift?.shifts?.filter((s) => s.businessDay);
    const nonRemovedWZP = removedInfo.shift?.shifts?.filter((s) => !s.businessDay);
    const removedDaysArray = UpdateWeekDays(removedInfo.removedDays, nonRemovedWZP[0]?.name);
    activityParameter.wzpTransitions
      .filter(it => it.wzpId === removedShift[0].id)
      .filter(it => removedInfo.removedDays.includes(it.dayOfWeek))
      .forEach(wzpTransition => {
        result += wzpTransition.transferRate;
      });
    if (nonRemovedWZP?.length) {
      activityParameter.wzpTransitions
        .filter(it => it.wzpId === nonRemovedWZP[0].id)
        .filter(it => removedDaysArray.includes(it.dayOfWeek))
        .forEach(wzpTransition => {
          result += wzpTransition.transferRate;
        });
    }
  });
  return result;
};

const sumWzpWzpTransformation = (period: Period, removedInfo: RemovedWzpDaysInfo): number => {
  let result = 0;
  const removedWzp = removedInfo.wzp?.workZonePeriods?.filter((s)=> s.businessDay);
  const nonRemovedWZP = removedInfo.wzp?.workZonePeriods?.filter((s) => !s.businessDay);
  const removedDaysArray = UpdateWeekDays(removedInfo.removedDays, nonRemovedWZP[0]?.name);
  period.activityParameters.forEach(activityParameter => {
    activityParameter.wzpTransitions
      .filter(it => it.wzpId === removedWzp[0].id)
      .filter(it => removedInfo.removedDays.includes(it.dayOfWeek))
      .forEach(wzpTransition => {
        result += wzpTransition.transferRate;
      });
    if (nonRemovedWZP?.length) {
      activityParameter.wzpTransitions
        .filter(it => it.wzpId === nonRemovedWZP[0].id)
        .filter(it => removedDaysArray.includes(it.dayOfWeek))
        .forEach(wzpTransition => {
          result += wzpTransition.transferRate;
        });
    }
  });
  return result;
};

export const areSomeShiftDaysRemovedAndUsed = (originalPp: PlanningParameters, newPp: PlanningParameters): boolean => {
  let result = false;
  originalPp.periods.forEach((origPeriod, i) => {
    const removedShiftDays = getRemovedShiftDays(origPeriod.realShiftsWithShift, newPp.periods[i].realShiftsWithShift);
    if (removedShiftDays.length) {
      LOG_SHIFTS.d(`Detected removed shift days for period ${i}`, removedShiftDays);
      LOG_SHIFTS.d(origPeriod);
      removedShiftDays.forEach(removedInfo => {
        const effort = sumWzpTransformation(newPp.periods[i], removedInfo);
        if (effort > 0) {
          result = true;
          LOG_SHIFTS.d(`Found existing shift transformation ${effort} in period ${i} for`, removedInfo);
        }
      });
    }
  });
  return result;
};

export const areSomeWzpDaysRemovedAndUsed = (originalPp: PlanningParameters, newPp: PlanningParameters): boolean => {
  let result = false;
  originalPp.periods.forEach((origPeriod, i) => {
    const removedWzpDays = getRemovedWzpDays(origPeriod.realShiftsWithWZP, newPp.periods[i].realShiftsWithWZP);
    if (removedWzpDays.length) {
      LOG_SHIFTS.d(`Detected removed wzp days for period ${i}`, removedWzpDays);
      LOG_SHIFTS.d(origPeriod);
      removedWzpDays.forEach(removedInfo => {
        const effort = sumWzpWzpTransformation(newPp.periods[i], removedInfo);
        if (effort > 0) {
          result = true;
          LOG_SHIFTS.d(`Found existing shift transformation ${effort} in period ${i} for`, removedInfo);
        }
      });
    }
  });
  return result;
};

export const areSomeShiftDaysRemovedAndUsedFlag = (originalPp: PlanningParameters, newPp: PlanningParameters): boolean => {
  let result = false;
  let removedShiftDays = []
  originalPp.periods.forEach((origPeriod, i) => {
    removedShiftDays = getRemovedShiftDays(origPeriod.realShiftsWithShift, newPp.periods[i].realShiftsWithShift);
    if (removedShiftDays.length) {
      LOG_SHIFTS.d(`Detected removed shift days for period ${i}`, removedShiftDays);
      LOG_SHIFTS.d(origPeriod);
      removedShiftDays.forEach(removedInfo => {
        const effort = sumWzpTransformation(newPp.periods[i], removedInfo);
        if (effort > 0) {
          result = true;
          LOG_SHIFTS.d(`Found existing shift transformation ${effort} in period ${i} for`, removedInfo);
        }
      });
    }
  });
  return  removedShiftDays.length > 0 ? true : false;
};
