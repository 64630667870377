import { getIn } from 'formik';
import { toast } from 'react-toastify';
import DaysPickerCellEditor from 'components/DaysPickerCellEditor';
import TimePickerCellEditor from 'components/TimePickerCellEditor';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { SimpleHeader } from 'components/DetailPageShared';
import { getWeekdays } from 'utils/calendar/localize';
import messages from './messages';
import DetailsButtons from './DetailsButtons';
import { numericSetter } from '../../utils/utils';

export default function createColumnDefs(props, addOpenAction, handleShiftDeletion) {
  const { edit, labourCategory = true, isShift, tableKey = 'shift-volume' } = props;
  const editable = edit;
  const handleDeleteShift = (payload) => {
    const deletionFlag = handleShiftDeletion && handleShiftDeletion(payload) || [];
    if (deletionFlag && deletionFlag.length > 0) {
        toast.error(`Cannot delete shift ${payload.name} because it is used as Maintenance WZP in Mhe Availability`)
      return;
    }else{
      props.openDeleteDialog(props.deleteShiftSettingsLine, payload)
    }
  }
  const isEditable = (params) => {
    const modifiedIds = JSON.parse(sessionStorage.getItem('businessDateChangedIds'));
    const IdExists = modifiedIds?.some((m) => m == params.data.id);
    return !IdExists;
  }
  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.name),
      width: 220,
      field: 'name',
      colId: 'name',
      editable: editable && !isShift,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.days),
      field: 'days',
      colId: 'days',
      width: 500,
      editable: editable && !isShift && isEditable,
      menuTabs: ['filterMenuTab'],
      valueFormatter: params => Object.values(getWeekdays(props.intl.formatMessage, params.value, true)).join(', '),
      valueSetter: params => {
        if (params && params.newValue) {
          if (typeof params.newValue === 'string') {
            params.data[params.colDef.field] = params.newValue.split(',');
          } else {
            params.data[params.colDef.field] = params.newValue;
          }
          return true;
        }
        return false; // value was not changed
      },
      cellEditorFramework: DaysPickerCellEditor,
    },
    {
      headerName: props.intl.formatMessage(messages.startTime),
      field: 'startTime',
      colId: 'startTime',
      suppressMenu: true,
      width: 100,
      // editable,
      cellRendererFramework: TimePickerCellEditor,
      cellRendererParams: {
        editable: editable && !isShift,
        okLabel: props.intl.formatMessage(messages.setStartTime),
        nextCellEditor: 'endTime',
      },
    },
    {
      headerName: props.intl.formatMessage(messages.endTime),
      field: 'endTime',
      colId: 'endTime',
      suppressMenu: true,
      width: 100,
      // editable,
      cellRendererFramework: TimePickerCellEditor,
      cellRendererParams: {
        editable: editable && !isShift,
        okLabel: props.intl.formatMessage(messages.setEndTime),
      },
    },
    {
      headerName: props.intl.formatMessage(messages.breakTime),
      field: 'breakTime',
      colId: 'breakTime',
      suppressMenu: true,
      width: 100,
      editable: editable && !isShift,
      valueSetter: numericSetter,
    },
  ];
  if (labourCategory) {
    columnDefs.push({
      headerName: props.intl.formatMessage(messages.detailsButtons),
      field: 'detailsButtons',
      colId: 'detailsButtons',
      sortable: false,
      suppressMenu: true,
      cellRendererFramework: DetailsButtons,
      cellRendererParams: {
        onClickHandler: addOpenAction,
        editable,
      },
    });
  }
  if (!(isShift && tableKey === 'shift-volume') && editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: payload => handleDeleteShift(payload),
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
      headerComponentFramework: SimpleHeader,
    });
  }
  return { columnDefs };
}

export function createWZPColumnDef(props, addOpenAction, handleShiftDeletion) {
  const { edit, labourCategory = true, isShift, tableKey = 'shift-volume' } = props;
  const editable = edit;
  const handleDeleteShift = (payload) => {
    const deletionFlag = handleShiftDeletion && handleShiftDeletion(payload) || [];
    if (deletionFlag && deletionFlag.length > 0) {
        toast.error(`Cannot delete wzp ${payload.name} because it is used as Maintenance WZP in Mhe Availability`)
      return;
    }else{
      props.openDeleteDialog(props.deleteWZPSettingsLine, payload)
    }
  }
  const isEditable = (params) => {
    const modifiedIds = JSON.parse(sessionStorage.getItem('businessDateChangedIds'));
    const IdExists = modifiedIds?.some((m) => m == params.data.id);
    return !IdExists;
  }
  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.name),
      width: 220,
      field: 'name',
      colId: 'name',
      editable: editable && !isShift,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.days),
      field: 'days',
      colId: 'days',
      width: 500,
      editable: editable && isEditable,
      menuTabs: ['filterMenuTab'],
      valueFormatter: params => Object.values(getWeekdays(props.intl.formatMessage, params.value, true)).join(', '),
      valueSetter: params => {
        if (params && params.newValue) {
          if (typeof params.newValue === 'string') {
            params.data[params.colDef.field] = params.newValue.split(',');
          } else {
            params.data[params.colDef.field] = params.newValue;
          }
          return true;
        }
        return false; // value was not changed
      },
      cellEditorFramework: DaysPickerCellEditor,
    },
    {
      headerName: props.intl.formatMessage(messages.startTime),
      field: 'startTime',
      colId: 'startTime',
      suppressMenu: true,
      width: 135,
      // editable,
      cellRendererFramework: TimePickerCellEditor,
      cellRendererParams: {
        editable: editable && !isShift,
        okLabel: props.intl.formatMessage(messages.setStartTime),
        nextCellEditor: 'endTime',
      },
    },
    {
      headerName: props.intl.formatMessage(messages.endTime),
      field: 'endTime',
      colId: 'endTime',
      suppressMenu: true,
      width: 135,
      // editable,
      cellRendererFramework: TimePickerCellEditor,
      cellRendererParams: {
        editable: editable && !isShift,
        okLabel: props.intl.formatMessage(messages.setEndTime),
      },
    },
    {
      headerName: props.intl.formatMessage(messages.breakTime),
      field: 'breakTime',
      colId: 'breakTime',
      suppressMenu: true,
      width: 100,
      editable: editable && !isShift,
      valueSetter: numericSetter,
    },
  ];
  if (labourCategory) {
    columnDefs.push({
      headerName: props.intl.formatMessage(messages.detailsButtons),
      field: 'detailsButtons',
      colId: 'detailsButtons',
      sortable: false,
      suppressMenu: true,
      cellRendererFramework: DetailsButtons,
      cellRendererParams: {
        onClickHandler: addOpenAction,
        editable,
      },
    });
  }
  if (!(isShift && tableKey === 'shift-volume') && editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: payload => handleDeleteShift(payload),
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
      headerComponentFramework: SimpleHeader,
    });
  }
  return { columnDefs };
}
