// @flow
/* eslint-disable no-restricted-syntax,no-plusplus */
/**
 *
 * ResultMatrixActivitiesTableMasterPlan
 *
 */

import React from 'react';
import { isEqual } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled, { withTheme } from 'styled-components';
import uuid from 'uuid/v4';

import NameWithToolTip, { GroupWithToolTip } from 'components/NameWithToolTip';
import Table from 'components/Table';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { makeSelectRunningApiCalls } from 'containers/App/selectors';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { round } from 'containers/PlanResultPage/calculation/calculateCommon';
import { UNIT } from 'containers/PlanResultPage/calculation/types';
import { PURPOSE } from 'containers/PlanResultPage/constants';
import { selectEdit } from 'containers/PlanResultPage/selectors';
import { TableWrap } from 'containers/PlanResultPage/styled';
import { flatCols, parseDay, tableHeight } from 'containers/PlanResultPage/utils';
import { toNumber } from 'utils/utils';

import { ExpandCollapseButton, ExportButton } from '../IconButton';
import { refreshDiscrepancyRowByDemandTotalsAndAdaptNos } from './interactiveCalculations';
import messages from './messages';
import { LoadingWrap, SectionTitle, Wraptable } from './styled';

type LoadingProps = {
  status: string,
};

export const Loading = (props: LoadingProps) => {
  const { status } = props;
  return (
    <LoadingWrap>
      {(status && messages[status] && <FormattedMessage {...messages[status]} />) || (
        <FormattedMessage {...messages.loading} />
      )}
    </LoadingWrap>
  );
};

function getRowClass(params) {
  const { data } = params;
  const result = [];
  if (!data) {
    return '';
  }
  if (params.data.isIndirect) {
    result.push('row-activity-indirect');
  } else {
    result.push('row-activity-direct');
  }
  if (data.isSumRow) {
    result.push('sum-row');
  }
  if (data.isPreSumRow) {
    result.push('pre-sum-row');
  }
  if (result.length > 0) {
    result.push('not-group');
  }
  return result.join(' ');
}

const Text = styled.div`
  margin-left: 10px;
`;
const WrapText = styled.div`
  display: flex;
  align-items: center;
`;

const VirtualColumnNames = styled.div`
  position: absolute;
  top: 1px;
  left: ${props => props.offsetLeft + 1}px;
`;

const VirtualColumnPlaceholder = styled.span`
  color: rgba(0, 0, 0, 0.54);
  font-weight: 600;
  font-size: 12px;
  line-height: 31px;
  margin-top: 1px;
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
`;

const getWidthOfChildren = columnGroup => {
  let width = 0;
  if (columnGroup.children) {
    columnGroup.children.forEach(child => {
      width += getWidthOfChildren(child);
    });
  } else {
    return columnGroup.actualWidth || 0;
  }
  return width;
};

const ActivityRendered = (showLabourAvailability, onShowLabourAvailability, isShift = true) => params => {
  if (!params.data) {
    return <span />;
  }
  const {
    data: { isLabor, isSumRow, activity },
  } = params;
  if (isLabor && isSumRow) {
    return (
      <WrapText>
        <ExpandCollapseButton
          name="showLabourAvailability"
          field={{ value: showLabourAvailability, onChange: onShowLabourAvailability }}
        />
        {
          isShift && activity === 'Labour Availability Sum' ? 
          <strong>{activity}</strong> :
          <Text>{params.value}</Text>
        }
      </WrapText>
    );
  }
  if (isShift) {
    return <strong>{activity}</strong>;
  }
  return NameWithToolTip()(params);
};

export type ScrollPosition = {
  left: number,
  top: number,
};

type Props = {
  result: Object,
  intl: Object,
  formik: Object,
  onGridReady: Function,
  onSendToKronos: Function,
  onOverrideHelper: Function,
  editing: boolean,
  isLoading: boolean,
  onExport: Function,
  onCsvExport: Function,
  isEditingAction: Function,
  showLabourAvailability: boolean,
  onShowLabourAvailability: Function,
  lastScrollPosition: {
    current: null | ScrollPosition,
  },
  granularity: string,
  calculationStatus: string;
};

type StateProps = {
  totals: Array<Object>,
  colDefs: Array<Object>,
};

const doNotSummarizeCols = [UNIT.pr, UNIT.v, UNIT.heads, UNIT.headsOpt, UNIT.variance];
const activityCol = 2;
const shiftCol = 3;

/* eslint-disable react/prefer-stateless-function */
class ResultMatrixActivitiesTableMasterPlan extends React.Component<Props, StateProps> {
  constructor(props: Props) {
    super(props);
    const { result } = this.props;
    const totals = this.calculateTotals(result);
    this.state = {
      totals,
      colDefs: result.colDef,
      virtualGroupColumns: [],
      leftVirtualGroupColumsOffset: 0,
      tableRenderKey: '',
    };
    this.gridApi = null;
    this.initialVals = {};
  }
  labourAvailableFlag = this.props.viewSettings.checkboxLabourAvailable;

  shouldComponentUpdate(nextProps, nextState) {
    const { result, isLoading } = nextProps;
    if (isLoading !== this.props.isLoading) {
      return true;
    }
    if (
      !isEqual(nextState.virtualGroupColumns, this.state.virtualGroupColumns) ||
      nextState.leftVirtualGroupColumsOffset !== this.state.leftVirtualGroupColumsOffset
    ) {
      return true;
    }
    const oldEditing = this.props.result.editing;
    const newEditing = result.editing;
    if (this.props.showLabourAvailability !== nextProps.showLabourAvailability || oldEditing !== newEditing) {
      this.setState({ colDefs: result.colDef, virtualGroupColumns: [] });
      return true;
    }
    if (this.state.totals !== nextState.totals) {
      return true;
    }

    return result !== this.props.result;
  }

  calculateAdjsutmentDifference(sumEffort, adjustmentEffort){
    let differenceValue;
    if (!sumEffort && !adjustmentEffort) {
      differenceValue = 0;
    }
    if (!sumEffort) {
      differenceValue = 100;
    }
    if (!adjustmentEffort) {
      differenceValue = 100;
    }
    if(sumEffort === 0 && adjustmentEffort ===0){
      differenceValue = 0;
    }
    if(sumEffort > 0 && adjustmentEffort === 0){
      differenceValue = -100;
    }
    if(sumEffort === 0 && adjustmentEffort > 0){
      differenceValue = 100;
    }
    if (sumEffort >0 && adjustmentEffort>0) {
      differenceValue = ((adjustmentEffort - sumEffort) / sumEffort) * 100;
    }
    return differenceValue == 0 ? "0.00" : differenceValue ? differenceValue.toFixed(2) : 0;
  }

  summarrizeCol(total, col, row) {
    const key = col.colId;
    const val = row[key];
    if (col.type === 'numericColumn' && val !== undefined && doNotSummarizeCols.indexOf(col.unit) === -1) {
      if (!(key in total)) total[key] = 0;
      total[key] += Number(val);
      if(key.includes('diffAdju')){
        const splitKey = key?.split('_diffAdju')[0];
        const sumEffort = total[`${splitKey}_e`];
        const adjustmentEffort = total[`${splitKey}_effAdju`];
        total[key] = this.calculateAdjsutmentDifference(sumEffort, adjustmentEffort);
        }
      const cellTypeKey = `${key}_ovrSrcOverrideState`;
      if (row[cellTypeKey]) {
        const cellType = row[cellTypeKey];
        if (!(cellTypeKey in total)) {
          total[cellTypeKey] = cellType;
        } else if (cellType === 'ovrd' || cellType === 'ovrdcal') {
          total[cellTypeKey] = 'ovrdcal';
        }
      }
    }
  }

  addTotalsData(total, otherTotals) {
    const {
      intl: { formatMessage },
    } = this.props;
    for (const key in total) {
      if (!key.endsWith('ovrSrcOverrideState')) {
        total[key] = round(total[key]);
      }
    }
    const name = formatMessage(messages.labourDemandSum);
    total.activity = name;
    total.isSumRow = true;
    const result = [total].concat(otherTotals);
    const showNos =
      this.props.formikBag &&
      this.props.formikBag.values &&
      this.props.formikBag.values.planningParameters &&
      this.props.formikBag.values.planningParameters.transformationType === 'SHIFT';
    return refreshDiscrepancyRowByDemandTotalsAndAdaptNos(result, this.props.isShiftFlag);
  }

  calculateTotals = ({ colDef, data, totals = [] }) => {
    const cols = colDef && flatCols(colDef);
    if (!cols) return [];
    const total = {};
    data && data.map(row => cols.map(col => this.summarrizeCol(total, col, row)));
    const totalsdata = this.addTotalsData(total, totals);
    const arr = totalsdata && totalsdata.slice(0,4);
    const arr1 = totalsdata && totalsdata.slice(4,totalsdata.length);
    arr1 && arr1.sort((a, b) => a?.activity?.localeCompare(b?.activity));
    return [...arr, ...arr1]
  };

  onFilterChanged = params => {
    const { result, onFilterChanged } = this.props;
    const otherTotals = result.totals;
    const cols = result.colDef && flatCols(result.colDef);
    const totals = {};
    this.gridApi &&
      this.gridApi.api.forEachNodeAfterFilter(node => {
        cols.forEach(col => {
          if (!node.data) return;
          this.summarrizeCol(totals, col, node.data);
        });
      });
    this.gridApi && this.labourAvailableFlag && this.gridApi.api.setPinnedBottomRowData(this.addTotalsData(totals, otherTotals));
    if (params) {
      onFilterChanged && onFilterChanged(params);
    }
  };

  calculateColumnsHash(columns) {
    const hash = [];
    columns.forEach(column => {
      if (column.children) {
        hash.push(this.calculateColumnsHash(column.children));
      } else {
        hash.push(`${column.colId}`);
      }
    });
    return `[${hash.join(',')}]`;
  }

  componentDidUpdate(prevProps) {
    const { result } = this.props;

    const previousHash = this.calculateColumnsHash(prevProps.result.colDef);
    const currentHash = this.calculateColumnsHash(this.props.result.colDef);
    const force = previousHash !== currentHash;
    if (result !== prevProps.result) {
      this.redrawAgGridTable(force);
    }
  }

  redrawAgGridTable(force: boolean) {
    const { result } = this.props;
    const totals = this.calculateTotals(result);
    this.gridApi.api.setRowData((result && result.data && result.data) || []);
    this.gridApi.api.setColumnDefs(result.colDef);
    this.gridApi.api.refreshCells({ force: true });
    this.gridApi.api.redrawRows();
    if(this.labourAvailableFlag){
      this.gridApi.api.setPinnedBottomRowData(totals);
    };
    const state = {
      totals,
      colDefs: result.colDef,
      virtualGroupColumns: [],
    };
    if (force) {
      state.tableRenderKey = uuid();
    }
    this.setState(state);
  }

  onCellValueChanged = params => {
    // eslint-disable-line
    const {
      colDef,
      data: { activityId, departmentId, uomId, customerId },
      oldValue,
      newValue,
      colKey,
      rowIndex,
      node,
    } = params;
    const field = `${colDef.field}.${rowIndex}`;

    if (Number.isNaN(newValue)) {
      if (!(field in this.initialVals)) {
        this.initialVals[field] = oldValue;
      }
      node.setDataValue(colDef.colId, oldValue);
      return;
    }

    const { formikBag, granularity } = this.props;
    const overrides = { ...formikBag.values.overrides };
    if (!(field in this.initialVals)) {
      this.initialVals[field] = oldValue;
    } else {
      const nv = this.initialVals[field];
      if (toNumber(nv) === toNumber(newValue)) {
        delete overrides[field];
        formikBag.setFieldValue(`overrides`, { ...overrides });
        return;
      }
    }
    if (toNumber(oldValue) === toNumber(newValue)) {
      return;
    }

    const override = {
      activityId,
      departmentId,
      customerId,
      uomId,
      day: parseDay(colDef, granularity),
      workZonePeriodId: colDef.wzp || (params.data.wzp && params.data.wzp.workZonePeriodId),
      granularity,
      hourOfDay: colDef.hour,
      valueType: colDef.colType,
      value: newValue === '' ? undefined : toNumber(newValue),
      purpose: colDef.dataSet === 'actuals' ? PURPOSE.ACTUAL : PURPOSE.OVERRIDE,
      planingParameterId: this.props.result.planingParameterId,
    };
    overrides[field] = override;
    formikBag.setFieldValue(`overrides`, overrides);
    this.onFilterChanged();
  };

  onGridReady = params => {
    const { result, lastScrollPosition } = this.props;
    this.gridApi = params;
    this.props.onGridReady(params, !!result.colDef);
    if(this.labourAvailableFlag){
      params.api.setPinnedBottomRowData(this.state.totals);
    };
    const scrollPosition = lastScrollPosition.current;
    if (scrollPosition) {
      this.gridApi.api.gridPanel.setCenterViewportScrollLeft(scrollPosition.left);
      this.gridApi.api.gridPanel.setVerticalScrollPosition(scrollPosition.top);
    }
  };

  getLeftmostGroupWithNotVisibleLabelForParents = (childrenColumnGroups, scrollLeft) => {
    const filteredGroups = childrenColumnGroups
      .filter(childrenColGroup => childrenColGroup.left <= scrollLeft)
      .sort((a, b) => b.left - a.left);
    return filteredGroups[0];
  };

  onBodyScroll = params => {
    this.props.lastScrollPosition.current = {
      left: params.left,
      top: params.top,
    };
    if (params.direction === 'vertical') {
      return;
    }
    const leftColumnGroups = this.gridApi.columnApi.getLeftDisplayedColumnGroups();
    let leftVirtualGroupColumsOffset = 0;
    leftColumnGroups.forEach(child => {
      leftVirtualGroupColumsOffset += getWidthOfChildren(child);
    });

    const columnGroups = this.gridApi.columnApi.getCenterDisplayedColumnGroups();
    const groupsWithInvisibleLabels = [];
    let currentGroup = { children: columnGroups };
    // eslint-disable-next-line no-constant-condition
    while (true) {
      currentGroup = this.getLeftmostGroupWithNotVisibleLabelForParents(currentGroup.children, params.left);
      if (!currentGroup || !currentGroup.originalColumnGroup || !currentGroup.children) {
        break;
      }
      groupsWithInvisibleLabels.push(currentGroup);
    }

    this.setState({
      virtualGroupColumns: groupsWithInvisibleLabels
        .map(ig => ig.originalColumnGroup.colGroupDef)
        .map(({ headerName, headerClass }) => ({
          headerName,
          headerClass,
        })),
      leftVirtualGroupColumsOffset,
    });
  };

  onColumnResized = () => {
    this.setState({
      virtualGroupColumns: [],
    });
  };

  render() {
    const {
      result,
      isLoading,
      showLabourAvailability,
      onShowLabourAvailability,
      granularity,
      onSortChanged,
      onRowGroupOpened,
      intl: { formatMessage },
      viewSettings,
      calculationStatus, 
      isSubShift
    } = this.props;
    const { byHour, planName } = result;
    const { totals, colDefs, tableRenderKey } = this.state;
    const aproxHeight = tableHeight(result, totals.length, showLabourAvailability, granularity, byHour, false);
    const groupHeaderName = `${formatMessage(messages.department)} \u2192 ${formatMessage(
      messages.customer,
    )}  \u2192  ${formatMessage(messages.activity)}`;
    const filterColumns = ['department', 'customer', 'activity'];
    if (!byHour) {
      filterColumns.push('uom');
    }
    return (
      <TableWrap>
        <SectionTitle>
          {
            isSubShift ? <span>{`Activity per Business Shift for ${planName}`}</span> : <span>{planName}</span>
          }
          <div style={{ display: 'flex' }}>
            <ExportButton onClick={this.props.onExport} />
            <ExportButton onClick={this.props.onCsvExport} icon="file-csv" message={messages.csv} />
          </div>
        </SectionTitle>
        <Wraptable className={`ag-theme-balham ${(byHour && 'shift') || ''}`}>
          {calculationStatus === 'notStarted' ? (
            <Loading status="notStarted" />
          ) : (
            <>
              <Table
                frameworkComponents={{
                  acWithToolTip: GroupWithToolTip(),
                  groupFilter: GroupFilter,
                  acShowLabourAvailability: ActivityRendered(showLabourAvailability, onShowLabourAvailability, byHour),
                }}
                key={`${aproxHeight}-${showLabourAvailability}-${tableRenderKey}`}
                domLayout="autoHeight"
                singleClickEdit
                name="resultMainTable"
                messages={messages}
                showCOG={false}
                pagination={false}
                filter
                suppressCsvExport
                // suppressChangeDetection={true}
                columnDefs={(colDefs && colDefs) || []}
                rowData={(result && result.data && result.data) || []}
                suppressMovableColumns
                onCellValueChanged={this.onCellValueChanged}
                getRowClass={getRowClass}
                stopEditingWhenGridLosesFocus
                onGridReady={this.onGridReady}
                pinnedBottomRowData={this.labourAvailableFlag ? totals : []}
                onFilterChanged={this.onFilterChanged}
                onSortChanged={onSortChanged}
                // Grouping
                onRowGroupOpened={onRowGroupOpened}
                suppressAggFuncInHeader
                rememberGroupStateWhenNewData
                groupDefaultExpanded={-1}
                onBodyScroll={this.onBodyScroll}
                onColumnResized={this.onColumnResized}
                showCollapseButtonsInsideTable
                autoGroupColumnDef={{
                  width: 300,
                  pinned: true,
                  colId: 'groupping',
                  headerName: groupHeaderName,
                  headerTooltip: groupHeaderName,
                  menuTabs: ['filterMenuTab'],
                  filter: 'groupFilter',
                  field: byHour ? null : 'activity',
                  // pinnedRowCellRendererFramework: ActivityRendered(showLabourAvailability, onShowLabourAvailability, isShift),
                  // hack to make filterMenuTab visible for  autoColumn
                  // valueGetter: params => '',
                  cellRendererSelector: byHour
                    ? null
                    : params => {
                        if (params.node.rowPinned === 'bottom') {
                          return {
                            component: 'acShowLabourAvailability',
                          };
                        }
                        if (params.node.group)
                          return {
                            component: 'agGroupCellRenderer',
                            params: {
                              suppressCount: true,
                            },
                          };
                        return {
                          component: 'acWithToolTip',
                          params: {
                            padding: 2,
                          },
                        };
                      },
                  filterValueGetter(params) {},
                  filterParams: {
                    columns: filterColumns,
                    applyButton: true,
                    clearButton: true,
                  },
                  cellRenderer: byHour ? 'agGroupCellRenderer' : null,
                  cellRendererParams: byHour ? { suppressCount: true } : null,
                }}
              />
              {(calculationStatus !== 'finished' || isLoading) && (
                <Loading status={isLoading ? 'loading' : calculationStatus} />
              )}
              <VirtualColumnNames offsetLeft={this.state.leftVirtualGroupColumsOffset}>
                {this.state.virtualGroupColumns.map((group, index) => (
                  <React.Fragment key={index}>
                    <VirtualColumnPlaceholder
                      className={group.headerClass}
                      style={{
                        backgroundColor: group.headerClass ? undefined : '#f5f7f7',
                      }}
                    >
                      {group.headerName}
                    </VirtualColumnPlaceholder>
                    <br />
                  </React.Fragment>
                ))}
              </VirtualColumnNames>
            </>
          )}
        </Wraptable>
      </TableWrap>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  edit: selectEdit,
  isLoading: makeSelectRunningApiCalls(),
  viewSettings: selectViewModeStoredData,
});

const withConnect = connect(mapStateToProps);

export default compose(withTheme, withConnect, injectIntl)(ResultMatrixActivitiesTableMasterPlan);
