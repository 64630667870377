/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import messages from './messages';
import Label from '../Label';
import { PaShiftAddLine } from '../ShiftAddLine';
import { PaShiftSettings } from '../../containers/ShiftSettings/LabourShiftSettings';
import ShiftsGraph from '../../containers/ShiftsGraph';

/* eslint-disable react/prefer-stateless-function */
class PlanningAreaDetailShifts extends React.PureComponent {
  render() {
    const { values, formik, isShift, roles } = this.props;
    return (
      <div style={{ marginTop: '10px' }}>
        <Label {...messages.header} />
        {!isShift && <PaShiftAddLine {...{ values }} />}
        <PaShiftSettings isShift={isShift} formik={formik} roles = {roles} />
        <Label {...messages.headerGraphicalView} />
        <ShiftsGraph data={values} periodIndex={this.props.periodIndex} />
      </div>
    );
  }
}

PlanningAreaDetailShifts.propTypes = {
  values: PropTypes.object,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};

export default PlanningAreaDetailShifts;
