/* eslint-disable no-restricted-syntax */
/**
 *
 * PlannedVolumeTable
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { selectEditFromplan, selectShowFormula, selectShowValue, selectShowFormulaVar, selectShowValueVar, selectPeriodIndexFromPlan } from 'containers/PlanDetailPage/selectors';
import { deletePlannedVolume, toggleVolumeValue, toggleVolumeFormula, toggleVolumeValueVar, toggleVolumeFormulaVar } from 'containers/PlanDetailPage/actions';
import { selectEditFromPa, selectShowFormulaPa, selectShowValuePa, selectShowFormulaPaVar, selectShowValuePaVar, selectPeriodIndexFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import { deleteBudgetVolume, toggleVolumeValuePa, toggleVolumeFormulaPa, toggleVolumeValuePaVar, toggleVolumeFormulaPaVar } from 'containers/PlanningAreaDetailPage/actions';
import { selectEdit as getResultPageEdit, selectShowValueResult, selectShowFormulaResult } from 'containers/PlanResultPage/selectors';
import { selectUOMS } from 'containers/App/selectors';
import { TableWrap } from 'containers/PlanResultPage/styled';
import Button from 'components/Button';
import { checkVariables, textToFormulaActivity } from 'utils/formulas';

import { toNumber } from 'utils/utils';
import Table from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { connect as formikConnect, getIn } from 'formik';

import cloneDeep from 'lodash/cloneDeep';
import { cellEditDirtyMarker } from '../DetailPageShared';
import { withDeleteDialog, DeleteDialog } from '../Dialog';

import messages from './messages';
import { createDeleteColumn, createStandardVolumeColumnDefs, isDaily, createStandardVolumeColumnDefsVar } from './volume';
import { isEqual } from 'lodash';

const Wrap = styled.div`
  display: flex;
  height: ${props => props.height}px;
`;

const Title = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 10px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  > * {
    margin: 0 2px;
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.color.red};
`;

const ToggleButtonStyled = styled(Button)`
  background-color: ${props => (props.value ? props.theme.color.yellow : props.theme.color.grey1)};
`;

/* eslint-disable react/prefer-stateless-function */
class PlannedVolumeTable extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.edit !== nextProps.edit) {
      return true;
    }
    if (this.props.deleteDialogOpen !== nextProps.deleteDialogOpen) {
      return true;
    }
    if(this.props.showFormula !== nextProps.showFormula){
      return true;
    }
    if(this.props.showValue !== nextProps.showValue){
      return true;
    }
    if(this.props.showFormulaVar !== nextProps.showFormulaVar){
      return true;
    }
    if(this.props.showValueVar !== nextProps.showValueVar){
      return true;
    }
    if(!isEqual(this.props.formik.values.planningParameters.volumeCategoryParameters, nextProps.formik.values.planningParameters.volumeCategoryParameters)){
      return true;
    }
    const newHeaders = getIn(nextProps.formik.initialValues, 'planningParameters.volumeCategoryParameters.headers');
    const currentHeaders = getIn(this.props.formik.initialValues, 'planningParameters.volumeCategoryParameters.headers');
    return newHeaders !== currentHeaders || this.isDaily(nextProps) !== this.isDaily();
  }

  isDaily = extProps => {
    const props = extProps || this.props;
    if (props.daily !== undefined) {
      return props.daily;
    }

    return isDaily(props);
  };

  getDataKey() {
    return (this.isDaily() && 'dailyRowData') || 'rowData';
  }

  createColumnDefs(props, categoryMessage) {
    const columnDefs = createStandardVolumeColumnDefs(
      props,
      messages,
      categoryMessage,
      'volumeCategoryParameters',
      this.isDaily(),
    );
    if (props.edit && props.deleteLine) {
      const onDelete = payload => {
        props.openDeleteDialog(props.deleteLine, payload, {
          catName: payload.volumeCategory.name,
          uomName: payload.uom.name,
        });
      };
      columnDefs.unshift(createDeleteColumn({ props, messages, onDelete }));
    }
    return columnDefs;
  }

  createColumnDefsVar(props, categoryMessage) {
    const columnDefs = createStandardVolumeColumnDefsVar(
      props,
      messages,
      categoryMessage,
      'volumeCategoryParameters',
      this.isDaily(),
    );
    
    if (props.edit && props.deleteLine) {
      const onDelete = payload => {
        props.openDeleteDialog(props.deleteLine, payload, {
          catName: payload.volumeCategory.name,
          uomName: payload.uom.name,
        });
      };
      columnDefs.unshift(createDeleteColumn({ props, messages, onDelete }));
    }
    return columnDefs;
  }

  activitiesVariableNames = props => {
    const vars = {};
    const {
      intl: { formatMessage },
    } = this.props;
    props.formik.values?.planningParameters?.periods?.forEach((data)=>{
      getIn(data, `apCalculated.direct`, []).forEach(
        it => {
          vars[it.variableName] = formatMessage(messages.activityLabel, { name: it.activity && it.activity.name });
        },
      );
    })
    return vars;
  };

  validateFormula(selfVar, formula, variableNames) {
    const {
      intl: { formatMessage },
      formik,
    } = this.props;
    if (!variableNames) return;
    const variablesOk = checkVariables(formula, variableNames, formatMessage, messages, selfVar);
    if (variablesOk !== true) {
      const errorMessage = formatMessage(messages.warningMessage, { warning: variablesOk });
      return errorMessage;
    }
    return false;
  }

  render() {

    const dataKey = this.getDataKey();
    let rowData = cloneDeep(getIn(this.props.formik.values, `planningParameters.volumeCategoryParameters.${dataKey}`));
    const rowDataVar = cloneDeep(
      getIn(this.props.formik.values, `planningParameters.volumeCategoryParameters.${dataKey}Var`),
    );
    if(this.props.resultPage){
      rowData = [...rowData, ...rowDataVar];
    }
    return (
      <>
        {rowData.length > 0 && this.renderTable(rowData, messages.category, messages.categorySection, '')}
        {rowDataVar.length > 0 && this.props.resultPage !== true &&
          this.renderTableVar(rowDataVar, messages.categoryVariable, messages.categoryVariableSection, 'Var')}
        <DeleteDialog {...this.props} text={messages.dialogDeleteText} />
      </>
    );
  }
  
  renderTable(rowData, categoryMessage, sectionMessage, varPostfix) {
    const inDirectData = getIn(this.props.formik.values, `planningParameters.periods.${this.props.periodIndex}.apCalculated.indirect`, []).map((it)=> it.variableName);
    const vn = this.activitiesVariableNames(this.props);
    const rowDataWarnings = [];
    rowData.forEach((row) =>{
      row && Object.keys(row).forEach((key)=>{
        if(key.includes('_formula')){
          if(row[key] === '') return;
          if(!isNaN(row[key])) rowDataWarnings.push(`Fixed numeric values can't be used for Volume category: ${row.volumeCategory.name}`);
          if(inDirectData.includes(row[key])) rowDataWarnings.push(`Reference to indirect activities can't be used: ${row[key]}`);
          if(row[key] && !inDirectData.includes(row[key])){
            const warning = this.validateFormula(row.volumeCategory.variableName, JSON.parse(JSON.stringify({ r: textToFormulaActivity(row[key]) })), vn)
            if(warning !== false){
              rowDataWarnings.push(warning);
            }
          }
          if(row[key] && (row[key].includes('+') || row[key].includes('-'))){
            const splittedAddFormula = row[key].split('+');
            splittedAddFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for Volume category: ${row.volumeCategory.name}`)
            })
            const splittedSubFormula = row[key].split('-');
            splittedSubFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for Volume category: ${row.volumeCategory.name}`)
            })
          }
        }
        if(key.includes('defaultFormula')){
          if(row[key] === '') return;
          if(!isNaN(row[key])) rowDataWarnings.push(`Fixed numeric values can't be used for default formula Volume category: ${row.volumeCategory.name}`);
          if(inDirectData.includes(row[key])) rowDataWarnings.push(`Reference for default formula to indirect activities can't be used: ${row[key]}`);
          if(row[key] && !inDirectData.includes(row[key])){
            const warning = this.validateFormula(row.volumeCategory.variableName, JSON.parse(JSON.stringify({ r: textToFormulaActivity(row[key]) })), vn)
            if(warning !== false){
              rowDataWarnings.push(warning);
            }
          }
          if(row[key] && (row[key].includes('+') || row[key].includes('-'))){
            const splittedAddFormula = row[key].split('+');
            splittedAddFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for default formula Volume category: ${row.volumeCategory.name}`)
            })
            const splittedSubFormula = row[key].split('-');
            splittedSubFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for default formula Volume category: ${row.volumeCategory.name}`)
            })
          }
        }
      })
    })
    const aproxHeight = rowData.length * 35 + 90; // TODO: something better
    return (
      <div>
        <Title>
          <FormattedMessage {...sectionMessage} />
        </Title>
        { this.props.resultPage !== true && 
        <>
        <ButtonsWrap>
            <ToggleButtonStyled value={this.props.showValue} onClick={() => (this.props.toggleVolumeValue(!this.props.showValue))}>
              <FormattedMessage {...messages.value} />
            </ToggleButtonStyled>
          <ToggleButtonStyled value={this.props.showFormula} onClick={() => this.props.toggleVolumeFormula(!this.props.showFormula)}>
            <FormattedMessage {...messages.formula} />
          </ToggleButtonStyled>
        </ButtonsWrap>
          {
            rowDataWarnings && rowDataWarnings && rowDataWarnings.map((aw) => {
              return <ErrorMessage>{aw}</ErrorMessage>
            })
          }
        </>
        }
        <Wrap height={aproxHeight}>
          <Table
            name={
              this.props.plan
                ? this.props.edit
                  ? 'plannedVolumeTableEdit'
                  : 'plannedVolumeTable'
                : this.props.edit
                ? 'budgetVolumeTableEdit'
                : 'budgetVolumeTable'
            }
            messages={messages}
            defaultConfig={
              this.props.edit
                ? TABLE_DEFAULTS.plannedVolumeTableEditConfigConfig
                : TABLE_DEFAULTS.plannedVolumeTableConfig
            }
            pagination={false}
            columnDefs={this.createColumnDefs(this.props, categoryMessage)}
            rowData={rowData}
            onCellValueChanged={params => this.onCellValueChanged(params, params?.data?.volumeCategory?.variable ? 'Var' : '')}
            getRowNodeId={data =>  data.volumeCategory.id}
            deltaRowDataMode = {true}
            onPaste={data => this.onPaste(data, '')}
            animateRows={false}
            showCOG={false}
            singleClickEdit
            {...cellEditDirtyMarker(this.props.formik.setFieldValue)}
          />
        </Wrap>
      </div>
    );
  }

  renderTableVar(rowData, categoryMessage, sectionMessage, varPostfix) {
    const inDirectData = getIn(this.props.formik.values, `planningParameters.periods.${this.props.periodIndex}.apCalculated.indirect`, []).map((it)=> it.variableName);
    const vn = this.activitiesVariableNames(this.props);
    const rowDataWarnings = [];
    rowData.forEach((row) =>{
      row && Object.keys(row).forEach((key)=>{
        if(key.includes('_formula')){
          if(row[key] === '') return;
          if(!isNaN(row[key])) rowDataWarnings.push(`Fixed numeric values can't be used for Volume category: ${row.volumeCategory.name}`);
          if(inDirectData.includes(row[key])) rowDataWarnings.push(`Reference to indirect activities can't be used: ${row[key]}`);
          if(row[key] && !inDirectData.includes(row[key])){
            const warning = this.validateFormula(row.volumeCategory.variableName, JSON.parse(JSON.stringify({ r: textToFormulaActivity(row[key]) })), vn)
            if(warning !== false){
              rowDataWarnings.push(warning);
            }
          }
          if(row[key] && (row[key].includes('+') || row[key].includes('-'))){
            const splittedAddFormula = row[key].split('+');
            splittedAddFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for Volume category: ${row.volumeCategory.name}`)
            })
            const splittedSubFormula = row[key].split('-');
            splittedSubFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for Volume category: ${row.volumeCategory.name}`)
            })
          }
        }
        if(key.includes('defaultFormula')){
          if(row[key] === '') return;
          if(!isNaN(row[key])) rowDataWarnings.push(`Fixed numeric values can't be used for default formula Volume category: ${row.volumeCategory.name}`);
          if(inDirectData.includes(row[key])) rowDataWarnings.push(`Reference for default formula to indirect activities can't be used: ${row[key]}`);
          if(row[key] && !inDirectData.includes(row[key])){
            const warning = this.validateFormula(row.volumeCategory.variableName, JSON.parse(JSON.stringify({ r: textToFormulaActivity(row[key]) })), vn)
            if(warning !== false){
              rowDataWarnings.push(warning);
            }
          }
          if(row[key] && (row[key].includes('+') || row[key].includes('-'))){
            const splittedAddFormula = row[key].split('+');
            splittedAddFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for default formula Volume category: ${row.volumeCategory.name}`)
            })
            const splittedSubFormula = row[key].split('-');
            splittedSubFormula.forEach((f)=>{
              if(!isNaN(f)) rowDataWarnings.push(`Fixed numeric values can't be used for default formula Volume category: ${row.volumeCategory.name}`)
            })
          }
        }
      })
    })
    const aproxHeight = rowData.length * 35 + 90; // TODO: something better
    return (
      <div>
        <Title>
          <FormattedMessage {...sectionMessage} />
        </Title>
        { this.props.resultPage !== true && 
        <>
        <ButtonsWrap>
            <ToggleButtonStyled value={this.props.showValueVar} onClick={() => (this.props.toggleVolumeValueVar(!this.props.showValueVar))}>
              <FormattedMessage {...messages.value} />
            </ToggleButtonStyled>
          <ToggleButtonStyled value={this.props.showFormulaVar} onClick={() => this.props.toggleVolumeFormulaVar(!this.props.showFormulaVar)}>
            <FormattedMessage {...messages.formula} />
          </ToggleButtonStyled>
        </ButtonsWrap>
         {
          rowDataWarnings && rowDataWarnings && rowDataWarnings.map((aw) => {
            return <ErrorMessage>{aw}</ErrorMessage>
          })
        }
        </>
        }
        <Wrap height={aproxHeight}>
          <Table
            name={
              this.props.plan
                ? this.props.edit
                  ? 'plannedVolumeTableVarEdit'
                  : 'plannedVolumeTableVar'
                : this.props.edit
                ? 'budgetVolumeTableVarEdit'
                : 'budgetVolumeTableVar'
            }
            messages={messages}
            defaultConfig={
              this.props.edit
                ? TABLE_DEFAULTS.plannedVolumeVarTableEditConfigConfig
                : TABLE_DEFAULTS.plannedVolumeVarTableConfig
            }
            pagination={false}
            columnDefs={this.createColumnDefsVar(this.props, categoryMessage)}
            rowData={rowData}
            onCellValueChanged={params => this.onCellValueChanged(params, params?.data?.volumeCategory?.variable ? 'Var' : '')}
            onPaste={data => this.onPaste(data, 'Var')}
            getRowNodeId={data =>  data.volumeCategory.id}
            deltaRowDataMode = {true}
            animateRows={false}
            showCOG={false}
            singleClickEdit
            {...cellEditDirtyMarker(this.props.formik.setFieldValue)}
          />
        </Wrap>
      </div>
    );
  }

  onCellValueChanged = (params, varPostfix) => {
    const { formik } = this.props;
    const fieldName = `planningParameters.volumeCategoryParameters.${this.getDataKey()}${varPostfix}.${
      params.data.index
    }.${params.colDef.field}`;
    if((params.newValue == params.oldValue) || ((params.oldValue === undefined) && (params.newValue === ""))){
      return false;
    }
    const containsFormula = (fieldName && fieldName.includes('_formula')) || (fieldName && fieldName.includes('defaultFormula')) ;
    const formula = containsFormula && textToFormulaActivity(params.newValue.trim());
    const formulaValue = params.newValue ? JSON.stringify({ r: formula }) : null;
    formik.setFieldValue(fieldName, containsFormula? params.newValue.trim() : toNumber(params.newValue));
  };

  onPaste = (data, varPostfix) => {
    const { formik } = this.props;
    const postFix = [...new Set(data.map((d)=>d.volumeCategory.variable))];
    if(postFix?.length > 1){
      const nonVariableData = data.filter((d)=>!d.volumeCategory.variable);
      const nonVariablefieldName = `planningParameters.volumeCategoryParameters.${this.getDataKey()}${''}`;
      formik.setFieldValue(nonVariablefieldName, nonVariableData);
      const variableData = data.filter((d)=>d.volumeCategory.variable);
      const variablefieldName = `planningParameters.volumeCategoryParameters.${this.getDataKey()}${'Var'}`;
      formik.setFieldValue(variablefieldName, variableData);
    }else{
    const fieldName = `planningParameters.volumeCategoryParameters.${this.getDataKey()}${varPostfix}`;
    formik.setFieldValue(fieldName, data);
    }
  };
}

PlannedVolumeTable.propTypes = {
  edit: PropTypes.bool,
  plan: PropTypes.bool,
  formik: PropTypes.object,
  deleteLine: PropTypes.func,
  daily: PropTypes.bool,
  periodIndex: PropTypes.number,
};

// PLAN
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  uoms: selectUOMS,
  showValue: selectShowValue,
  showFormula: selectShowFormula,
  showValueVar: selectShowValueVar,
  showFormulaVar: selectShowFormulaVar,
  periodIndex: selectPeriodIndexFromPlan,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteLine: deletePlannedVolume,
      toggleVolumeFormula: toggleVolumeFormula,
      toggleVolumeValue: toggleVolumeValue,
      toggleVolumeFormulaVar: toggleVolumeFormulaVar,
      toggleVolumeValueVar: toggleVolumeValueVar,
    },
    dispatch,
  );
}

const withPlanConnect = connect(
  mapPlanStateToProps,
  mapPlanDispatchToProps,
);

// PA
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  uoms: selectUOMS,
  showValue: selectShowValuePa,
  showFormula: selectShowFormulaPa,
  showValueVar: selectShowValuePaVar,
  showFormulaVar: selectShowFormulaPaVar,
  periodIndex: selectPeriodIndexFromPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteLine: deleteBudgetVolume,
      toggleVolumeFormula: toggleVolumeFormulaPa,
      toggleVolumeValue: toggleVolumeValuePa,
      toggleVolumeFormulaVar: toggleVolumeFormulaPaVar,
      toggleVolumeValueVar: toggleVolumeValuePaVar,
    },
    dispatch,
  );
}

const withPaConnect = connect(
  mapPaStateToProps,
  mapPaDispatchToProps,
);

export default compose(
  injectIntl,
  withPlanConnect,
  withDeleteDialog,
  formikConnect,
)(PlannedVolumeTable);

export const BudgetVolumeTable = compose(
  injectIntl,
  withPaConnect,
  withDeleteDialog,
  formikConnect,
)(PlannedVolumeTable);

const mapResultStateToProps = createStructuredSelector({
  edit: getResultPageEdit,
  showValue: selectShowValueResult,
  showFormula: selectShowFormulaResult
});

const withResultConnect = connect(mapResultStateToProps);

export const ResultVolumeTable = compose(
  injectIntl,
  withResultConnect,
  withDeleteDialog,
  formikConnect,
)(props => (
  <TableWrap {...props}>
    {' '}
    <PlannedVolumeTable daily={false} {...props} />{' '}
  </TableWrap>
));

export const ResultVolumeTableDaily = props => <ResultVolumeTable {...props} daily />;
