/**
 *
 * PlanningAreasListPage
 *
 */
import { PERMISSIONS } from 'utils/security';
import { LOCAL_STORAGE_KEYS, PATHS, TABLE_DEFAULTS } from 'containers/App/constants';
import { dataToSearch, parseSearch } from 'utils/url';
import { DateCell } from 'components/ListGrid/utils';
import { parseDate } from 'utils/dateTime';
import messages from './messages';
import makeListPage from '../ListPage';

function formatCalculatedBy(lastCalculatedBy) {
  const { firstName, lastName } = lastCalculatedBy;
  return firstName ? (lastName ? `${firstName} ${lastName}` : firstName) : lastName || undefined;
}

const PlanListPage = makeListPage({
  defaultTableConfig: TABLE_DEFAULTS.PLAN_DEFAULT,
  tableConfigKey: LOCAL_STORAGE_KEYS.PLAN_LIST_TABLE_CONFIG,
  permissionAdd: PERMISSIONS.ADD_PLAN,
  permissionDisable: PERMISSIONS.DISABLE_PLAN,
  permissionSaveConfig: PERMISSIONS.SAVE_TABLE_CONFIG,
  permissionDisableData: PERMISSIONS.DISABLE_PLAN_IGNORE_RESTRICTIONS,
  permissionViewDetail: PERMISSIONS.VIEW_PLAN_DETAILS,
  pathToDetail: PATHS.planDetailId,
  pathToCreate: PATHS.makePlan,
  pathToSelf: PATHS.planList,
  messages,
  domainName: 'planListPage',
  domainUrl: 'plans',
  exportToExcel: true,
  parseData: item => ({
    id: item.id,
    name: item.name,
    area: item.planningArea && item.planningArea.name,
    facilities: (item.planningArea && item.planningArea.facilities) || [],
    customers: (item.planningArea && item.planningArea.customers) || [],
    managementUnits: (item.planningArea && item.planningArea.managementUnits) || [],
    created: item.audit.created,
    createdBy: item.audit.createdBy,
    editable: item.editable,
    deleted: item.deleted,
    updated: item.audit.updated,
    updatedBy: item.audit.updatedBy,
    calculated: item.statistics && item.statistics.lastCalculated,
    calculatedBy:
      item.statistics && item.statistics.lastCalculatedBy && formatCalculatedBy(item.statistics.lastCalculatedBy),
    numOfThisMonthCalcs: (item.statistics && item.statistics.numOfThisMonthCalcs) || 0,
    numOfLastMonthCalcs: (item.statistics && item.statistics.numOfLastMonthCalcs) || 0,
    masterPlans: item.masterPlans,
    pageName : messages.pageName.defaultMessage,
    planningParameters : item.planningParameters,
  }),
  interceptColDef: (colDefs, config, props) => {
    const area = {
      headerName: props.intl.formatMessage(messages.areaBase),
      field: 'area',
      colId: 'area',
      menuTabs: ['filterMenuTab'],
      ...props.domain.defs.planningAreaName,
    };
    colDefs.splice(3, 0, area);
    colDefs.push({
      headerName: props.intl.formatMessage(messages.calculated),
      field: 'calculated',
      colId: 'calculated',
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: DateCell,
      valueGetter: params => params.data.calculated && parseDate(params.data.calculated),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      },
      width: 150,
    });
    colDefs.push({
      headerName: props.intl.formatMessage(messages.calculatedBy),
      field: 'calculatedBy',
      colId: 'calculatedBy',
      menuTabs: ['filterMenuTab'],
      width: 150,
    });
    colDefs.push({
      headerName: props.intl.formatMessage(messages.numOfThisMonthCalcs),
      field: 'numOfThisMonthCalcs',
      colId: 'numOfThisMonthCalcs',
      menuTabs: ['filterMenuTab'],
      filter: 'agNumberColumnFilter',
      width: 200,
    });
    colDefs.push({
      headerName: props.intl.formatMessage(messages.numOfLastMonthCalcs),
      field: 'numOfLastMonthCalcs',
      colId: 'numOfLastMonthCalcs',
      menuTabs: ['filterMenuTab'],
      filter: 'agNumberColumnFilter',
      width: 200,
    });
    return colDefs;
  },
  onGridReady: (params, props) => {
    const pa = parseSearch('filterForPA', props);
    if (pa) {
      const filterModel = params.api.getFilterModel();
      if(sessionStorage.getItem('filterForPA')){
      if (pa) filterModel.area = { values: [sessionStorage.getItem('filterForPA')], filterType: 'set', search: sessionStorage.getItem('filterForPA') };
      sessionStorage.removeItem('filterForPA')
      }else{
        if (pa) filterModel.area = { values: [pa], filterType: 'set', search: pa };
      }
      setTimeout(() => params.api.setFilterModel({ ...filterModel }), 1000);
    }
  },

  actionCellRendererParams: (props, config) => ({
    onDetail:
      props.hasPerm(config.permissionViewDetail) &&
      (id => {
        props.history.push(config.pathToDetail.replace(':id', id));
      }),
    onResult: id => {
      const defaultOptions = {
        planId: id,
      };
      props.history.push({ pathname: PATHS.planResult, search: dataToSearch(defaultOptions) });
    },
    onForecast: props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION) && (id => {
      const defaultOptions = {
        planId: id,
        isEdit: false
      };
      props.history.push({ pathname: PATHS.forecast, search: dataToSearch(defaultOptions) });
    }),
    onCopy:
      props.hasPerm(PERMISSIONS.COPY_PLAN) &&
      (id => {
        props.history.push(PATHS.makeCopyPlan.replace(':id', id));
      }),
  }),
});

export default PlanListPage;
