import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FastField, Field, FormikProps, getIn } from 'formik';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import DatePicker from 'components/DatePicker';
import StyledLabel from 'components/Label';
import { formatDate, parseDate } from 'utils/dateTime';

import messages from './messages';
import { ForecastForm } from './types';

const CenteredLabel = styled(StyledLabel)`
  height: 100%;
  display: table;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
`;

const InputError = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${props => props.theme.color.red};
`;

type DateLimits = {
  minDate?: DateTime;
  maxDate?: DateTime;
};

type Props = {
  formik: FormikProps<ForecastForm>;
  startFieldName: string;
  endFieldName: string;
  forecastEdit: boolean;
  startDateLimits?: DateLimits;
  endDateLimits?: DateLimits;
  data?: any;
};

const StartEndDate: React.FC<Props> = ({
  formik,
  forecastEdit,
  startFieldName,
  endFieldName,
  startDateLimits,
  endDateLimits,
  data,
}) => {
  const statusValue = data?.status == "SUCCESSFUL" || data?.status == "SUCCESS" || data?.status == "FAILED";
  const editable = forecastEdit && !statusValue;
  const renderField = (
    labelName,
    fieldName,
    input,
    fastField = true,
    labelWidth = undefined,
    inputWidth = undefined,
    validate = undefined,
  ) => (
    <>
      {labelName && (
        <Grid item xs={labelWidth || 4}>
          <CenteredLabel {...labelName} />
        </Grid>
      )}
      <Grid item xs={inputWidth || 8}>
        {fastField ? (
          <FastField name={fieldName} render={input} validate={validate} />
        ) : (
          <Field name={fieldName} render={input} validate={validate} />
        )}
      </Grid>
    </>
  );

  const startDateValidation = value =>
    value && forecastEdit && parseDate(value) > getIn(formik.values, endFieldName)
      ? `Must not be more than ${formatDate(getIn(formik.values, endFieldName))}`
      : undefined;

  const endDateValidation = value =>
    value && forecastEdit && parseDate(value) < getIn(formik.values, startFieldName)
      ? `Must be at least ${formatDate(getIn(formik.values, startFieldName))}`
      : undefined;

  return (
    <>
      <Grid item xs={6}>
        {renderField(
          messages.eventsTableStartDate,
          startFieldName,
          ({ field, form }) => (
            <>
              <DatePicker
                disabled={!editable}
                value={parseDate(field.value)}
                onAccept={value => {
                  form.setFieldValue(field.name, value);
                  if (value && value.startOf('day') > parseDate(getIn(formik.values, endFieldName))?.startOf('day')) {
                    form.setFieldValue(endFieldName, value);
                  }
                }}
                {...startDateLimits}
              />
              {startDateValidation(field.value) && <InputError>{startDateValidation(field.value)}</InputError>}
            </>
          ),
          false,
          12,
          12,
        )}
      </Grid>
      <Grid item xs={6}>
        {renderField(
          messages.eventsTableEndDate,
          endFieldName,
          ({ field, form }) => (
            <>
              <DatePicker
                disabled={!editable}
                value={parseDate(field.value)}
                onAccept={value => {
                  form.setFieldValue(field.name, value);
                  if (value && value.startOf('day') < parseDate(getIn(formik.values, startFieldName))?.startOf('day')) {
                    form.setFieldValue(startFieldName, value);
                  }
                }}
                {...endDateLimits}
              />
              {endDateValidation(field.value) && <InputError>{endDateValidation(field.value)}</InputError>}
            </>
          ),
          false,
          12,
          12,
        )}
      </Grid>
    </>
  );
};

export default StartEndDate;
