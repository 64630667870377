/* eslint-disable guard-for-in,no-restricted-syntax,no-plusplus */
import React from 'react';
import get from 'lodash/get';
import { DateTime, Duration } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  addActivityLine,
  addLabourCategoryLine,
  addPeriod,
  addShiftSettings,
  addVolumeLine,
  deleteLabourCategoryLine,
  deletePeriod,
  deleteShiftSettings,
  doAddWzp,
  doDeleteWzp,
  extractDiffFieldsDeep,
  savePlaningParametersFromTo,
  spreadIntoChildFieldIfNotEmpty,
  timeToApiFormat,
  waitForSaveAll,
} from 'utils/commonDetailSaga';
import { parseDate, parseTime } from 'utils/dateTime';

import { api, convertEntityWithPlanningParametersFromApi, formatDateToApiFormat, withUrl } from '../../utils/api';
import { storeActivitiesByPlanAction } from '../App/actions';
import { CLOSE_MODAL } from '../UploadModal/constants';
import {
  reloadPlanDetail,
  saveMasterPlanConflict,
  savePlanDone,
  savePlanError,
  setPeriodIndexPlanAction,
  startPlanCopyAction,
  storePlan,
  storePlanEdit,
  storePlanLoading,
} from './actions';
import {
  ADD_ACTIVITY_LINE,
  ADD_LABOUR_AVAILABILITY_CATEGORY_LINE,
  ADD_MASTER_PLANNED_VOLUME_LINE,
  ADD_PERIOD_MASTER_PLAN,
  ADD_SHIFT_SETTINGS_LINE,
  ADD_WZP,
  COPY_MASTER_PLAN,
  COPY_PERIOD_MASTER_PLAN,
  DELETE_ACTIVITY_LINES,
  DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE,
  DELETE_PERIOD_MASTER_PLAN,
  DELETE_SHIFT_SETTINGS_LINE,
  DELETE_WZP,
  LOAD_MASTER_PLAN,
  SAVE_MASTER_PLAN,
  SAVE_MASTER_PLANNED_VOLUME_HEADER,
  SWITCH_TO_DISPLAY_MASTER_PLAN,
} from './constants';
import messages from './messages';
import { selectPeriodIdFromPlan, selectPlan, selectPlanningParametersId } from './selectors';

export default function* defaultSaga() {
  yield takeLatest(SAVE_MASTER_PLANNED_VOLUME_HEADER, savePlanPlaningParametersFromTo);
  yield takeLatest(ADD_MASTER_PLANNED_VOLUME_LINE, addPlannedVolumeLine);
  yield takeLatest(ADD_ACTIVITY_LINE, addPlanActivityLine);
  yield takeLatest(DELETE_ACTIVITY_LINES, deleteActivityLines);
  yield takeLatest(COPY_MASTER_PLAN, copyPlan);
  yield takeLatest(SAVE_MASTER_PLAN, saveAllPlan);
  yield takeLatest(DELETE_WZP, doDeleteWzpPlan);
  yield takeLatest(ADD_WZP, doAddWzpPlan);
  yield takeLatest(LOAD_MASTER_PLAN, doLoadPlan);
  yield takeLatest(SWITCH_TO_DISPLAY_MASTER_PLAN, doSwitchToDisplay);
  yield takeLatest([ADD_PERIOD_MASTER_PLAN, COPY_PERIOD_MASTER_PLAN], addPlanPeriod);
  yield takeLatest(DELETE_PERIOD_MASTER_PLAN, deletePlanPeriod);
  yield takeLatest(ADD_LABOUR_AVAILABILITY_CATEGORY_LINE, addLabourAvailabilityCategoryLine);
  yield takeLatest(DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE, deleteLabourAvailabilityCategoryLine);
  yield takeLatest(ADD_SHIFT_SETTINGS_LINE, addShiftSettingsLine);
  yield takeLatest(DELETE_SHIFT_SETTINGS_LINE, deleteShiftSettingsLine);
  yield takeLatest(CLOSE_MODAL, doOnCloseUploadModal);
}

const newPlan = {
  name: '',
  description: '',
  deleted: false,
  planningParameters: {},
};

function* doLoadPlan(action) {
  yield put(storePlanLoading(true));
  const masterPlan = action.payload.id ? yield call(fetchPlan, action.payload.id) : newPlan;
  if (masterPlan) {
    masterPlan.reloadCount = action.payload.reloadCount === undefined ? 0 : action.payload.reloadCount + 1;
    yield put(storeActivitiesByPlanAction(masterPlan));
    yield put(storePlan(masterPlan));
    if (action.payload.copy === true) {
      yield put(startPlanCopyAction());
    } else if (action.payload.edit === true || action.payload.edit === false) {
      yield put(storePlanEdit(action.payload.edit));
    }
  }
  return masterPlan;
}

export function* fetchReportingRuns(id, logResponse) {
  const runsResponse = yield call(api, withUrl(`/masterPlan/${id}/reportingRuns`).disableLogCalls(logResponse || true));
  if (runsResponse.isOk) {
    return runsResponse.data.sort((a, b) => (a.requestedDate > b.requestedDate ? -1 : 1));
  }
  return [];
}

export function convertDateTimeValuesForMasterPlan(data) {
  const scheduleStartDate = data.scheduleStartDate ? DateTime.fromISO(data.scheduleStartDate) : null;
  return {
    ...data,
    scheduleStartTime: scheduleStartDate
      ? timeToApiFormat(
          Duration.fromObject({ hours: scheduleStartDate.hour, minutes: scheduleStartDate.minute, seconds: 0 }),
        )
      : null,
    scheduleStartDay: scheduleStartDate,
  };
}

export function* fetchPlan(id, logResponse) {
  // the masterPlan could be big se logging might have performance impact
  // atm it can be optionally disabled
  const response = yield call(
    api,
    withUrl(`/masterPlan/${id}`)
      .andTitle('Loading Master Plan')
      .disableLogCalls(logResponse || true),
  );
  if (response.isOk) {
    let data = convertEntityWithPlanningParametersFromApi(response.data);
    data = convertDateTimeValuesForMasterPlan(data);
    return data;
  }
  return null;
}

function* wrapWithPlanningParametersAndCall(action, functionToCall, toastMessage) {
  const dataWrapper = action.payload;
  dataWrapper.planningParametersId = yield select(selectPlanningParametersId);
  dataWrapper.periodId = yield select(selectPeriodIdFromPlan);
  const response = yield call(functionToCall, dataWrapper);
  if (response.isOk) {
    yield call(reload);
    if (toastMessage) {
      toast(toastMessage);
    }
  }
  return response;
}

function* savePlanPlaningParametersFromTo(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, savePlaningParametersFromTo, 'Start/end time updated');
}

function* doDeleteWzpPlan(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, doDeleteWzp);
}

function* doAddWzpPlan(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, doAddWzp);
}

function* doSwitchToDisplay(action) {
  const dataWrapper = action.payload;
  if (dataWrapper) {
    const response = yield call(waitForSaveAll, dataWrapper);
    if (response && response.isOk) {
      yield put(storePlanEdit(false));
      yield call(reload);
    }
  }
}

function* addPlannedVolumeLine(action) {
  const planningParametersId = yield select(selectPlanningParametersId);
  const response = yield call(addVolumeLine, action, planningParametersId);
  if (response.isOk) {
    yield call(reload);
  }
}

function* addPlanActivityLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addActivityLine);
}

function* addPlanPeriod(action) {
  const result = yield call(wrapWithPlanningParametersAndCall, action, addPeriod);
  yield put(setPeriodIndexPlanAction(-1));
  if (result && result.data && result.data.returnMessage) {
    toast(result.data.returnMessage);
  }
}

function* deletePlanPeriod(action) {
  yield put(setPeriodIndexPlanAction(0));
  return yield call(wrapWithPlanningParametersAndCall, action, deletePeriod);
}

function* deleteActivityLines(action) {
  const masterPlan = yield select(selectPlan);
  const planningParametersId = get(masterPlan, 'planningParameters.id');
  const paId = action.payload.id;
  const periodId = yield select(selectPeriodIdFromPlan);
  const url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityParameters/${paId}/delete`;
  const response = yield call(api, withUrl(url).post().andTitle('Deleting activity line'));
  if (response.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.planActivityLineDeleted} />);
  }
}

function* copyPlan(payload) {
  if (payload) {
    const { id, name, description } = payload;
    const url = `/masterPlan/${id}/copy`;
    const response = yield call(api, withUrl(url).post({ name, description }).andTitle('Copying masterPlan'));
    if (response.isOk) {
      yield put(reloadPlanDetail(response.data.id));
      toast(<FormattedMessage {...messages.planCopied} />);
    }
  } else {
    console.error('Data missing');
  }
}

function* saveAllPlan(action) {
  const values = action.payload;
  const saved = yield select(selectPlan);
  const payload = {};
  spreadIntoChildFieldIfNotEmpty(payload, 'masterPlan', extractDiffFieldsDeep(saved, values));
  if (payload.masterPlan.scheduleStartDay || payload.masterPlan.scheduleStartTime) {
    const date = parseDate(payload.masterPlan.scheduleStartDay || saved.scheduleStartDay);
    const time = parseTime(payload.masterPlan.scheduleStartTime || saved.scheduleStartTime);
    const finalDate = DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: time.hours,
      minute: time.minutes,
    });
    payload.masterPlan.scheduleStartDate = finalDate.toISO();
    delete payload.masterPlan.scheduleStartDay;
    delete payload.masterPlan.scheduleStartTime;
    delete payload.masterPlan.clearSchedule;
  }
  if (payload.masterPlan.customers) {
    payload.masterPlan.customers = payload.masterPlan.customers.map(c => c.id);
  }
  if (payload.masterPlan.facilities) {
    payload.masterPlan.facilities = payload.masterPlan.facilities.map(f => ({ facilityId: f.id, ...f }));
  }
  if (payload.masterPlan.managementUnits) {
    payload.masterPlan.managementUnits = payload.masterPlan.managementUnits.map(f => ({ managementUnitId: f.id, ...f }));
  }
  if (payload.masterPlan.plans) {
    payload.masterPlan.plans = payload.masterPlan.plans.map(p => ({
      ...p,
      validFrom: formatDateToApiFormat(p.validFrom),
      validTo: formatDateToApiFormat(p.validTo),
    }));
  }
  const isInsert = !saved.id;
  const url = isInsert ? `/masterPlan/` : `/masterPlan/${saved.id}`;
  const response = yield call(api, withUrl(url).post(payload.masterPlan).andTitle('Saving masterPlan'));
  if (response.isOk) {
    let masterPlan = convertEntityWithPlanningParametersFromApi(response.data);
    masterPlan = convertDateTimeValuesForMasterPlan(masterPlan);
    const reportingRuns = yield fetchReportingRuns(saved.id, false);
    masterPlan.reloadCount = saved.reloadCount + 1;
    masterPlan.reportingRuns = reportingRuns;
    yield put(storePlan(masterPlan));
    yield put(storeActivitiesByPlanAction(masterPlan));
    toast(<FormattedMessage {...messages.planSaved} />);
  } else if (response.errorType === 'MASTER_PLAN_IN_COLLISION') {
    yield put(saveMasterPlanConflict(response));
  } else if (response.errorType === 'PLAN_HAS_NO_FACILITY') {
    toast.error(messages.planHasNoFacility.defaultMessage);
    yield put(savePlanError(response));
  } else {
    yield put(savePlanError(response));
  }
  yield put(savePlanDone(response));
  return response;
}

function* reload(id) {
  // eslint-disable-next-line no-unneeded-ternary
  const saved = yield select(selectPlan);
  const planId = id || saved.id;
  yield call(doLoadPlan, reloadPlanDetail(planId, saved.reloadCount));
}

function* addLabourAvailabilityCategoryLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addLabourCategoryLine);
}

function* deleteLabourAvailabilityCategoryLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, deleteLabourCategoryLine);
}

function* addShiftSettingsLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addShiftSettings);
}

function* deleteShiftSettingsLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, deleteShiftSettings);
}

function* doOnCloseUploadModal(action) {
  if (action && action.payload.doReload && action.payload.entity === 'plans') {
    yield call(reload);
  }
}
