import React, { useEffect, useRef } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { connect as formikConnect, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { GroupWithToolTip } from 'components/NameWithToolTip';
import AgTable from 'components/Table';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { formatDate } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import {  mappingOMSCodes } from 'containers/PlanResultPage/actions';

import messages from './messages';
import { EntityEntry, ForecastConversionRateTableDTOWithId, ForecastForm, ForecastListEntityEntry } from './types';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-body-viewport div[col-id='activityForecastId'] {
    white-space: normal;
    line-height: 20px;
    overflow: auto;
  }

  .ag-rich-select-list {
    width: 540px;
  }

  .ag-header-viewport .ag-header-cell-menu-button {
    display: none;
  }

  .ag-header-viewport div[col-id='1_0'],
  .ag-header-viewport div[col-id='dates.0.activity_1'],
  .ag-header-viewport div[col-id='dates.0.job_1'],
  .ag-body-viewport div[col-id='dates.0.activity_1'],
  .ag-body-viewport div[col-id='dates.0.job_1'] {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.64);
  }
`;

const columnDefs = (
  formik,
  editable: boolean,
  omsCodesById: { [value: number]: EntityEntry },
  activityForecastList: ForecastListEntityEntry[],
  dates: DateTime[],
  intl: InjectedIntl,
  planActivityMapping: boolean,
) => {
  const distinctactivityForecastList = activityForecastList && activityForecastList.filter(
    (item, index, self) =>
      index === self.findIndex(t => (t.customerId === item.customerId) && (t.departmentId === item.departmentId) && (t.value === item.value) && (t.uom === item.uom))
   );
  const filteredActivityForecastList = distinctactivityForecastList
    ? distinctactivityForecastList.filter(a => a.customerId && a.departmentId)
    : [];
  const cellStyle = ({ data, node }) => (data?.omsId || node.group ? {} : { color: 'red' });
  const extendedActivityForecastList = [
    { value: null, omsId: null, label: intl.formatMessage(messages.omsCodesTableUnassign) },
    ...filteredActivityForecastList,
  ];
  const getAvailableActivitiesForRow = params => {
    let finalList;
    if (params.data.activityForecastId) {
      finalList = extendedActivityForecastList.filter(value => !value.omsId || value.omsId === params.data?.omsId);
    } else {
      finalList = filteredActivityForecastList.filter(value => !value.omsId || value.omsId === params.data?.omsId);
    }
    // the activity can only be assigned to 1 smartProdSourceId
    const assignedActivitIds = {};
    params.node.parent.parent.allLeafChildren.forEach(node => {
      if (!assignedActivitIds[node.data.activityForecastId]) {
        assignedActivitIds[node.data.activityForecastId] = new Set();
      }
      assignedActivitIds[node.data.activityForecastId].add(node.data.smartProdSourceId);
    });
    return finalList.filter(activity => {
      const assigned = assignedActivitIds[activity.value];
      return (
        !activity.value ||
        !assigned || // activity is not assigned yet
        (assigned.has(params.data.smartProdSourceId) && assigned.size <= 1) // activity is assigned to the same smartProdSourceId
      );
    });
  };
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'omsId',
      field: 'omsId',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.omsCodesTableOMSCode),
      valueFormatter: ({ value }) => omsCodesById[value]?.label || '',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      pinned: 'left',
      width: 150,
      hide: true,
      suppressSizeToFit: true,
      rowGroup: false,
      resizable: true,
      cellStyle,
    },
    {
      colId: 'smartProdSourceName',
      field: 'smartProdSourceName',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.omsCodesTableSmartProdSource),
      cellRenderer: 'agGroupCellRenderer',
      checkboxSelection: false,
      sortable: true,
      pinned: 'left',
      width: 180,
      hide: true,
      suppressSizeToFit: true,
      rowGroup: true,
      resizable: true,
      cellStyle,
    },
    {
      colId: 'activityForecastId',
      field: 'activityForecastId',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.omsCodesTableSmartPlanActivity),
      valueFormatter: ({ value }) => activityForecastList.find(s => s.value === value)?.label,
      sortable: true,
      pinned: 'left',
      width: 290,
      suppressSizeToFit: true,
      resizable: true,
      editable: params =>
        editable && planActivityMapping &&
        (!!getAvailableActivitiesForRow(params).filter(value => value.omsId === params.data?.omsId).length ||
          params.data.activityForecastId),
      singleClickEdit: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => ({
        component: 'agRichSelect',
        values: getAvailableActivitiesForRow(params),
        cellRenderer: p => p?.value?.label || '',
      }),
      cellRenderer: ({ value }) => {
        const activity = activityForecastList.find(s => s.value === value);
        return activity ? activity.label : '';
      },
      valueSetter: params => {
        if (typeof params.newValue !== 'object') {
          return false;
        }
        if (params.data) {
          if (params.data.activityForecastId !== params.newValue?.value) {
            params.data.activityForecastId = params.newValue?.value;
            // params.data.dates = [];
          }
        }
        return true;
      },
      cellStyle: ({ data, node }) =>{
        //@ts-ignore
        const highLightActivityData = activityForecastList?.filter((activity)=>activity?.ForecastOutOfPlanDateInterval) || [];
        const isHighlighted = highLightActivityData?.filter((act) =>act?.value == data?.activityForecastId);
        if(isHighlighted?.length > 0){
          return {color: 'red'}
        }else{
          return {color: '#000'}
        }
      }
    },
    {
      colId: 'omsCodesTableSmartProdJobCode',
      field: 'omsCodesTableSmartProdJobCode',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.omsCodesTableSmartProdJobCodeWithCustomer),
      valueGetter: ({ data }) => (data ? `${data.smartProdJobCodeName} (${data.customer} / ${data.smartProdJobCodeUomName})` : ''),
      sortable: true,
      pinned: 'left',
      width: 280,
      suppressSizeToFit: true,
      resizable: true,
      cellStyle,
    },
    {
      headerName: intl.formatMessage(messages.omsCodesTableSmartConversionRate),
      children: [null, ...dates].map((date, i) => ({
        field: 'date',
        headerName: i === 0 ? intl.formatMessage(messages.omsCodesTableDefaultConversionRate) : `${formatDate(date)}`,
        children: [
          {
            field: `dates.${i}.activity`,
            headerName: intl.formatMessage(messages.omsCodesTableActivity),
            width: 110,
            filter: null,
            singleClickEdit: true,
            sortable: false,
            resizable: true,
            suppressSizeToFit: true,
            valueGetter: params => {
              if (params.data?.activityForecastId) {
                return params.data.dates?.[i]?.activity || '';
              }
              return '';
            },
            editable: ({ data }) => !!data.dates?.length && editable,
            cellStyle: ({ data, node }) =>
              data?.dates?.length || node.group ? {} : { backgroundColor: 'rgba(0,0,0,0.05)' },
          },
          {
            field: `dates.${i}.job`,
            headerName: intl.formatMessage(messages.omsCodesTableJob),
            width: 110,
            flex: 1,
            filter: null,
            singleClickEdit: true,
            sortable: false,
            resizable: true,
            suppressSizeToFit: true,
            valueGetter: params => {
              if (params.data?.activityForecastId) {
                return params.data.dates?.[i]?.job || '';
              }
              return '';
            },
            editable: ({ data }) => !!data.dates?.length && editable,
            cellStyle: ({ data, node }) =>
              data?.dates?.length || node.group ? {} : { backgroundColor: 'rgba(0,0,0,0.05)' },
          },
        ],
      })),
    },
  ];
  return columns;
};
type Props = {
  formik?: FormikProps<ForecastForm>;
  intl: InjectedIntl;
  forecastEdit: boolean;
  forecastConversionRates: ForecastConversionRateTableDTOWithId[];
  dates: DateTime[];
  allOmsCodes: EntityEntry[];
  activityForecastList: ForecastListEntityEntry[];
  gridApi;
  setGridApi;
  onFilterChanged: Function;
  selectedForecastActivity?: ForecastListEntityEntry;
  planActivityMapping?: boolean;
};

const OMSCodesAndSmartProdJobsTable: React.FC<Props> = ({
  formik,
  intl,
  forecastEdit,
  forecastConversionRates,
  dates,
  allOmsCodes,
  activityForecastList,
  gridApi,
  setGridApi,
  onFilterChanged,
  selectedForecastActivity,
  planActivityMapping,
}) => {
  // this is here because ag-grid sometimes uses initialy loaded isRowSelectable callback
  // and this allows to change reference to selected activity inside it
  const selectedForecastActivityRef = useRef(null);
  const omsCodesById = {};
  allOmsCodes &&
    allOmsCodes.forEach(omsCode => {
      omsCodesById[omsCode.value] = omsCode;
    });
    const dispatch = useDispatch();
  const isRowSelectable = row => {
    if (!forecastEdit) {
      return false;
    }
    if (row.group) {
      return false;
    }
    if (selectedForecastActivityRef && selectedForecastActivityRef.current) {
      return row.data.omsId === selectedForecastActivityRef.current.omsId;
    }
    return false;
  };

  const updateSelectableItems = () => {
    if (gridApi) {
      const updateSelectable = rowNode => {
        const isSelectable = isRowSelectable(rowNode);
        if (!isSelectable) {
          rowNode.setSelected(false);
        }
        rowNode.setRowSelectable(isSelectable);
      };
      gridApi.api.forEachNode(updateSelectable);
      gridApi.api.refreshCells({ force: true });
      gridApi.api.redrawRows();
      if(!isEmpty(gridApi.api.getFilterModel())) sessionStorage.setItem('OMSFilterModel', JSON.stringify(gridApi.api.getFilterModel()));
    }
  };

  const onGridReady = params => {
    setGridApi(params);
  };

  const handleCellValueChanged = params => {
    const { data, column, newValue } = params;
    if (column.colId === 'activityForecastId') {
      formik.setFieldValue(`forecastConversionRates[${data.id}].activityForecastId`, newValue || null);
    } else {
      formik.setFieldValue(`forecastConversionRates[${data.id}].${column.colDef.field}`, newValue || null);
    }
  };

  const handleFilterChanged = () => {
    onFilterChanged();
    updateSelectableItems();
  };

  useEffect(() => {
    if (gridApi) {
      const colDefs = columnDefs(formik, forecastEdit, omsCodesById, activityForecastList, dates, intl, planActivityMapping);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.setRowData(forecastConversionRates);
      gridApi.api.sizeColumnsToFit();
      const filterModel = JSON.parse(sessionStorage.getItem('OMSFilterModel'));
      gridApi.api.setFilterModel(filterModel);
    }
  }, [gridApi, forecastEdit, forecastConversionRates]);

  useEffect(() => {
    selectedForecastActivityRef.current = selectedForecastActivity;
    updateSelectableItems();
  }, [forecastEdit, selectedForecastActivity]);

  const ROW_HEIGHT = 45;
  const allCodesMapped1 = forecastConversionRates && forecastConversionRates.filter((data)=>data.activityForecastId &&  data.activityForecastId !== null);
    if(forecastConversionRates){
       if(forecastConversionRates.length == allCodesMapped1.length){
        dispatch(mappingOMSCodes(false));
       } else {
        dispatch(mappingOMSCodes(true));
       }
    }

  return (
    allOmsCodes && (
      <Table
        defaultColDef={{
          flex: 1,
        }}
        frameworkComponents={{
          acWithToolTip: GroupWithToolTip(),
          groupFilter: GroupFilter,
        }}
        sortable
        pagination={false}
        suppressMovableColumns
        onCellValueChanged={handleCellValueChanged}
        columnDefs={columnDefs(formik, forecastEdit, omsCodesById, activityForecastList, dates, intl, planActivityMapping)}
        rowData={forecastConversionRates}
        rowHeight={ROW_HEIGHT}
        height={200 + ROW_HEIGHT * Math.min(10, forecastConversionRates.length)}
        rowSelection="multiple"
        suppressRowClickSelection
        onGridReady={onGridReady}
        isRowSelectable={isRowSelectable}
        onFilterChanged={handleFilterChanged}
        groupDefaultExpanded={1}
        autoGroupColumnDef={{
          colId: 'groupping',
          headerName: `${intl.formatMessage(messages.omsCodesTableSmartProdSource)} \u2192 ${intl.formatMessage(
            messages.omsCodesTableOMSCode,
          )}`,
          field: 'omsId',
          valueFormatter: data => omsCodesById[data.value]?.label || '',
          width: 250,
          suppressSizeToFit: true,
          pinned: true,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: { checkbox: true },
          menuTabs: ['filterMenuTab'],
          filter: 'groupFilter',
          filterParams: {
            columns: ['smartProdSourceName', 'omsId'],
            applyButton: true,
            clearButton: true,
          },
        }}
        groupSelectsChildren={false}
        suppressAggFuncInHeader
        showCollapseButtonsInsideTable
      />
    )
  );
};

export default injectIntl(formikConnect(OMSCodesAndSmartProdJobsTable));
