import { defineMessages } from 'react-intl';


export default defineMessages({
  shiftRedistribution: {
    id: 'app.components.ShiftDistributionWarningDialog.shiftRedistribution',
    defaultMessage: 'Shift redistribution',
  },
  wzpRedistribution: {
    id: 'app.components.ShiftDistributionWarningDialog.wzpRedistribution',
    defaultMessage: 'WZP redistribution',
  },
  shiftRedistributionDescription: {
    id: 'app.components.ShiftDistributionWarningDialog.shiftRedistributionDescription',
    defaultMessage:
      'Days are removed from shifts which are used in time transformation. How do you want to redistribute transformation?',
  },
  wzpRedistributionDescription: {
    id: 'app.components.ShiftDistributionWarningDialog.wzpRedistributionDescription',
    defaultMessage:
      'Days are removed from wzps which are used in time transformation. How do you want to redistribute transformation?',
  },
  shiftRedistributionEqually: {
    id: 'app.components.ShiftDistributionWarningDialog.shiftRedistributionEqually',
    defaultMessage: 'Equally',
  },
  shiftRedistributionProportionally: {
    id: 'app.components.ShiftDistributionWarningDialog.shiftRedistributionProportionally',
    defaultMessage: 'Proportionally',
  },
});
