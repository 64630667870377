import * as React from 'react';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import * as Yup from 'yup';
import { formatDateTime } from 'utils/dateTime';

import BasePage from 'components/BasePage';
import { EditableList } from 'components/CdmEditableList';
import { FacilityItemNoUnits } from 'components/CdmEditableList/FacilityItem';
import { FormikText, FormikTextInput, InlineLabel, WarningText } from 'components/FormikTextInput';
import { ParameterGroup } from 'components/GroupParametersValuesTable/types';
import Label from 'components/Label';
import NamedSection from 'components/NamedSection';
import ToggleSection from 'components/ToggleSection';
import { formatDateToApiFormat } from 'utils/api';
import EnchantedMap from 'utils/enchantedMap';
import injectSaga from 'utils/injectSaga';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { loadSearch } from 'utils/url';
import { findDiffs, findOverlaps } from 'utils/utilsTs';

import createUploadModal from '../UploadModal';
import {
  invokeStaffSync,
  loadAvailablePlans,
  loadAvailableUnproductiveActivities,
  loadDefaultScheduleParameters,
  loadScheduleStaff,
  loadShiftSchedule,
  loadStaffSyncState,
  saveShiftSchedule,
  storeShiftScheduleEdit,
  storeScheduleStaffSyncState,
} from './actions';
import ActivitiesTable from './activitiesTable';
import KronosActivitiesTable from './kronosActivitiesTable';
import messages from './messages';
import ParameterSetsSection from './parameterSetsSection';
import PlanTabs from './planTabs';
import saga from './saga';
import {
  selectDefaultScheduleParameters,
  selectScheduleStaffSyncState,
  selectShiftSchedule,
  selectShiftScheduleEdit,
  selectShiftScheduleStaff,
} from './selectors';
import { getToken } from 'containers/App/selectors';
import StaffTable from './staffTable';
import ShiftScheduleDetailToolbar from './toolbar';
import AllTransferCosts from './transferCosts/allTransferCosts';
import { mapTransferCostsFromAPI, mapTransferCostsToAPI } from './transferCosts/mappingUtils';
import { ApiSchedule, ScheduleStaff, ScheduleStaffSyncState } from './types';
import { convertArrayToMapWithReduce } from './utils';
import { ACTIVE_ENV } from '../../utils/activeEnv';

const UploadModal = createUploadModal('shiftScheduleDetail');

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is mandatory'),
  plans: Yup.array().of(
    Yup.object({
      validFrom: Yup.object(),
      validTo: Yup.object().nullable(),
    }).test(
      'planValidationDates',
      'End validity date must be empty or after Valid from',
      p => !p.validTo || !!(p.validTo && p.validFrom && p.validTo.toMillis() >= p.validFrom.toMillis()),
    ),
  ),
});

const Section = styled(NamedSection)`
  input {
    background-color: ${props => props.theme.color.grey5};
  }
  vertical-align: top;
`;

const GrowableSection = styled(Section)`
  flex-grow: 4;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  margin: 0 0 12px;
  column-gap: 10px;
`;

const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const BigSection = styled(NamedSection)`
  flex-grow: 1;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const FullWidthToggleSection = styled(ToggleSection)`
  min-width: 1170px;
`;

const SyncLabel = styled(Label)`
  display: inline-block;
  max-width: 320px;
  margin-bottom: 10px;
`;

const ActivityWarning = styled(Label)`
  color: ${props => props.theme.color.red};
  margin-bottom: 10px;
`;

const SectionsWrapper = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
`;

const BasicAndPlansSections = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ShiftScheduleDetailPageProps {
  shiftSchedule?: ApiSchedule;

  scheduleStaff: ScheduleStaff[];

  scheduleStaffSyncState: ScheduleStaffSyncState;

  defaultScheduleParameters: ParameterGroup[];

  submitting?: boolean;

  isEdit: boolean;

  history: any;

  intl: any;

  match: any;

  user: any;

  hasPerm(perm: any): () => any;

  save(action: any): () => any;

  invokeStaffSync(id: number): () => any;

  invokeActivitiesSync(): () => any;

  loadShiftSchedule(action: any): () => any;

  loadScheduleStaff(action: any): () => any;

  loadStaffSyncState(action: any): () => any;

  storeShiftScheduleEdit(action: boolean): () => any;

  loadAvailablePlans(action: number[]): () => any;

  loadAvailableUnproductiveActivities(action: number[]): () => any;

  loadDefaultScheduleParameters(action: number[]): () => any;

  storeScheduleStaffSyncState(action: any): () => any;

  token?: string
}

class ShiftScheduleDetailPage extends Component<ShiftScheduleDetailPageProps, {}> {
  public state = {
    isStaffExpanded: false,
  };

  private staffPullingJob = null;

  public componentDidMount() {
    const query = loadSearch(this.props);
    const isEdit = query.get('isEdit') === 'true';
    this.props.storeShiftScheduleEdit(isEdit);
    this.props.loadShiftSchedule(this.props.match.params.id);
    this.props.loadScheduleStaff(this.props.match.params.id);
    this.props.loadDefaultScheduleParameters(this.props.match.params.id);
    this.props.loadStaffSyncState(this.props.match.params.id);
    const fullUrl = `${ACTIVE_ENV.basePathBe}/schedules/${this.props.match.params.id}/staff/state`;
    const fetchOptions = { method: 'GET', headers: { Authorization: `Bearer ${this.props.token}` } };
    fetch(fullUrl, fetchOptions)
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      throw response;
    })
    .then(results =>{
      if(results.isRunning){
        this.initStaffSyncPullingJob()
      }
    })
  }

  public componentWillUnmount() {
    clearInterval(this.staffPullingJob);
  }

  public componentWillUpdate(nextProps: Readonly<ShiftScheduleDetailPageProps>, nextState: Readonly<{}>) {
    if(this.props?.shiftSchedule){
      sessionStorage.setItem('scheduleName', this.props?.shiftSchedule?.name);
    }
    if (this.props.scheduleStaffSyncState?.isRunning && !nextProps.scheduleStaffSyncState?.isRunning) {
      this.props.loadScheduleStaff(this.props.match.params.id);
      this.props.loadShiftSchedule(this.props.match.params.id);
      this.props.loadDefaultScheduleParameters(this.props.match.params.id);
      clearInterval(this.staffPullingJob);
    }
  }

  public render() {
    if (!this.props.shiftSchedule.id) {
      return (
        <BasePage labelMessage={messages.header} labelValues={{ name: '' }}>
          Loading...
        </BasePage>
      );
    }

    const doSubmit = (values: ApiSchedule, actions: FormikActions<ApiSchedule>) => {
      // When there are two plans with open interval (validTo == null),
      // the first one must be closed using start date of another. Store such changes here
      const planChangesToApply = new EnchantedMap<number, DateTime>();

      const facilityToPlans = convertArrayToMapWithReduce(
        values.facilities,
        f => f.id,
        () => [],
      );

      values.plans.forEach(p => {
        p.plan.planningArea.facilities.forEach(planFacility => {
          facilityToPlans.get(planFacility.id)?.push(p);
        });
      });

      for (
        let facilityToPlansIndex = 0;
        facilityToPlansIndex < facilityToPlans.listValues().length;
        facilityToPlansIndex += 1
      ) {
        const group = facilityToPlans.listValues()[facilityToPlansIndex];
        // For auto enclosing open intervals, sort it by start date so we always pick the closest one
        const sorted = group.sort((a, b) => (a.validFrom < b.validFrom ? -1 : 1));

        // Iterate over groups to enclose sequent open intervals
        for (let i = 0; i < sorted.length - 1; i += 1) {
          if (!sorted[i].validTo) {
            // Plans have multiple facilities and the same plan might be in multiple groups.
            // So there might be an override from another group, then pick the closest one.
            const alreadyOverriden = planChangesToApply.get(sorted[i].planId);
            const candidateVal = sorted[i + 1].validFrom;

            const valueToSet = !alreadyOverriden || candidateVal < alreadyOverriden ? candidateVal : alreadyOverriden;

            planChangesToApply.set(sorted[i].planId, valueToSet);
          }
        }

        // Check for overlaps in plans
        const overlaps = findOverlaps(group);
        if (overlaps.length > 0) {
          const planNames = overlaps.map(p => p.plan.name).join(', ');
          toast.error(`There is a dates overlap in plans ${planNames}`);
          actions.setSubmitting(false);
          return;
        }
      }

      // Apply validTo overrides to plans
      values.plans = values.plans.map(p => {
        const dateOverride = planChangesToApply.get(p.planId);
        if (dateOverride) {
          return {
            ...p,
            validTo: formatDateToApiFormat(dateOverride.minus({ days: 1 })),
          };
        }
        return p;
      });

      this.props.save({
        schedule: this.convertFormDataForSave(values),
        scheduleName: values.name,
      });
      actions.setSubmitting(false);

      // Cache of changed activity transfer costs must be reset
      values.activityTransferCostsMap = new EnchantedMap();
    };

    return (
      <BasePage labelMessage={messages.header} labelValues={{ name: this.props.shiftSchedule.name }} noMaxWidth>
         <Helmet>
          <title>{this.props.intl.formatMessage(messages.header, { name: this.props.shiftSchedule.name })}</title>
          <meta name="description" content="Schedule detail page" />
        </Helmet>
        <Formik
          validateOnChange
          onSubmit={doSubmit}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={this.props.shiftSchedule}
          render={this.renderForm}
        />
      </BasePage>
    );
  }

  /**
   * Pull for changes in staff synchronization
   */
  private initStaffSyncPullingJob() {
    const staffSyncStatePullingJob = () => {
      this.props.loadStaffSyncState(this.props.match.params.id);
    };

    staffSyncStatePullingJob();
    this.staffPullingJob = setInterval(staffSyncStatePullingJob, 4000);
  }

  private startKronosSync() {
    this.props.invokeStaffSync(this.props.shiftSchedule.id);
    this.initStaffSyncPullingJob();
  }

  /**
   * Renders the whole form
   */
  private renderForm = (formik: FormikProps<ApiSchedule>) => {
    const { isEdit } = this.props;

    const staffExpandHandler = () => {
      this.setState({ isStaffExpanded: !this.state.isStaffExpanded });
    };

    const checkProgress = () => {
      if(!this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages){
        return messages.syncFailed
      }else if(this.props.scheduleStaffSyncState?.isRunning){
        return messages.syncInProgress
      }else{
        return ''
      }
    }
    const checkError = () => {
      if(!this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages){
        return messages.warningAvailable
      }else if(this.props.scheduleStaffSyncState?.isRunning){
        return messages.warningAvailable
      }else{
        return ''
      }
    }
    const facilityById = convertArrayToMapWithReduce(
      this.props.shiftSchedule.facilities,
      item => item.id.toString(),
      item => item.name,
    );

    const errorMessageParser = str => {
      if (str.indexOf('::') > -1) {
        const tokens = str.split('::');

        const facilityPart = `${this.props.intl.formatMessage(messages.facility)} ${facilityById.get(tokens[0])}: `;
        const detailPart = `${this.props.intl.formatMessage(messages[`syncError_${tokens[1]}`], { param1: tokens[2] })}`;

        return facilityPart + detailPart;
      }
      return str;
    };
    const errorMessage =  this.props.scheduleStaffSyncState?.messages?.split(';;').map(errorMessageParser)

    return (
      <Form>
        <Prompt when={formik.dirty} message={this.props.intl.formatMessage(messages.confirmDirty)} />
        {/* @ts-ignore */}
        <ShiftScheduleDetailToolbar invokeStaffSync={() => this.startKronosSync()} />

        <SectionsWrapper>
          <BasicAndPlansSections>
            <ColumnSection>
              {this.renderBasicInfoSection(formik)}
              {this.renderFacilitiesSection(formik)}
            </ColumnSection>
            <BigSection message={messages.plan} noMaxWidth>
              {/* @ts-ignore */}
              <PlanTabs formik={formik} isEdit={isEdit} />
            </BigSection>
          </BasicAndPlansSections>
         <FullWidthToggleSection
           message={messages.warning}
           subtitleToShow={checkError()}
         >
            {
              !this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages
                ?
                <WarningText>{errorMessage && errorMessage[0]}</WarningText>
                :
                this.props.scheduleStaffSyncState?.isRunning ? <WarningText><FormattedMessage {...messages.syncInProgress} /></WarningText>
                :
                <WarningText><FormattedMessage {...messages.noWarningAvailable} /></WarningText>
            }
         </FullWidthToggleSection>
          <FullWidthToggleSection
            message={messages.staff}
            subtitleToShow={checkProgress()}
            expanded={this.state.isStaffExpanded}
            onToggle={staffExpandHandler}
          >
            {this.renderSyncInfoRow()}
            <StaffTable
              facilities={formik.values.facilities}
              staff={this.props.scheduleStaff}
              scheduleId={this.props.shiftSchedule.id}
              history={this.props.history}
            />
          </FullWidthToggleSection>
          <FullWidthToggleSection subtitleToShow={checkProgress()} message={messages.smartShiftJobs}>
            {this.renderSyncInfoRow()}
            <ActivitiesTable formik={formik} history={this.props.history} isEdit={isEdit} />
            <ToggleSection message={messages.smartShiftOnly}>
              <KronosActivitiesTable formik={formik} history={this.props.history} />
            </ToggleSection>
          </FullWidthToggleSection>

          <FullWidthToggleSection message={messages.transferCosts}>
            {/* @ts-ignore */}
            <AllTransferCosts formik={formik} isEdit={isEdit} />
          </FullWidthToggleSection>

          <FullWidthToggleSection message={messages.parameterSets}>
            {/* @ts-ignore */}
            <ParameterSetsSection
              // @ts-ignore
              defaultScheduleParameters={this.props.defaultScheduleParameters}
              formik={formik}
              isEdit={isEdit}
              sets={formik.values.parameterSets}
            />
          </FullWidthToggleSection>
        </SectionsWrapper>
        <UploadModal
          entity="schedules"
          currentId={this.props.shiftSchedule.id}
          intl={this.props.intl}
          user={this.props.user}
          isAuditor={this.props.hasPerm(PERMISSIONS.HISTORY_PLAN)}
        />
      </Form>
    );
  };

  /**
   * Row above staff table with "Do sync button" and sync state tooltip
   */
  private renderSyncInfoRow = () => {
    const { formatMessage } = this.props.intl;

    let staffSync = null;

    const facilityById = convertArrayToMapWithReduce(
      this.props.shiftSchedule.facilities,
      item => item.id.toString(),
      item => item.name,
    );

    const errorMessageParser = str => {
      if (str.indexOf('::') > -1) {
        const tokens = str.split('::');

        const facilityPart = `${formatMessage(messages.facility)} ${facilityById.get(tokens[0])}: `;
        const detailPart = `${formatMessage(messages[`syncError_${tokens[1]}`], { param1: tokens[2] })}`;

        return facilityPart + detailPart;
      }
      return str;
    };

    let errorMessagesTooltip = null;

    if (!this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages) {
      const errorMessages = this.props.scheduleStaffSyncState?.messages
        .split(';;')
        .map(errorMessageParser)
        .map((m, i) => <p key={i}>{m}</p>);

      const errorMessagesTooltipTitle = (
        <div>
          <b>
            <p key="first">{formatMessage(messages.lastSyncResult)}</p>
          </b>
          {errorMessages}
        </div>
      );
      errorMessagesTooltip = this.props.scheduleStaffSyncState?.messages && (
        <Tooltip key="t" title={errorMessagesTooltipTitle}>
          <span>
            <FontAwesomeIcon color="red" style={{ marginLeft: '10px' }} icon="exclamation-circle" size="lg" />
          </span>
        </Tooltip>
      );
    }

    return [staffSync, errorMessagesTooltip];
  };

  private renderFacilitiesSection(formik: FormikProps<ApiSchedule>) {
    const handleFacilitiesAdded = value => {
      this.props.loadAvailablePlans(value.map(v => v.id));
    };

    const handleFacilitiesRemoved = value => {
      // TODO force re-render transfer costs, temp workaround to trigger hiding the rows
      formik.setFieldValue('changesCounter', (formik.values.changesCounter || 0) + 1);
      handleFacilitiesAdded(value);
    };

    const facilityRemoveValidation = item => {
      const facilitiesOfPlan = p => (p.plan.planningArea.facilities || []).map(f => f.id);

      const facilitiesPerPlan = formik.values.plans
        .filter(p => p.plan && p.plan.planningArea && p.plan.planningArea.facilities)
        .map(p => ({ name: p.plan.name, facilities: facilitiesOfPlan(p) }));

      const associatedPlans = facilitiesPerPlan.filter(plan => plan.facilities.indexOf(item.id) > -1);

      if (formik.values.facilities.length === 1) {
        const error = <FormattedMessage {...messages.errorLastFacility} />;
        toast.error(error);
        return false;
      }
      if (associatedPlans.length > 0) {
        const names = associatedPlans.map(p => p.name).join(', ');
        const error = <FormattedMessage {...messages.errorAssociatedPlans} values={{ plans: names }} />;
        toast.error(error);
        return false;
      }
      return true;
    };

    return (
      <GrowableSection message={messages.facilities}>
        <EditableList
          isEdit={this.props.isEdit}
          field="facilities"
          editComponent={FacilityItemNoUnits}
          onItemAdded={handleFacilitiesAdded}
          onItemRemoved={handleFacilitiesRemoved}
          validationOnItemRemoved={facilityRemoveValidation}
          additionalQueryParams="&shiftFiller=true"
        />
      </GrowableSection>
    );
  }

  private renderBasicInfoSection(formik: FormikProps<ApiSchedule>) {
    const nameField = this.props.isEdit ? (
      <Field name="name" type="text" component={FormikTextInput} />
    ) : (
      <FormikText>{formik.values.name}</FormikText>
    );

    const descriptionField = this.props.isEdit ? (
      <Field name="description" type="text" component={FormikTextInput} />
    ) : (
      <FormikText>{formik.values.description}</FormikText>
    );

    const lastUpdate = (
      <FormikText>{formatDateTime(formik.values.lastUpdate)}</FormikText>
    );

    return (
      <Section message={messages.basicInfo}>
        <GridRow>
          <InlineLabel {...messages.name} />
          {nameField}
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.description} />
          {descriptionField}
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.lastUpdate} />
          {lastUpdate}
        </GridRow>
      </Section>
    );
  }

  /**
   * Do needed conversions of formik values before sending to save API
   */
  private convertFormDataForSave(values) {
    const toSave: any = {
      id: values.id,
      name: values.name,
    };

    if (this.props.shiftSchedule.description !== values.description) {
      toSave.description = values.description;
    }
    if (this.props.shiftSchedule.transferCostUnproductive !== values.transferCostUnproductive) {
      toSave.transferCostUnproductive = values.transferCostUnproductive;
    }

    // When looking for modified plans, check dates only (the rest can't be changed by user)
    const plansComparator = (left, right) => left.validFrom === right.validFrom && left.validTo === right.validTo;

    const planDiffs = findDiffs(
      this.props.shiftSchedule.plans,
      values.plans.filter(p => p.planId),
      'planId',
      plansComparator,
    );

    toSave.deletedPlans = planDiffs.deletedIds;

    const ignoreActivitiesDiff = findDiffs(this.props.shiftSchedule.activities, values.activities);
    if(ignoreActivitiesDiff && ignoreActivitiesDiff.modified && ignoreActivitiesDiff.modified.length){
      toSave.scheduleActivities = ignoreActivitiesDiff.modified.map((ia)=>({
        id: ia.id,
        ignore: ia.ignore,
      }));
    };
    toSave.plans = planDiffs.added.concat(planDiffs.modified).map(p => ({
      planId: p.planId,
      validFrom: formatDateToApiFormat(p.validFrom),
      validTo: formatDateToApiFormat(p.validTo),
    }));

    toSave.transferCosts = findDiffs(
      mapTransferCostsFromAPI(this.props.shiftSchedule.departmentTransferCosts).listValues(),
      values.departmentTransferCostsMap.listValues(),
    ).modified;

    toSave.transferCosts = toSave.transferCosts.concat(
      findDiffs(
        mapTransferCostsFromAPI(this.props.shiftSchedule.facilityTransferCosts).listValues(),
        values.facilityTransferCostsMap.listValues(),
      ).modified,
    );

    toSave.transferCosts = mapTransferCostsToAPI(
      toSave.transferCosts.concat(values.activityTransferCostsMap.listValues()),
    );

    const facilityDiffs = findDiffs(this.props.shiftSchedule.facilities, values.facilities);
    toSave.deletedFacilities = facilityDiffs.deletedIds;
    toSave.facilities = facilityDiffs.added;
    const smartShiftJobSchedulesDiffs = findDiffs(
      this.props.shiftSchedule.smartShiftJobSchedules,
      values.smartShiftJobSchedules,
      'smartShiftJobId',
    );
    if (smartShiftJobSchedulesDiffs?.modified?.length) {
      toSave.smartShiftJobSchedules = smartShiftJobSchedulesDiffs.modified.map(a => ({
        id: a.id,
        smartShiftJobId: a.smartShiftJobId,
        scheduleActivityIds: a.scheduleActivityIds,
        coveragePriority: a.coveragePriority,
        allowLeftoverSpread: a.allowLeftoverSpread,
        minimumJobSegmentLength: a.minimumJobSegmentLength,
      }));
    }

    // =================== Parameter Sets ====================================
    const parameterSetDiffs = findDiffs(this.props.shiftSchedule.parameterSets, values.parameterSets);

    // ---------- Deleted Parameter Sets ----------------
    toSave.deletedParameterSets = parameterSetDiffs.deletedIds;

    // ---------- New Parameter Sets ----------------
    const newParameterSetsResult = parameterSetDiffs.added.map(newParameterSet => ({
      default: newParameterSet.default,
      name: newParameterSet.name,
      parametersValues: newParameterSet.groups
        .reduce((acc, item) => acc.concat(item.parametersValues), [])
        .map(item => ({
          parameterId: item.parameterId,
          value: item.value,
        })),
    }));

    // ---------- Updated Parameter Sets ----------------
    const modifiedParameterSets = parameterSetDiffs.modified;
    const allNewFullParameterSets = values.parameterSets;

    const fullModifiedParameterSets = allNewFullParameterSets.filter(fullParameterSet =>
      modifiedParameterSets.map(modifiedParameterSet => modifiedParameterSet.id).includes(fullParameterSet.id),
    );

    // old parameters values to a flat array
    const oldParameterSets = this.props.shiftSchedule.parameterSets;
    const oldParametersValuesFlat = [];
    oldParameterSets.forEach(oldParameterSet => {
      oldParameterSet.groups.forEach(group => {
        group.parametersValues.forEach(oldParameterValue => {
          oldParametersValuesFlat.push({ id: oldParameterValue.id, value: oldParameterValue.value });
        });
      });
    });

    // updated parameters values to a flat array
    const modifiedParameterSetsResult = [];
    fullModifiedParameterSets.forEach(updatedParameterSet => {
      const updateParametersValuesFlat = [];
      updatedParameterSet.groups.forEach(group => {
        group.parametersValues.forEach(parameterValue => {
          updateParametersValuesFlat.push({ id: parameterValue.id, value: parameterValue.value });
        });
      });
      const parametersValuesDiffs = findDiffs(oldParametersValuesFlat, updateParametersValuesFlat);
      const oldParameterSet = oldParameterSets.find(set => updatedParameterSet.id === set.id);
      modifiedParameterSetsResult.push({
        default: updatedParameterSet.default !== oldParameterSet.default ? updatedParameterSet.default : null,
        id: updatedParameterSet.id,
        name: updatedParameterSet.name !== oldParameterSet.name ? updatedParameterSet.name : null,
        parametersValues: parametersValuesDiffs.modified,
      });
    });
    toSave.parameterSets = newParameterSetsResult.concat(modifiedParameterSetsResult);
    return toSave;
  }
}

const mapStateToProps = createStructuredSelector({
  defaultScheduleParameters: selectDefaultScheduleParameters,
  isEdit: selectShiftScheduleEdit,
  scheduleStaff: selectShiftScheduleStaff,
  scheduleStaffSyncState: selectScheduleStaffSyncState,
  shiftSchedule: selectShiftSchedule,
  token: getToken
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      invokeStaffSync,
      loadAvailablePlans,
      loadAvailableUnproductiveActivities,
      loadDefaultScheduleParameters,
      loadScheduleStaff,
      loadShiftSchedule,
      loadStaffSyncState,
      save: saveShiftSchedule,
      storeShiftScheduleEdit,
      storeScheduleStaffSyncState,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// @ts-ignore
const withSaga = injectSaga({ key: 'shiftScheduleDetailPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  // @ts-ignore
)(ShiftScheduleDetailPage);
