import React from 'react';
import { Field } from 'formik';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { FormikText, FormikTextInput } from 'components/FormikTextInput';
import { selectGuiState, selectPlan } from 'containers/MasterPlanDetailPage/selectors';

import Lb from '../Label';
import messages from './messages';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  align-items: center;
  grid-template-columns: 35% 64%;
  input {
    background-color: ${props => props.theme.color.grey5};
    width: 100%;
  }

  ${FormikText} {
    width: 100%;
  }
`;

const Label = styled(Lb)`
  height: ${props => props.theme.grid.rowHeight};
  align-items: center;
  display: flex;
`;

const MasterPlanDetailBasicInfo = props => {
  const renderValue = field => {
    const editable = props.editMode;
    if (editable && field !== 'masterPlan.id') {
      return <Field name={field} component={FormikTextInput} />;
    }
    return <div>{get(props, field)}</div>;
  };

  return (
    <Wrapper>
      <Label {...messages.name} />
      {renderValue('masterPlan.name')}
      <Label {...messages.description} />
      {renderValue('masterPlan.description')}
      <Label {...messages.id} />
      {renderValue('masterPlan.id')}
    </Wrapper>
  );
};

const mapPlanStateToProps = state => ({
  ...createStructuredSelector({
    masterPlan: selectPlan,
    guiState: selectGuiState,
  })(state),
});

const withPlanConnect = connect(mapPlanStateToProps);
export default withRouter(withPlanConnect(MasterPlanDetailBasicInfo));
