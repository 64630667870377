import { EffortForecastTableData } from 'components/EffortForecast/types';
import {
  ApiActivityParametersMultipleDaysCalculationResultDTO,
  ApiAvailabilityMultipleDaysCalculationResultDTO,
  ApiEffortSummaryByWeekDTO,
  ApiMheCalculationDTO,
} from 'types/drep-backend.d';

export enum SECTION_NAME {
  AVAIL = 'availability',
  BUDGET = 'budget',
  PLANNED = 'plan',
  ACTUALS = 'actuals',
}

export enum UNIT {
  pr = 'pr',
  v = 'v',
  e = 'e',
  fte = 'fte',
  fteAdju = 'fteAdju',
  heads = 'heads',
  headsOpt = 'headsOpt',
  nos = 'nos',
  nosAdju = 'nosAdju',
  variance = 'variance',
  sah = 'sah',
  saf = 'saf',
  sas = 'sas',
  diff = 'diff',
  effAdju = 'effAdju',
  diffAdju = 'diffAdju',
  fteDiff = 'fteDiff',
  staffDiff = 'staffDiff'
}

export enum UNIT_FULL {
  prodRate = 'prodRate',
  volume = 'volume',
  effort = 'effort',
  fte = 'fte',
  heads = 'heads',
  headsOpt = 'headsOpt',
}

export enum DATASET {
  budget = 'budget',
  planned = 'planned',
  actuals = 'actuals',
  original = 'original',
  forecast = 'forecast',
  adjusted = 'adjusted',
  smartVolume = 'smartVolume'
}

export enum ARRAY_CURSOR {
  periods = 'periods',
  days = 'days',
  wzps = 'wzps',
  hours = 'hours',
  minutes = 'minutes',
}

export enum SINGLE_CURSOR {
  period = 'period',
  day = 'day',
  wzp = 'wzp',
  hour = 'hour',
  minute = 'minute',
}

export enum GRANULARITY {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  WZP = 'WZP',
  HOUR = 'HOUR',
}

export enum LEVEL {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  WZP = 'WZP',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}

export enum VALUE_TYPE {
  VOLUME = 'VOLUME',
  EFFORT = 'EFFORT',
  PRODUCTIVITY_RATE = 'PRODUCTIVITY_RATE',
}

export enum DT_TYPE {
  WEEKLY = 'ACTIVITY',
  DAILY = 'VOLUME_CATEGORY',
}

export enum T_TYPE {
  SHIFT = 'SHIFT',
  WZP = 'WZP',
}

export const LEVELS_ORDER: LEVEL[] = [LEVEL.MONTH, LEVEL.WEEK, LEVEL.DAY, LEVEL.WZP, LEVEL.HOUR, LEVEL.MINUTE];

export enum OVERRIDE_STATE {
  ovrdcal = 'ovrdcal',
  ovrd = 'ovrd',
  calc = 'calc',
  calcShift = 'calcShift',
}


export enum OVERRIDE_STATE_SOURCE {
  user = 'USER',
  mhe = 'MHE',
}

export enum OVERRIDE_KEYS {
  productivityRateSrc = 'productivityRateSrc',
  volumeSrc = 'volumeSrc',
  effortSrc = 'effortSrc',
}

export enum CELL_TYPE {
  OVERRIDE_STATE = 'OverrideState',
  OVERRIDE_STATE_SOURCE = 'OverrideStateSource'
}

export enum DAY_OF_WEEK {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface ParsedColumnName {
  week?: string;
  weekStart?: string;
  dayOfWeek?: string;
  day?: string;
  workZonePeriodId?: number;
  hourOfDay?: number;
  dataSet?: DATASET;
  dataUnit?: UNIT;
}

export interface Value {
  calculatedVolume: number;
  overriddenVolume: number;
  actualsVolume: number;
  calculatedHoursEffort: number;
  overriddenHoursEffort: number;
  actualsHoursEffort: number;
  calculatedFte: number;
  overriddenFte: number;
  actualsFte: number;
  calculatedHeads: number;
  calculatedHeadsOpt: number;
  overriddenHeads: number;
  overriddenHeadsOpt: number;
  actualsHeads: number;
  actualsHeadsOpt: number;
  calculatedProductivityRate: number;
  overriddenProductivityRate: number;
  actualsProductivityRate: number;
  calculatedNumberOfStaff: number;
  overriddenNumberOfStaff: number;
  actualsNumberOfStaff: number;
  overriddenVolumeSrc: OVERRIDE_STATE;
  overriddenVolumeSrcSource: OVERRIDE_STATE_SOURCE;
  actualsVolumeSrc: OVERRIDE_STATE;
  actualsVolumeSrcSource: OVERRIDE_STATE_SOURCE,
  overriddenProductivityRateSrc: OVERRIDE_STATE;
  actualsProductivityRateSrc: OVERRIDE_STATE;
  overriddenEffortSrc: OVERRIDE_STATE;
  actualsEffortSrc: OVERRIDE_STATE;
  mhes: ApiMheCalculationDTO[];
  adjustedNumberOfStaff: number;
  adjustedFte: number;
}

export interface Cursor {
  single: SINGLE_CURSOR;
  array: ARRAY_CURSOR;
}

export interface Hour {
  hourOfDay: number;
  calculatedHoursEffort: number;
  overriddenHoursEffort: number;
  calculatedFte: number;
  overriddenFte: number;
}

export interface Wzp {
  workZonePeriodId: number;
  workZonePeriodName: string;
  calculatedHoursEffort: number;
  calculatedFte: number;
  hours: Hour[];
  name: string;
  startTime: string;
  endTime: string;
  id: number;
  periodParametersId: number;
}

export interface Day {
  dayOfWeek: DAY_OF_WEEK;
  day: string;
  calculatedHoursEffort: number;
  calculatedFte: number;
  wzps: Wzp[];
}

export interface LabourCategory {
  id: number;
  name: string;
  description: string;
}

export interface LabourCategoryData {
  labourCategory: LabourCategory;
  calculatedHoursEffort: number;
  calculatedFte: number;
  calculatedHeads: number;
  calculatedHeadsOpt: number;
  periods: ApiActivityParametersMultipleDaysCalculationResultDTO[];
}

export interface Period {
  apCalculated: ActivityParametersCalculated;
  startDay: string;
  endDay: string;
  calculatedHoursEffort: number;
  calculatedFte: number;
  days: Day[];
  shifts: Shift[];
  workZonePeriods: Wzp[];
}

export interface ActivityParametersCalculated {
  unproductiveLocations: any[];
  unproductiveStaff: any[];
}

export interface Availability {
  labourCategories: LabourCategoryData[];
  sums: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  totals: LabourCategoryData;
}

export interface Discrepancy {
  sums: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  totals: LabourCategoryData;
}

export interface Plan {
  id?: number;
  name?: string;
  planningArea?: any;
  mhes: MHETransient[];
  activities: ActivityTransient[];
  sums: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  directSums: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  indirectSums: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  totals: Totals;
}

export interface LabourCategoryTransitions {
  breakTime: number;
  headCount: number;
  labourCategory: LabourCategory;
}

export interface Shift {
  id: number;
  endTime: string;
  startTime: string;
  name: string;
  days: string[];
  labourCategoryTransitions: LabourCategoryTransitions;
}

export interface PlanningParameters {
  firstDayOfWeek: string;
  endDay: string;
  startDay: string;
  periods: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  offDays: string[];
  transformationType: string;
  defaultForecastSource: string
}

export interface PlanDetailData {
  planningArea: any;
  planningParameters: PlanningParameters;
  effortForecast: EffortForecastTableData[];
}

export interface Activity {
  id: number;
  name: string;
  variableName: string;
  activityTypeId: number;
  activityTypeName: string;
  groupKey?: string;
  globalName: string;
  kronosCode: string;
  indirect: boolean;
  regionalConfigurationName: string;
}

export interface Department {
  id: number;
  name: string;
  order: number;
}

export interface UOM {
  id: number;
  name: string;
  code: string;
}

export interface WZP {
  endTime: string;
  id: number;
  name: 'Dummy shift';
  periodParametersId: number;
  startTime: string;
}

export interface ActivityTransient {
  activity: Activity;
  department: Department;
  uom: UOM;
  calculatedHeads: number;
  overriddenHeads: number;
  actualsHeads: number;
  calculatedHeadsOpt: number;
  overriddenHeadsOpt: number;
  actualsHeadsOpt: number;
  calculatedVolume: number;
  overriddenVolume: number;
  actualsVolume: number;
  calculatedHoursEffort: number;
  overriddenHoursEffort: number;
  actualsHoursEffort: number;
  calculatedFte: number;
  overriddenFte: number;
  actualsFte: number;
  calculatedProductivityRate: number;
  overriddenProductivityRate: number;
  actualsProductivityRate: number;
  overriddenVolumeSrc: OVERRIDE_STATE;
  overriddenVolumeSrcSource: OVERRIDE_STATE_SOURCE;
  actualsVolumeSrc: OVERRIDE_STATE;
  actualsVolumeSrcSource: OVERRIDE_STATE_SOURCE,
  overriddenProductivityRateSrc: OVERRIDE_STATE;
  actualsProductivityRateSrc: OVERRIDE_STATE;
  overriddenEffortSrc: OVERRIDE_STATE;
  actualsEffortSrc: OVERRIDE_STATE;
  indirect: boolean;
  mhes: MHETransient[];
  periods: ApiActivityParametersMultipleDaysCalculationResultDTO[];
  wzp: WZP;
  wzpNumberOfStaffTotal?: WzpAverage[];
}

export interface WzpAverage {
  wzpName: string;
  actualsNumberOfStaff: number;
  calculatedNumberOfStaff: number;
  overriddenNumberOfStaff: number;
}

export interface Totals {
  calculatedHoursEffort: number;
  calculatedFte: number;
  overriddenHoursEffort: number;
  overriddenFte: number;
  overriddenHeads: number;
  overriddenHeadsOpt: number;
  overriddenEffortSrc: OVERRIDE_STATE;
  actualsHoursEffort: number;
  actualsFte: number;
  actualsHeads: number;
  actualsHeadsOpt: number;
  actualsEffortSrc: OVERRIDE_STATE;
  mhes: MHETransient[];
}

export interface MHETransient {
  id: number;
  calculatedHoursEffort: number;
  calculatedFte: number;
  calculatedHeads: number;
  calculatedHeadsOpt: number;
  overriddenHoursEffort: number;
  overriddenEffortSrc: string;
  actualsHoursEffort: number;
  actualsEffortSrc: string;
  mhe: MHE;
  department?: string;
  departmentId?: string;
  periods: ApiActivityParametersMultipleDaysCalculationResultDTO[];
}

export interface MHE {
  id: number;
  name: string;
  mheTypeId: number;
  mheTypeName: string;
  globalName: string;
  description: string;
  groupKey?: string;
  shift?: string;
  shiftId: string;
  uom: object | string;
  regionalConfigurationName: string;
}

export interface Intl {
  formatMessage: (key: any) => string;
  formatDate: (date: string) => string;
  formatNumber: (num: number, options?: object) => string;
}

export interface Props {
  intl: Intl;
  messages: any;
  planningParameters: PlanningParameters;
}

export interface Filter {
  isShift: boolean;
  byHour: boolean;
  isDaily: boolean;
  isDailyPR: boolean;
  activitiesByPlan: any;
  editing: boolean;
  granularity: GRANULARITY;
  includeBaseLine: boolean;
  includeWeekStartAsSunday: boolean;
  includeVolumeCategory: boolean;
  includeForecast: boolean;
  includeSmartVolume: boolean;
  showBudget: boolean;
  showDirect: boolean;
  showIndirect: boolean;
  showEffort: boolean;
  showFte: boolean;
  showHeads: boolean;
  showHeadsOpt: boolean;
  showVolume: boolean;
  showProductivityRate: boolean;
  showPlanned: boolean;
  showPlannedOriginal: boolean;
  showActuals: boolean;
  showForecast: boolean;
  showSmartVolumeForecast: boolean;
  showLabourAvailability: boolean;
  showNumberOfStaff: boolean;
  showVariance: boolean;
  transformationType: T_TYPE;
  showAvailableHours: boolean;
  showAvailableFTE: boolean;
  showAvailableStaff: boolean;
  showlabourDiscrepancy: boolean;
  isLabourAvailabilityTypePR: boolean;
  showEffortAdjustment: boolean;
  displayAdjustments: boolean;
  calculateValueType?: string;
  showShiftLabourAvailability?: boolean;
  planBasicDetails?: any;
}

export interface GranulartiyTree {
  isLabor?: boolean;
  isMheAva?: boolean;
  indirect?: boolean;
  periods?: (
    | ApiActivityParametersMultipleDaysCalculationResultDTO
    | ApiAvailabilityMultipleDaysCalculationResultDTO
    | ApiEffortSummaryByWeekDTO
  )[];
  level?: LEVEL;
  period?: ApiActivityParametersMultipleDaysCalculationResultDTO | ApiAvailabilityMultipleDaysCalculationResultDTO;
  day?: Day;
  days?: Day[];
  wzp?: Wzp;
  wzps?: Wzp[];
  hour?: Hour;
  hours?: Hour[];
  isSum?: boolean;
  isSumRow?: boolean;
  cellType?: CELL_TYPE;
  cellTypeSource?: CELL_TYPE;
  formatters?: object;
}

export enum MENU_TABS {
  filterMenuTab = 'filterMenuTab',
}

export interface ColDef {
  headerClass?: string;
  colId?: string;
  colType?: VALUE_TYPE;
  field?: string;
  keyCreator: (params: Params) => string;
  cellClass?: ((params: Params) => string) | string;
  tooltipValueGetter?: (params: Params) => string | string;
  editable?: boolean | ((params: Params) => boolean);
  menuTabs?: MENU_TABS[];
  pinned?: boolean;
  suppressMenu?: boolean;
  sortable?: boolean;
  width?: number;
  headerName?: string;
  headerTooltip?: string;
  type?: string;
  children?: ColDef[];
  enableValue?: boolean;
  aggFunc?: string | ((params: unknown) => number);
  valueGetter?: (params: Params) => string;
  valueFormatter?: (params: Params) => any;
  valueSetter?: (params: Params) => string;
  valueParser?: (params: Params) => string;
  onCellValueChanged?: (params: Params) => void;
  newValueHandler?: (params: Params) => boolean;
  filterParams?: any;
  filterValueGetter?: object;
  rowGroup?: boolean;
  hide?: boolean;
  pinnedRowCellRenderer?: string;
  cellRenderer?: string;
  cellRendererParams?: {
    suppressCount: boolean;
  };
  showRowGroup?: string;
}

export interface Params {
  data: any;
  node: {
    group: boolean;
    field: string;
  };
  newValue?: any;
  oldValue?: any;
  value?: any;
  colDef: { colId: string };
  api?: any;
}

export const CURSORS = {
  [LEVEL.WEEK]: { single: SINGLE_CURSOR.period, array: ARRAY_CURSOR.periods },
  [LEVEL.MONTH]: { single: SINGLE_CURSOR.period, array: ARRAY_CURSOR.periods },
  [LEVEL.DAY]: { single: SINGLE_CURSOR.day, array: ARRAY_CURSOR.days },
  [LEVEL.WZP]: { single: SINGLE_CURSOR.wzp, array: ARRAY_CURSOR.wzps },
  [LEVEL.HOUR]: { single: SINGLE_CURSOR.hour, array: ARRAY_CURSOR.hours },
  [LEVEL.MINUTE]: { single: SINGLE_CURSOR.minute, array: ARRAY_CURSOR.minutes },
};

export interface Data {
  budgetAvailability: Availability;
  plan: Plan;
  budget: Plan;
  planAvailability: Availability;
  planMheAvailability: Plan;
  budgetMheAvailability: Plan;
  planDiscrepancy: Discrepancy;
  budgetDiscrepancy: Discrepancy;
}

export interface TableResult {
  colDef?: ColDef[];
  data?: any[];
  totals?: any[];
  formatters?: object;
}

export interface TableColumnResult {
  colDef: ColDef;
}

export interface TableDataResult {
  data: any;
}

export interface TablesColumnResult {
  activity: TableColumnResult;
  mhe: TableColumnResult;
  role: TableColumnResult;
}

export interface TablesDataResult {
  mhe: TableResult;
  activity?: TableResult;
}

export interface TablesResult {
  activity?: TableResult;
  mhe?: TableResult;
  role?: TableResult;
}

export interface NamedItem {
  globalName: string;
  name: string;
}

export interface CleanData {
  axisXTemp: any;
  dataTemp: any;
  data: any[];
  axisX: any[];
  colorCount: number;
  activitiesNum?: number;
}

export interface Facility {
  id: number;
  name: string;
  code: string;
}
