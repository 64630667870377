/**
 *
 * PlannedVolumeAddLine
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AddLineButton } from 'components/IconButton';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import { addWZPSettingsLine } from 'containers/PlanDetailPage/actions';
import { addWZPSettingsLinePa } from 'containers/PlanningAreaDetailPage/actions';
import { selectGuiState, selectPeriodIndexFromPlan } from 'containers/PlanDetailPage/selectors';
import { selectGuiStateForPa, selectPeriodIndexFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import messages from './messages';

const Wrapper = styled.div``;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
  ${AddLineButton} {
    margin-left: 20px;
  }
  > * {
    margin-bottom: 5px;
  }
  > span {
    flex-basis: 150px;
  }
`;

const SelectWrapper = styled.div`
  width: 15em;
  margin-right: 20px;
`;

/* eslint-disable react/prefer-stateless-function */
class WZPAddline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newShiftName: null,
    };
  }

  handleChange = event => {
    this.setState({ newShiftName: event.target.value });
  };

  render() {
    const editable = this.props.guiState.get('edit') || this.props.isEdit;
    return (
      <Wrapper>
        {editable && (
          <Grid>
            <SelectWrapper>
              <input
                type="text"
                name="newShiftName"
                placeholder={this.props.intl.formatMessage(messages.NewWZPPlaceholder)}
                onChange={this.handleChange}
                disabled={!editable}
                value={this.state.newShiftName || ''}
              />
            </SelectWrapper>
            <ButtonWithDirtyCheck
              disabled={!this.state.newShiftName}
              actionPayload={this.state}
              actionHandler={payload => {
                this.props.addWZPSettingsLine(payload);
                this.setState({
                  newShiftName: '',
                });
              }}
              component={AddLineButton}
            />
          </Grid>
        )}
      </Wrapper>
    );
  }
}

WZPAddline.propTypes = {
  guiState: PropTypes.object,
  // token: PropTypes.string,
  // periodIndex: PropTypes.number,
  addWZPSettingsLine: PropTypes.func,
  intl: PropTypes.object,
};

const withPlanConnect = connect(
  createStructuredSelector({
    guiState: selectGuiState,
    periodIndex: selectPeriodIndexFromPlan,
  }),
  dispatch => bindActionCreators({ addWZPSettingsLine }, dispatch),
);

const withPaConnect = connect(
  createStructuredSelector({
    guiState: selectGuiStateForPa,
    periodIndex: selectPeriodIndexFromPa,
  }),
  dispatch => bindActionCreators({ addWZPSettingsLine: addWZPSettingsLinePa }, dispatch),
);

export default withPlanConnect(injectIntl(WZPAddline));
export const PaWZPAddline = withPaConnect(injectIntl(WZPAddline));
