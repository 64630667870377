import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import styled from 'styled-components';

import BasePage from 'components/BasePage';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import ResultBasicInfo from 'components/ResultBasicInfo';
import ToggleSection from 'components/ToggleSection';
import { getToken } from 'containers/App/selectors';
import { mappingOMSCodesState } from 'containers/PlanResultPage/selectors';
import ForecastPerOMSCode from 'containers/ForecastDetailPage/ForecastPerOMSCode';
import OMSCodesAndSmartProdJobs from 'containers/ForecastDetailPage/OMSCodesAndSmartProdJobs';
import { validateSmartProdRunEntry } from 'containers/ForecastDetailPage/SmartProdRunsTableDetailCellRenderer';
import {
  EntityEntry,
  ForecastConversionRateTableDTOWithId,
  ForecastForm,
  ForecastListEntityEntry,
  SmartProdSourceEntityEntry,
} from 'containers/ForecastDetailPage/types';
import { loadPlanAction, mappingOMSCodes } from 'containers/PlanResultPage/actions';
import { convertEntityWithPlanningParametersFromApi, withUrl } from 'utils/api';
import { formatDate, generateDays, parseDate } from 'utils/dateTime';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { parserPlanResult, searchToData } from 'utils/url';
import {
  ApiActivityDTO,
  ApiForecastPerOmsCodeTableDTO,
  ApiSmartProdRunDTO,
  ApiSmartProdRunScheduleDTO,
} from 'types/drep-backend.d';

import {
  fetchActivities,
  fetchForecastConversionRateTable,
  fetchOMSCodes,
  fetchSmartProdInstances,
  fetchSmartProdJobAdjustments,
  fetchSmartProdActivityAdjustments,
  fetchWarehouses,
  getForecastPerOMSCode,
  getSmartProdRequestedRuns,
  getSmartProdScheduledRuns,
  refreshJobCodes,
  saveForecastConversionRateTable,
  saveSmartProdRuns, saveSmartProdScheduledRuns, fetchMasterPlanDetails, fetchSmartProdRunsDetails, saveTimeTransformationRuns,
  fetchOMSChangeHistory,
} from './fetch';
import messages from './messages';
import SmartProdRuns from './SmartProdRuns';
import Toolbar from './ToolBar';
import PlanDetailWarning from 'components/PlanDetailWarning';
import { Loading } from './Loading';
import Adjustments from './Adjustments';

export const SectionWrap = styled.div`
  margin: 5px 10px;
  padding: 5px;
  box-shadow: ${props => props.theme.shadow.pageBox};
  background-color: white;

  :nth-child(2) {
    margin-top: 80px;
  }
`;

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const PageSection: React.FC<{
  labelMessage: FormattedMessage.Props;
}> = ({ labelMessage, children }) => (
  <SectionWrap>
    {labelMessage && (
      <SectionTitle>
        <FormattedMessage {...labelMessage} />
      </SectionTitle>
    )}
    {children}
  </SectionWrap>
);

export const Section = styled(ToggleSection)`
  margin: 5px 10px;
`;

export const Wrap = styled(BasePage)`
  ${Section}:nth-child(2) {
    margin-top: 80px;
  }
`;

type PropsType = {
  intl: InjectedIntl;
  handleSubmit: () => void;
  fixed: boolean;
  isEdit: boolean;
  token: string;
  dispatch: () => void;
  result: unknown;
} & RouteComponentProps;

const ForecastDetailPage: React.FC<PropsType> = ({ intl, history }) => {
  const dispatch = useDispatch();

  const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
  const planId = searchObject?.planId;
  const areaId = searchObject?.areaId;
  const planEdit = searchObject?.isEdit === 'true';

  const token: string = useSelector(getToken);
  const omsstateValue = useSelector(mappingOMSCodesState);
  const [planDetailSmartRuns, setSmartProdRunsDetails] = useState([]);
  const [smartProdRunsDetailLoader, setSmartProdRunsDetailLoader] = useState(false);
  const [planDetailMasterPlans, setMasterPlanDetails] = useState([]);
  const [dates, setDates] = useState<DateTime[]>([]);
  const [forecastEdit, setForecastEdit] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [response, setResponse] = useState([]);
  const [allSmartProdInstances, setAllSmartProdInstances] = useState<EntityEntry[]>(null);
  const [allSmartProdSources, setAllSmartProdSources] = useState<SmartProdSourceEntityEntry[]>(null);
  const [allOmsCodes, setAllOmsCodes] = useState<EntityEntry[]>(null);
  const [omsLoader, setOMSLoader] = useState(false);
  const [allWarehouses, setAllWarehouses] = useState<EntityEntry[]>(null);
  const [activityForecastList, setActivityForecastList] = useState<ForecastListEntityEntry[]>(null);
  const [forecastPerOMSCode, setForecastPerOMSCode] = useState<ApiForecastPerOmsCodeTableDTO>(null);
  const [allActivities, setActivities] = useState<ApiActivityDTO[]>(null);
  const [omsChangeHistory, setOMSChangeHistory] = useState([]);
  const [ratioWarnings, setRatioWarnings] = useState([]);
  const [ratioWarningsGroup, setRatioWarningsGroup] = useState([]);
  const [forecastLoader, setForecastLoader] = useState(false);
  const [smartProdAPI, setsmartProdAPI] = useState(false);
  const [initialValues, setInitialValues] = useState<ForecastForm>({
    forecastConversionRates: [],
    smartProdRuns: {},
    smartProdScheduledRuns: {},
    timeTransformationRuns: {},
    planningParameters: planDetails?.planningParameters,
  });
  const [smartProdRuns, setSmartProdRuns] = useState<ApiSmartProdRunDTO[]>([]);
  const [smartProdJobAdjustments, setSmartProdJobAdjustments] = useState([]);
  const [smartProdActivityAdjustments, setSmartProdActivityAdjustments] = useState([]);
  const [smartProdRunLoader, setSmartProdRunLoader] = useState(false);
  const [smartProdRunScheduleLoader, setSmartProdRunScheduleLoader] = useState(false);
  const [smartProdScheduledRuns, setSmartProdScheduledRuns] = useState<ApiSmartProdRunScheduleDTO[]>([]);
  const [timeTransformationRuns, setTimeTransformationRuns] = useState<ApiSmartProdRunDTO[]>([]);
  const [initialForecastConversionRates, setInitialForecastConversionRates] = useState<
    ForecastConversionRateTableDTOWithId[]
  >([]);
  const [initialForecastConversionRatesLoader, setInitialForecastConversionRatesLoader] = useState(false);
  const [forecastConversionRates, setForecastConversionRates] = useState<ForecastConversionRateTableDTOWithId[]>([]);
  const subtitleToDisplay = messages.activityAssignmentError;
  const name = planDetails ? planDetails.name : '';
  const planningParametersId = planDetails?.planningParameters.id;
  const hasAdjustments = planDetails?.planningParameters?.hasActivityAdjustments;
  const handleReloadData = () => {
    setRatioWarnings([]);
    setRatioWarningsGroup([]);
    const fetchDetails = async () => {
      if (planId || areaId) {
        const entity = areaId ? 'planningAreas' : 'plans';
        const response = await fetchData(withUrl(`/${entity}/${planId || areaId}`).andToken(token), dispatch);
        response.isOk ? setResponse(response.data) : null;
        const details = response.isOk ? convertEntityWithPlanningParametersFromApi(response.data) : null;
        setPlanDetails(details);
        
        if (details) {
          await fetchData(
            withUrl(`/planningParameters/${details.planningParameters.id}/handleCdmChangesForForecastPage`)
              .andToken(token)
              .post(),
            dispatch,
          );
          fetchMasterPlanDetails(token, dispatch, details.id, setMasterPlanDetails, entity);
          fetchSmartProdRunsDetails(token, dispatch, details.planningParameters.id, setSmartProdRunsDetails, setSmartProdRunsDetailLoader);
          fetchSmartProdJobAdjustments(token, dispatch, details.planningParameters.id, setSmartProdJobAdjustments)
          fetchSmartProdActivityAdjustments(token, dispatch, details.planningParameters.id, setSmartProdActivityAdjustments)
          fetchOMSCodes(token, dispatch, setAllOmsCodes, setOMSLoader);
          fetchForecastConversionRateTable(
            token,
            dispatch,
            details.planningParameters.id,
            setInitialForecastConversionRates,
            setInitialForecastConversionRatesLoader
          );
          fetchActivities(token, dispatch, details.planningParameters.id, setActivities);
          fetchOMSChangeHistory(token, dispatch, details.planningParameters.id, setOMSChangeHistory);
          getSmartProdRequestedRuns(token, dispatch, details.planningParameters.id, setSmartProdRuns, setSmartProdRunLoader, setTimeTransformationRuns);
          getSmartProdScheduledRuns(token, dispatch, details.planningParameters.id, setSmartProdScheduledRuns, setSmartProdRunScheduleLoader);
          getForecastPerOMSCode(token, dispatch, details.planningParameters.id, setForecastPerOMSCode, true, setForecastLoader);
          const datesList = generateDays(
            parseDate(details.planningParameters.startDay),
            parseDate(details.planningParameters.endDay),
          );
          setDates(datesList);
        }
      }
    };
    fetchDetails();
    fetchSmartProdInstances(token, dispatch, setAllSmartProdInstances);
    fetchWarehouses(token, dispatch, setAllWarehouses);
  };

  const tableData = [];
  forecastPerOMSCode?.oms?.forEach(oms => {
    oms?.activities?.forEach(activity => {
      activity?.jobs?.forEach(job => {
        const jobData = forecastConversionRates?.find(c => c.smartProdJobCodeId === job.smartProdJobCodeId);
        const omsCodesTableSmartProdJobCode = jobData
          ? `${jobData.smartProdJobCodeName} (${jobData.smartProdJobCodeUomName})`
          : '';
        const omsId = forecastConversionRates?.find(c => c.omsId === oms.omsId)?.omsId;
        const smartProdSourceName = forecastConversionRates?.find(c => c.smartProdSourceId === oms.smartProdSourceId)
          ?.smartProdSourceName;
        const activityForecastName = activityForecastList?.find(s => s.value === activity.activityForecastId)?.label;
        if (oms.smartProdSourceId) {
          tableData.push({
            omsId,
            smartProdSourceName,
            activityForecastName,
            omsCodesTableSmartProdJobCode,
            activities: oms.activities,
            omsDates: oms.dates,
            activityDates: activity.dates,
            jobDates: job.dates,
          });
        }
      });
    });
  });

  const omsCodesById = {};
  if (allOmsCodes) {
    allOmsCodes.forEach(omsCode => {
      omsCodesById[omsCode.value] = omsCode;
    });
  }

  tableData.sort((a, b) => {
    const codeA = omsCodesById[a.omsId]?.label || '';
    const codeB = omsCodesById[b.omsId]?.label || '';
    if (codeA === codeB) {
      if (a.activityForecastName === b.activityForecastName) {
        return a.omsCodesTableSmartProdJobCode < b.omsCodesTableSmartProdJobCode ? -1 : 1;
      }
      return a.activityForecastName < b.activityForecastName ? -1 : 1;
    }
    return codeA < codeB ? -1 : 1;
  });

  useEffect(() => {
    handleReloadData();
  }, [planId, areaId, token]);

  useEffect(()=>{
    return () => {
      if (history.location.state && history.location.state.createdFromMasterPlan) {
        let state = { ...history.location.state };
        delete state.createdFromMasterPlan;
        history.replace({ ...history.location, state });
    }
    sessionStorage.removeItem('forecastFilterModel');
    sessionStorage.removeItem('OMSFilterModel');
    sessionStorage.removeItem('smpjaFilterModel');
    sessionStorage.removeItem('smpjaiFilterModel');
    sessionStorage.removeItem('smpaaFilterModel');
    sessionStorage.removeItem('smpaaiFilterModel');
    }
  },[])

  useEffect(() => {
    window.addEventListener("beforeunload", removeSavedFilterModels);
    return () => {
      window.removeEventListener("beforeunload", removeSavedFilterModels);
    };
  }, []);
  const removeSavedFilterModels = () => {
    sessionStorage.removeItem('forecastFilterModel');
    sessionStorage.removeItem('OMSFilterModel');
    sessionStorage.removeItem('smpjaFilterModel');
    sessionStorage.removeItem('smpjaiFilterModel');
    sessionStorage.removeItem('smpaaFilterModel');
    sessionStorage.removeItem('smpaaiFilterModel');
  };

  useEffect(() => {
    const { warningList, ratioWarningsRawData } = getWarningData();
    var groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const formattedData = groupBy(ratioWarningsRawData, 'codeLabel')
    setRatioWarningsGroup(formattedData);
    if (warningList.length > 0 && ratioWarnings.length == 0) {
      setRatioWarnings(warningList);
    }
  }, [forecastConversionRates, planDetailSmartRuns, forecastPerOMSCode]);

  // smart prod runs reload
  useEffect(() => {
    let shouldReload = false;
    smartProdRuns.forEach(run => {
      if (run.status === 'RUNNING' || run.status === 'WAITING') {
        shouldReload = true;
      }
    });
    timeTransformationRuns.forEach((tRun)=> {
      if (tRun.status === 'RUNNING' || tRun.status === 'WAITING') {
        shouldReload = true;
      }
    });
    const interval = setInterval(() => {
      if (shouldReload) {
        handleReloadData();
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [smartProdRuns, timeTransformationRuns]);

  useEffect(() => {
    const values: ForecastForm = {
      forecastConversionRates: [],
      smartProdRuns: {},
      smartProdScheduledRuns: {},
      timeTransformationRuns: {},
      planningParameters: planDetails?.planningParameters,
    };
    smartProdRuns.forEach(data => {
      values.smartProdRuns[data.id] = data;
    });
    smartProdScheduledRuns.forEach(data => {
      values.smartProdScheduledRuns[data.id] = data;
    });
    timeTransformationRuns.forEach(data => {
      values.timeTransformationRuns[data.id] = data;
    });
    values.forecastConversionRates = cloneDeep(initialForecastConversionRates);
    setInitialValues(values);
    setForecastConversionRates(cloneDeep(initialForecastConversionRates));
  }, [ initialForecastConversionRates, smartProdRuns, smartProdScheduledRuns, forecastEdit, planDetails, timeTransformationRuns]);

  useEffect(()=>{
    setInitialForecastConversionRatesLoader(false);
  },[initialForecastConversionRates])

  useEffect(() => {
    if (planDetails && allWarehouses && allSmartProdInstances) {
      const smartProdSourceList = planDetails.planningParameters?.smartProdSourceList;
      const warehousesById = {};
      allWarehouses.forEach(warehouse => {
        warehousesById[warehouse.value] = warehouse.label;
      });
      const smartProdInstancesById = {};
      allSmartProdInstances.forEach(instance => {
        smartProdInstancesById[instance.value] = instance.label;
      });
      if (smartProdSourceList.length > 0) {
        setAllSmartProdSources(
          smartProdSourceList.map(smartProdSource => ({
            value: smartProdSource.id,
            label: `${smartProdSource?.smartProdInstance?.name} / ${smartProdSource?.smartProdWarehouse
              ?.smartProdWhId}`,
            smartProdSource,
          })),
        );
      } else {
        setAllSmartProdSources([]);
      }
    }
  }, [planDetails, allWarehouses, allSmartProdInstances]);

  useEffect(() => {
    if (planDetails && allActivities) {
      const activityForecastTable = planDetails.planningParameters?.activityForecastTable?.rowData || [];
      const activitiesById = {};
      allActivities.forEach((activity: ApiActivityDTO) => {
        activitiesById[activity.id] = activity;
      });
      const getOMSData = (activity) => {
        var actParams = planDetails.planningParameters?.periods?.map((p) => p?.activityParameters)?.flat(Infinity);
        return actParams?.filter((ap) => ap?.activity?.id === activity?.activityId)[0]?.omsId;
      }
      setActivityForecastList(
        activityForecastTable
          .map(activity => ({
            label: `${activity.activity} (${activity.uom || ''})`,
            value: activity.id,
            omsId: getOMSData(activity) || activitiesById[activity.activityId]?.omsId,
            departmentId: activity.departmentId,
            customerId: activity.customerId,
            uom: activity.uom,
            ForecastOutOfPlanDateInterval: activity?.ForecastOutOfPlanDateInterval,
          }))
          .filter(activity => activity.omsId),
      );
    }
  }, [planDetails, allActivities]);

  useEffect(() => {
    dispatch(loadPlanAction({ planId }, intl, true));
  }, []);

  useEffect(()=>{
    return () =>{
      dispatch(mappingOMSCodes(false))
    }
  },[])

  const editModeChange = (editMode: boolean) => {
    setForecastEdit(editMode);
  };

  const handleSubmitPage = async (values: ForecastForm) => {
    if (!isEmpty(handleValidate(values))) {
      return;
    }

    let reload = false;
    if (!isEqual(initialValues.forecastConversionRates, values.forecastConversionRates)) {
      const response = await saveForecastConversionRateTable(
        token,
        dispatch,
        planningParametersId,
        values.forecastConversionRates.filter((value, i) => !isEqual(value, initialValues.forecastConversionRates[i])),
      );
      if (response.isOk) {
        reload = true;
      }
    }
    if (!isEqual(initialValues.smartProdRuns, values.smartProdRuns) && !smartProdAPI) {
      setsmartProdAPI(true);
      const response = await saveSmartProdRuns(
        token,
        dispatch,
        planningParametersId,
        Object.values(values.smartProdRuns).filter(run => !isEqual(initialValues.smartProdRuns[run.id], run)),
      );
      if (response.isOk) {
        reload = true;
      }
    }

    if (!isEqual(initialValues.timeTransformationRuns, values.timeTransformationRuns) && !smartProdAPI) {
      setsmartProdAPI(true);
      const changedData = Object.values(values.timeTransformationRuns).filter(run => !isEqual(initialValues.timeTransformationRuns[run.id], run));
      changedData.forEach((c)=>{
        delete c.forecastDates;
        //@ts-ignore
        c.smartProdRunWzps.forEach((s)=>{
          s.id = s?.id?.length > 15 ? null : s.id
        })
      });
      const response = await saveTimeTransformationRuns(
        token,
        dispatch,
        planningParametersId,
        changedData
      );
      if(!response.isOk){
        setsmartProdAPI(false);
      }
      if (response.isOk) {
        reload = true;
      }
    }
    if (!isEqual(initialValues.smartProdScheduledRuns, values.smartProdScheduledRuns)) {
      const response = await saveSmartProdScheduledRuns(
        token,
        dispatch,
        planningParametersId,
        Object.values(values.smartProdScheduledRuns).filter(run => !isEqual(initialValues.smartProdScheduledRuns[run.id], run)),
      );
      if (response.isOk) {
        reload = true;
      }
    }
    if (reload) {
      setsmartProdAPI(false);
      handleReloadData();
    }
  };

  const handleGetSmartProdJobCodes = () => {
    if (planningParametersId) {
      refreshJobCodes(token, dispatch, planningParametersId).then(response => {
        if (response.isOk) {
          handleReloadData();
          toast.info(intl.formatMessage(messages.jobCodesRefreshedToast));
        }
      });
    }
  };

  const handleValidate = values => {
    let errors = {};
    Object.values(values.smartProdRuns).forEach((smartProdRun: ApiSmartProdRunDTO) => {
      const sprErrors = validateSmartProdRunEntry(smartProdRun, intl, true);
      if (sprErrors) {
        errors = { ...errors, ...sprErrors };
      }
    });
    Object.values(values.smartProdScheduledRuns).forEach((smartProdRun: ApiSmartProdRunDTO) => {
      const sprErrors = validateSmartProdRunEntry(smartProdRun, intl, true);
      if (sprErrors) {
        errors = { ...errors, ...sprErrors };
      }
    });
    Object.values(values.timeTransformationRuns).forEach((smartProdRun: ApiSmartProdRunDTO) => {
      const trErrors = validateSmartProdRunEntry(smartProdRun, intl, true);
      if (trErrors) {
        errors = { ...errors, ...trErrors };
      }
    });
    if (values.forecastConversionRates) {
      values.forecastConversionRates.forEach(activity => {
        activity.dates.forEach(date => {
          if (!/^\s*[0-9]+\s*$/g.test(date.activity)) {
            errors = {
              ...errors,
              forecastConversionRates: intl.formatMessage(messages.invalidConversionRateErrorActivityDate, {
                jobCode: `${activity.smartProdJobCodeName} (${activity.smartProdJobCodeUomName})`,
                date: date.conversionRateDate ? formatDate(date.conversionRateDate) : 'Default Conversion Rate',
                value: date.activity,
              }),
            };
          }
          if (!/^\s*[0-9]+\s*$/g.test(date.job)) {
            errors = {
              ...errors,
              forecastConversionRates: intl.formatMessage(messages.invalidConversionRateErrorJobDate, {
                jobCode: `${activity.smartProdJobCodeName} (${activity.smartProdJobCodeUomName})`,
                date: date.conversionRateDate ? formatDate(date.conversionRateDate) : 'Default Conversion Rate',
                value: date.activity,
              }),
            };
          }
        });
      });
    }
    return errors;
  };
  const mappedCodesData = forecastConversionRates && forecastConversionRates.filter((data)=>data.activityForecastId &&  data.activityForecastId !== null);
  if(mappedCodesData.length !== forecastConversionRates.length){
    dispatch(mappingOMSCodes(true))
  }

  const getWarningData = () => {
    const warningList = [];
    const ratioWarningsRawData = [];
    if (tableData.length > 0 && dates.length > 0) {
      dates.map((date, i) => {
        tableData.forEach((tableActivity, j) => {
          let sum = 0;
          let sumOfActivityRatios = 0;
          const code = omsCodesById[tableActivity.omsId];
          const dateData = tableActivity.activities[0].dates[i] || {};
          tableActivity.activities.map((mappedActivity, j) => {
            sumOfActivityRatios += mappedActivity.dates[i]?.ratioSmartProd || 0;
            sum = sumOfActivityRatios * 100
            sum = Number(sum.toFixed(2))
            if((((tableActivity.omsDates[i]).effortSmartProd == 0) || !(tableActivity.omsDates[i]).effortSmartProd) && (((tableActivity.omsDates[i]).effortSmartVolume == 0) || !(tableActivity.omsDates[i]).effortSmartVolume)){
              // skip warnings as part of SMP-4708
            }
            else if (j == tableActivity.activities.length - 1) {
              if (sum != 100) {
                const date = dateData.effortDate;
                const obj = "The ratio of OMS Code " + code?.label + " on the date " + date + " is " + sum + "%"

                if (!warningList.includes(obj)) {
                  warningList.push(obj);
                  ratioWarningsRawData.push({id: code?.value, codeLabel: code?.label, obj})
                }
              }
            }
          }
          )
        }
        )
      }
      )
    }
    return { warningList, ratioWarningsRawData};
  }

  const activityForecastTableData = planDetails?.planningParameters?.activityForecastTable?.rowData || [];
  const activityForecastListWaringData = activityForecastTableData?.filter((activity)=>activity?.ForecastOutOfPlanDateInterval) || [];
  const activityForecastListWaring = [];
  const activityForecastListWaringZeroRatio = [];
  activityForecastListWaringData?.forEach((activity) => {
    forecastPerOMSCode?.oms?.forEach((tb)=>{
      const activityFound = tb?.activities?.filter((act)=> (act?.activityForecastId == activity?.id) && !act.isOutOfPeriodForecasting);
      const activityFoundZero = tb?.activities?.filter((act)=> (act?.activityForecastId == activity?.id) && act.isOutOfPeriodForecasting);
      if (activityFound?.length > 0) {
        activityForecastListWaring.push({activity: activity.activity, dateInterval: activityFound[0].dateInterval});
      }
      if (activityFoundZero?.length > 0) {
        activityForecastListWaringZeroRatio.push({activity: activity.activity, dateInterval: activityFoundZero[0].dateInterval});
      }
    });
  }
  );
  const omsChangeHistoryWarnings = omsChangeHistory?.filter((o)=>o.jobCodeName);
  const defaultCustomers = forecastConversionRates?.filter((f) => f.customer === 'default_customer')?.map((j)=>j?.smartProdJobCodeName)?.join(', ');
  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      validateOnChange
      validate={handleValidate}
      onSubmit={handleSubmitPage}
    >
      <Wrap noMaxWidth labelMessage={areaId ? messages.headerArea : messages.headerPlan} labelValues={{ name }}>
        <Helmet>
          <title>{intl.formatMessage(areaId ? messages.headerArea : messages.headerPlan, { name })}</title>
          <meta name="description" content={intl.formatMessage(messages.content)} />
        </Helmet>

        <FormikErrorsPopup />

        <Toolbar
          isArea={!!areaId}
          plan={planDetails}
          planEdit={planEdit}
          forecastEdit={forecastEdit}
          editModeChange={editModeChange}
          handleGetSmartProdJobCodes={handleGetSmartProdJobCodes}
          handleReloadData={handleReloadData}
        />

        <PageSection labelMessage={messages.basicInfo}>
          <ResultBasicInfo plan={planDetails} />
        </PageSection>

        {planDetails && (
          <>
            <PlanDetailWarning smartProdRunsDetailLoader={smartProdRunsDetailLoader} isArea={!!areaId} planDetailSmartRuns={planDetailSmartRuns} planDetailMasterPlans={planDetailMasterPlans} ratioWarnings={ratioWarnings} ratioWarningsGroup={ratioWarningsGroup} activityForecastListWaring={activityForecastListWaring} activityForecastListWaringZeroRatio={activityForecastListWaringZeroRatio} omsChangeHistory={omsChangeHistoryWarnings} defaultCustomers={defaultCustomers}/>
            <Section subtitleToShow={omsstateValue ? subtitleToDisplay : ''} message={messages.activityAssignment}>
              <br />
              {
                (initialForecastConversionRatesLoader) ?
                <Loading message = {messages.omsLoading}/> :
                <OMSCodesAndSmartProdJobs
                planningParametersId={planningParametersId}
                forecastEdit={forecastEdit}
                forecastConversionRates={forecastConversionRates}
                handleReloadData={handleReloadData}
                allOmsCodes={allOmsCodes}
                allSmartProdSources={allSmartProdSources}
                activityForecastList={activityForecastList}
                dates={dates}
                planActivityMapping={planDetails?.activityMappingEditable}
              />
              }
            </Section>
            <Section message={messages.forecastPerOMSCode}>
              {
                  ((forecastLoader || initialForecastConversionRatesLoader)) ?
                  <Loading message={messages.forecastLoading} /> :
                  <ForecastPerOMSCode
                    planningParametersId={planningParametersId}
                    forecastConversionRates={forecastConversionRates}
                    forecastPerOMSCode={forecastPerOMSCode}
                    allOmsCodes={allOmsCodes}
                    allSmartProdSources={allSmartProdSources}
                    activityForecastList={activityForecastList}
                    handleReloadData={handleReloadData}
                    dates={dates}
                    hasAdjustments={hasAdjustments}
                  />
              }
            </Section>
            {
              hasAdjustments &&
              <Section message={messages.adjustmentsMapping}>
                <Adjustments smartProdJobAdjustments={smartProdJobAdjustments} smartProdActivityAdjustments={smartProdActivityAdjustments} planDetails={planDetails} response={response } />
              </Section>
            }
            <Section message={messages.smartProdRuns}>
              <SmartProdRuns
              forecastEdit={forecastEdit}
              data={{ requested: smartProdRuns, scheduled: smartProdScheduledRuns, timeTransformation: timeTransformationRuns }}
              allOmsCodes={allOmsCodes}
              allSmartProdSources={allSmartProdSources}
              planningParametersId={planningParametersId}
              handleSubmitPage={handleSubmitPage}
              handleReloadData={handleReloadData}
              hasAdjustments={hasAdjustments}
              planDetails={cloneDeep(planDetails)}
            />
            </Section>
          </>
        )}
      </Wrap>
    </Formik>
  );
};
// @ts-ignore
export default compose(injectIntl, withSecurity(PERMISSIONS.VIEW_FORECAST_SECTION))(ForecastDetailPage);
