/**
 *
 * ShiftSettings
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import SubWZPRenderer from './SubWZPrenderer';
import { DeleteDialog, withDeleteDialog } from '../../components/Dialog';
import { TABLE_DEFAULTS } from '../App/constants';
import { makeSelectToken } from '../LoginPage/selectors';
import { deleteWZPSettingsLine } from '../PlanDetailPage/actions';
import { selectEditFromplan, selectPeriodIndexFromPlan } from '../PlanDetailPage/selectors';
import { deleteWZPSettingsLinePa } from '../PlanningAreaDetailPage/actions';
import { selectEditFromPa, selectPeriodIndexFromPa } from '../PlanningAreaDetailPage/selectors';
import TableControlled from '../TableControlled';
import createColumnDef from './columnDefs';
import {createWZPColumnDef} from './columnDefs';
import LabourCategoryCellRenderer from './LabourCategoryCellRenderer';
import messages from './messages';

const isRowMaster =  ((dataItem) => {
  return dataItem ? dataItem?.workZonePeriods?.length > 1 : false;
});

const isFullWidthCell = rowNode => rowNode.data?.fullWidth;

const getRowHeight = props => params => {
  // you can have normal rows and full width rows any height that you want
  const isBodyRow = params.node.rowPinned === undefined;
  const isFullWidth = params.node?.data?.fullWidth;
  let result = 35;
  if (isBodyRow && isFullWidth) {
    const items = params.node.data.categories || [];
    result = (props.edit ? 120 : 70) + ((items.length || 1) + 1) * 45;
  }
  return result;
};

const Wrap = styled.div`
  height: ${props => props.height}px;
  .ag-react-container {
    width: 100%;
  }
  .ag-full-width-row {
    overflow: visible;
  }
  > span {
    line-height: 39px;
  }
`;

const Table = styled(TableControlled)`
  height: ${props => props.height}px;
`;

class ShiftTable extends React.Component {
  tableKey = props => `${props.isShift}_${props.edit}_${props.periodIndex}`;

  state = {
    openEditor: null,
    gridApi: null,
    shiftIdsOpen: [],
  };

  // countRows(props) {
  //   // this.props.periodIndex insode getFormikDataPath can be safely used as this function is used only when
  //   // period of old and new props is the same
  //   const rowData = getIn(props.formik.values, this.getFormikDataPath(), []);
  //   //return rowData.reduce((a, i) => `${a}${i.labourCategoryTransitions.length}`, 'RC');
  // }

  onDateChange = (rowIndex, colKey) => {
    this.setState({ opendEditor: { rowIndex, colKey } });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const newKey = this.tableKey(nextProps);
    const oldKey = this.tableKey(this.props);
    const thisValues = this.props.formik.values;
    const nextValues = nextProps.formik.values;
    const { tableKey = 'shift-volume', isShift, shiftIdsOpen } = nextProps;
    if(this.props.formik.values.planningParameters.labourAvailabilityType !== nextProps.formik.values.planningParameters.labourAvailabilityType){
      return true;
    }
    if(this.props.shiftDaysRemovedWarning !== nextProps.shiftDaysRemovedWarning) {
      return true;
    }
    if(!isEqual(thisValues.planningParameters.periods, nextValues.planningParameters.periods)){
      return true;
    }
    if (this.state.shiftIdsOpen !== nextState.shiftIdsOpen) {
      return true;
    }
    if(!isEqual(this.props?.periodIndex, nextProps?.periodIndex )){
      return true;
    }
    // if(!isEqual(this.props.isLoading, nextProps.isLoading )){
    //   return true;
    // }
    if(!isEqual(this.props.edit, nextProps.edit )){
      return true;
    }
    if (isShift !== this.props.isShift) {
      return true;
    }
    // if (newKey === oldKey) {
    //   const oldRowCounts = this.countRows(this.props);
    //   const newRowCounts = this.countRows(nextProps);
    //   if (oldRowCounts === newRowCounts) {
    //     if (isShift && tableKey === 'shift-volume' && nextProps.edit) {
    //       let value = getIn(this.props.formik.values, this.getFormikDataPath());
    //       value = (value && value.map(v => omit(v, ['labourCategoryTransitions']))) || [];
    //       let nextValue = getIn(nextProps.formik.values, this.getFormikDataPath());
    //       nextValue = (nextValue && nextValue.map(v => omit(v, ['labourCategoryTransitions']))) || [];
    //       return JSON.stringify(value) !== JSON.stringify(nextValue);
    //     }
    //     return false;
    //   }
    // }
    //return true;
  }

  openAction = id => {
    const { shiftIdsOpen } = this.state;
    if (shiftIdsOpen.includes(id)) {
      this.setState({ shiftIdsOpen: shiftIdsOpen.filter(i => i !== id) });
    } else {
      this.setState({ shiftIdsOpen: shiftIdsOpen.concat([id]) });
    }
  };

  handleShiftDeletion = payload => {
  const mheAvailabilityPath = () => `planningParameters.periods.${this.props.periodIndex}.mheAvailabilities`;
  let formikValues = this.props.formik.values;
  const mheAvails = getIn(formikValues, mheAvailabilityPath(), []);
  const deletionFlag = mheAvails && mheAvails.filter((mhea) => mhea.wzp && mhea.wzp.id === payload.id);
  return deletionFlag;
  }

  render() {
    const { tableKey = 'shift-volume', isShift = false } = this.props;
    const { shiftIdsOpen } = this.state;
    const { columnDefs } = createWZPColumnDef(this.props, this.openAction, this.handleShiftDeletion);
    const form = this.props.formik;
    const value = getIn(form.values, this.getFormikDataPath());
    const { openEditor } = this.state;
    let rowData = [];
    if (value) {
      if (tableKey === 'shift-volume') {
        rowData = value.map(row => ({
          ...row,
          detailsButtons: true,
          isOpen: shiftIdsOpen && shiftIdsOpen.includes(row.id),
        }));
      } else {
        rowData = cloneDeep(value);
      }
    }

    const totalRows = rowData.length;

    let details = 0;
    let detailItems = 0;
    const rowDataTemp = rowData;
    rowData = [];
    rowDataTemp.forEach(item => {
      // eslint-disable-line
      rowData.push(item);
      if (item.isOpen) {
        details += 1;
        detailItems += (item.labourCategoryTransitions || []).length || 2;
        rowData.push({
          fullWidth: true,
          categories: item.labourCategoryTransitions,
          editable: this.props.edit,
          shiftId: item.id,
          shiftIndex: item.index,
          id: -item.id,
        });
      }
    });
    const aproxHeight = (totalRows || 1) * 40 + 70 + 50 + (details * 120) + (detailItems * 35);
    let config = TABLE_DEFAULTS.wzpSettingsTableConfig;
    let name = 'wzpSettingsTable';
    if (tableKey === 'shift-definition') {
      if (this.props.edit) {
        config = TABLE_DEFAULTS.wzpSettingsTableEditConfig;
        name = 'wzpSettingsEditTable';
      }
    }

    let result = "";
    rowData && rowData.forEach((object, index) => {
      result += `${object && object.name}_${object && object.workZonePeriods && object.workZonePeriods.length}`
      if (index < rowData.length - 1) {
        result += '_';
      }
    });
    const onFirstDataRendered = params => {
      params.api.forEachNode(node => {
        if (node?.data?.workZonePeriods?.length > 1) {
          node.setExpanded(true);
        }else{
          node.setExpanded(false);
        }
      });
    };
    const handleParentMouseOut = (params) => {
      if(this.state.grid && this.state.grid.api && params?.colDef?.colId === 'businessDay'){
        const cell = this.state.grid.api.getFocusedCell();
        if(cell?.column?.colId === 'days') this.state.grid.api.clearFocusedCell();
      }
    }
    return (
      <Wrap>
        <Table
          key={`${tableKey}-${this.props.deleteDialogOpen}-${this.props.isLoading}-${result}-${this.props.formik.values.planningParameters.transformationType}-${this.tableKey(this.props)}-${(shiftIdsOpen || []).join('-')}`}
          masterDetail
          domLayout="autoHeight"
          detailCellRenderer="SubWZPRenderer"
          frameworkComponents={{
            SubWZPRenderer: SubWZPRenderer,
          }}
          detailCellRendererParams={params => ({
            formik: this.props.formik,
            intl : this.props.intl,
            editable: this.props.edit,
            periodIndex: this.props.periodIndex,
            params,
            isWZP: true,
            handleParentMouseOut
          })}
          defaultColDef={{
            flex: 1,
          }}
          showCOG={false}
          detailRowHeight={150}
          isRowMaster={isRowMaster}
          groupDefaultExpanded={1}
          defaultConfig={config}
          messages={messages}
          name={name}
          pagination={false}
          columnDefs={columnDefs}
          rowHeight={40}
          rowData={rowData}
          onFirstDataRendered={onFirstDataRendered}
          getRowNodeId={data => data.id}
          deltaRowDataMode
          onCellValueChanged={params => {
            this.onCellValueChanged(params, form);
          }}
          //getRowHeight={getRowHeight(this.props)}
          onGridReady={params => this.setState({ grid: params })}
          singleClickEdit
        />
      </Wrap>
    );
  }

  onCellValueChanged(params, form) {
    if (form) {
      const modifiedIndex = form.values?.planningParameters?.periods[this.props.periodIndex]?.realShiftsWithWZP?.findIndex((wzp) => wzp.id === params?.data?.id);
      form.setFieldValue(`planningParameters.periods.${this.props.periodIndex}.realShiftsWithWZP.${modifiedIndex}.${params.colDef.field}`, params.newValue)
      //setFieldValue([...this.getFormikDataPath().split('.'), params.rowIndex, params.colDef.field], params.newValue);
    } else {
      console.warn('ShiftSettings setFieldValue property not set, cannot propagate value update', params);
      console.log('ShiftSettings props are', this.props);
    }
  }

  getFormikDataPath = () => `planningParameters.periods.${this.props.periodIndex}.realShiftsWithWZP`;
}

/* eslint-disable react/prefer-stateless-function */
const WZPSettings = props => (
  <>
    <ShiftTable {...props} />
    <DeleteDialog {...props} text={messages.dialogWZPDeleteText} />
  </>
);

ShiftTable.propTypes = {
  intl: PropTypes.object,
  edit: PropTypes.bool,
  formik: PropTypes.object,
  deleteShiftSettingsLine: PropTypes.func,
  messages: PropTypes.object,
  createColumnDef: PropTypes.func,
  setFieldValue: PropTypes.func,
  token: PropTypes.string,
  periodIndex: PropTypes.number,
};

// Plan
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  token: makeSelectToken(),
  periodIndex: selectPeriodIndexFromPlan,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteWZPSettingsLine,
    },
    dispatch,
  );
}

const withPlanConnect = connect(mapPlanStateToProps, mapPlanDispatchToProps);

// Planning area
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  token: makeSelectToken(),
  periodIndex: selectPeriodIndexFromPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteWZPSettingsLine: deleteWZPSettingsLinePa,
    },
    dispatch,
  );
}

const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export default compose(injectIntl, withPlanConnect, withDeleteDialog)(WZPSettings);

export const PaWZPSettings = compose(injectIntl, withPaConnect, withDeleteDialog)(WZPSettings);
