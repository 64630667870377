/* eslint-disable no-restricted-syntax */
/**
 *
 * ActivitySettingsDirect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect as formikConnect, getIn } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { customerFormat, getCustomers } from 'components/CustomerCellRenderer';
import { IndirectValueGetter } from 'components/ListGrid/utils';
import NameWithToolTip, { UOMNameWithToolTip } from 'components/NameWithToolTip';
import { groupByDepartmentProps } from 'components/Table/utils';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { selectEditFromplan, selectPeriodIndexFromPlan } from 'containers/PlanDetailPage/selectors';
import { selectEditFromPa, selectPeriodIndexFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import TableControlled from 'containers/TableControlled';
import { DAY_NAMES } from 'utils/calendar/constants';
import calendarMessages from 'utils/calendar/messages';
import { checksumPercentageFormatter, checkSumValidationStyle, makeChecksumGetterByFilter } from 'utils/checksum';
import { numericSetter, percentageCellFormatterNull as percentageFormatter, toNumber } from 'utils/utils';

import { DOW_TRANS_ACTIVITY_AKA_WEEKLY } from '../../utils/constants';
import messages from './messages';

export function isDayOfWeekTransformationActivity(formik) {
  if (formik) {
    const dayTransformationType = getIn(
      formik.initialValues,
      'planningParameters.dayTransformationType',
      DOW_TRANS_ACTIVITY_AKA_WEEKLY,
    );
    return dayTransformationType === DOW_TRANS_ACTIVITY_AKA_WEEKLY;
  }
  return true;
}

function activitySpecificColumns(formatMessage, customers) {
  return [
    {
      headerName: null,
      children: [{
        headerName: null,
        width: 100,
        colId: 'department',
        menuTabs: ['filterMenuTab'],
        valueGetter: params => {
          if (params.data) {
            return params.data.department ? params.data.department.name : formatMessage(messages.allDepartments);
          }
          return null;
        },
        rowGroup: true,
      }]
    },
    {
      headerName: formatMessage(messages.activity),
      width: 100,
      colId: 'activityName',
      field: 'activityName',
      cellRendererFramework: NameWithToolTip('activity.regionalConfigurationName'),
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.customer),
      width: 100,
      colId: 'customerId',
      field: 'customerId',
      menuTabs: ['filterMenuTab'],
      valueFormatter: customerFormat(customers, formatMessage),
    },
    {
      headerName: formatMessage(messages.uom),
      width: 100,
      colId: 'uomName',
      field: 'uomName',
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: UOMNameWithToolTip,
    },
    {
      headerName: formatMessage(messages.indirect),
      field: 'activity.indirect',
      colId: 'activity.indirect',
      valueGetter: IndirectValueGetter,
      width: 120,
      menuTabs: ['filterMenuTab'],
    },
  ];
}

function volumeCategorySpecificColumns(formatMessage, customers) {
  return [
    {
      headerName: 'Is Variable',
      width: 100,
      colId: 'vcType',
      menuTabs: ['filterMenuTab'],
      valueGetter: params =>
        params.data && params.data.volumeCategory.variable
          ? formatMessage(messages.vcVariable)
          : formatMessage(messages.vcNonVariable),
      rowGroup: true,
    },
    {
      headerName: '',
      children: [{
        headerName: formatMessage(messages.volumeCategory),
        width: 100,
        colId: 'volumeCategory',
        field: 'volumeCategory',
        cellRendererFramework: NameWithToolTip('volumeCategory.regionalConfigurationName'),
        menuTabs: ['filterMenuTab'],
        valueGetter: params =>
          params.data ? `${params.data.volumeCategory.name} (${params.data.volumeCategory.variableName})` : null,
      }]
    },
    {
      headerName: formatMessage(messages.customer),
      width: 100,
      colId: 'customerId',
      field: 'customerId',
      menuTabs: ['filterMenuTab'],
      valueFormatter: customerFormat(customers, formatMessage),
    },
    {
      headerName: formatMessage(messages.uom),
      width: 100,
      colId: 'uomName',
      field: 'uomName',
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: UOMNameWithToolTip,
    },
  ];
}

const deparmentValueGetter = formatMessage => params => {
  if (params.data) {
    return params.data.department ? params.data.department.name : formatMessage(messages.allDepartments);
  }
  return null;
};

function createColumnDef(props) {
  const {
    intl: { formatMessage },
    formik,
  } = props;
  const editable = props.edit;

  const customers = getCustomers(formik);
  const columnDefs = isDayOfWeekTransformationActivity(formik)
    ? activitySpecificColumns(formatMessage, customers)
    : volumeCategorySpecificColumns(formatMessage, customers);

  for (const day of DAY_NAMES) {
    columnDefs.push({
      headerName: formatMessage(calendarMessages[day.toLowerCase()]),
      field: day,
      colId: day,
      width: 120,
      valueFormatter: percentageFormatter,
      valueSetter: numericSetter,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      editable,
    });
  }
  columnDefs.push({
    headerName: formatMessage(messages.checkSum),
    colId: 'checksum',
    valueGetter: makeChecksumGetterByFilter(key => key.match(/wzp_.*/) || DAY_NAMES.includes(key)),
    width: 120,
    valueFormatter: checksumPercentageFormatter,
    cellStyle: checkSumValidationStyle,
    suppressMenu: true,
    sortable: false,
    suppressMovable: true,
  });
  return columnDefs;
}

const Table = styled(TableControlled)`
  height: ${props => props.height}px;
`;

const Header = styled.div`
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
`

const StyledDiv = styled.div`
  .MuiBox-root {
   padding-top : 5px !important;
   } 
`

/* eslint-disable react/prefer-stateless-function */
class DayOfWeekTransformation extends React.Component {
  dayTransitionPath = props => {
    const p = props || this.props;
    return isDayOfWeekTransformationActivity(p.formik)
      ? `planningParameters.periods.${p.periodIndex}.apCalculated.dayTransitions`
      : `planningParameters.periods.${p.periodIndex}.volumeCategoryDayTransitions`;
  };

  keyFromProps = props => {
    const rowData = getIn(props.formik.values, this.dayTransitionPath(props), []);
    const activityMode = isDayOfWeekTransformationActivity(props.formik);
    const reloadCount = getIn(props.formik, 'values.reloadCount');
    return `${activityMode}_${props.edit}_${props.periodIndex}_${rowData.length}_${reloadCount}`;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.keyFromProps(nextProps) !== this.keyFromProps(this.props);
  }

  onCellValueChanged = params => {
    const { setFieldValue } = this.props.formik;
    const fieldName = `${this.dayTransitionPath()}.${params.data.index}.${params.colDef.field}`;
    setFieldValue(fieldName, toNumber(params.newValue));
  };

  onPaste = data => {
    const { setFieldValue } = this.props.formik;
    const fieldName = this.dayTransitionPath();
    data.sort((a, b) => (a.index < b.index ? -1 : 1));
    setFieldValue(fieldName, data);
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const activityMode = isDayOfWeekTransformationActivity(this.props.formik);
    const fieldPath = this.dayTransitionPath();
    // agGrid IS MUTATING row data - without cloneDeep formik's initial data gets overridden on paste
    const rowData = cloneDeep(getIn(this.props.formik.values, fieldPath));
    const volumeRowData=[...rowData];
    volumeRowData && volumeRowData.forEach((vd)=>{
      const uomName = vd && vd.uom && vd.uom.name;
      vd.uomName = uomName;
    })
    const values = [ 'STAFF_UNPRODUCTIVE', 'LOCATION_UNPRODUCTIVE' ];
    const filteredRowData = rowData && rowData.filter(item => item && item.activity && item.activity.productivityType && !values.includes(item.activity.productivityType));
    filteredRowData && filteredRowData.forEach((fd) => {
      const activityName = fd && fd.activity && fd.activity.name;
      const variableName = fd && fd.variableName
      const uomName = fd && fd.uom && fd.uom.name;
      fd.activityName = `${activityName} (${variableName})`;
      fd.uomName = uomName;
    })
    // console.log('TT rowdata', rowData);
    const totalRows = filteredRowData.length;
    const aproxHeight = Math.min(totalRows || 1, 15) * 35 + 120;
    const tableName = activityMode ? 'dayOfWeekTransformation' : 'dayOfWeekVolumeCategoryTransformation';
    return (
      <StyledDiv>
        <Header><FormattedMessage {...(activityMode ? messages.header : messages.volumeCategoryHeader)} /></Header>
        <Table
          showCollapseButtonsInsideTable
          key={this.keyFromProps(this.props)}
          defaultConfig={
            activityMode
              ? TABLE_DEFAULTS.transformationTableConfig
              : TABLE_DEFAULTS.dayOfWeekVolumeCatTransformationTableConfig
          }
          autosize
          pagination={false}
          showCOG={false}
          messages={messages}
          name={tableName + (this.props.edit ? 'Edit' : '')}
          height={aproxHeight}
          columnDefs={createColumnDef(this.props)}
          rowData={activityMode ? filteredRowData : volumeRowData}
          onCellValueChanged={this.props.edit ? this.onCellValueChanged: null}
          getRowNodeId={data => data.id}
          deltaRowDataMode
          onPaste={this.props.edit ? this.onPaste: null}
          // Grouping
          {...groupByDepartmentProps(formatMessage, messages, deparmentValueGetter(formatMessage))}
        />
      </StyledDiv>
    );
  }
}

DayOfWeekTransformation.propTypes = {
  edit: PropTypes.bool,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};
// Plan
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
});

const withPlanConnect = connect(mapPlanStateToProps);

// Planning area
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
});

const withPaConnect = connect(mapPaStateToProps);

export default compose(injectIntl, withPlanConnect, formikConnect)(DayOfWeekTransformation);

export const PaDayOfWeekTransformation = compose(injectIntl, withPaConnect, formikConnect)(DayOfWeekTransformation);
