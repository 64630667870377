import React, { useEffect, useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Link } from "@material-ui/core";

import DateTimePickerCellEditor from 'components/DateTimePickerCellEditor';
import { dataToSearch } from 'utils/url';
import { PATHS } from 'containers/App/constants';
import AgTable from 'components/Table';
import { dateComparator, dateTimeCellFormatter, parseDate } from 'utils/dateTime';
import { DisableValueGetter } from 'components/ListGrid/utils';
import { ROLES } from 'utils/security';
import { ApiMasterPlanToPlanDTO, ApiPlanDTO } from 'types/drep-backend.d';

import { makeSelectUser } from '../LoginPage/selectors';
import messages from './messages';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import ActionCell from 'components/DeleteCellRenderer/ActionCell';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }
  .past-row {
    color: red;
  }
  .future-row {
    color: Orange;
  }
  .ag-full-width-row {
    overflow: visible;
  }
`;

const validFromToStyle = (params, admin, col) => {
  if (admin) {
    return !!params.colDef.editable;
  }
  if (!admin) {
    return params.data[col] < DateTime.now().minus({ days: 1 }).startOf('day') ? false : params.colDef.editable != false;
  }
};

const columnDefs = (intl: InjectedIntl, formik, editable: boolean, isAdmin: boolean, withAction: boolean, mappingTable: boolean, history, canEditAddValidity: boolean) => {
  const handleFrom = params => {
    if (isAdmin && editable) {
      return !!params.colDef.editable;
    }
    if (!isAdmin && editable) {
      return params.data.validFrom < DateTime.now().minus({ days: 1 }) ? false : params.colDef.editable != false;
    }
    if (!editable) {
      return false;
    }
  };
  const handleTo = params => {
    if (isAdmin && editable) {
      return !!params.colDef.editable;
    }
    if (!isAdmin && editable) {
      return params.data.validTo < DateTime.now().minus({ days: 1 }) ? false : params.colDef.editable != false;
    }
    if (!editable) {
      return false;
    }
  };

  const goToPlanDetail = (params) => {
    const path = `${PATHS.masterplanplanDetailId.replace(':id', `${params?.data?.planId}`)}?isEdit=true&masterPlanId=${params?.data?.mId}`;
    history.push(path);
  };

  const goToPADetail = (params) => {
    const path = `${PATHS.masterplanplanningAreaDetail.replace(':id', `${params?.data?.planningArea?.id}`)}?isEdit=true&masterPlanId=${params?.data?.mId}`;
    history.push(path);
  };

  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'name',
      field: 'name',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.planListName),
      cellRendererFramework: params => {
        //const path = `${PATHS.masterplanplanDetailId.replace(':id', `${params?.data?.planId}`)}?isEdit=true&masterPlanId=${params?.data?.mId}`;
        //return <Link rel="noopener" target='_blank' href = {path}><a><span>{params?.value}</span></a></Link>
        return <Link target='_blank' style={{cursor:'pointer'}} onClick={(event) => goToPlanDetail(params)}>{params?.value}</Link>
      },
      sortable: true,
      resizable: true,
    },
    {
      cellStyle: {color : 'black !important'},
      cellRendererFramework: ActionCell,
      cellRendererParams: {
        mappingTable,
        editable,
        canEditAddValidity,
        onDetail: data => {
          let path = PATHS.masterplanplanDetailId;
          path = path.replace(':id', data.planId);
          history.replace({pathname: path, search : dataToSearch({masterPlanId: data.mId})});
        },
        onCopy: data => {
          let path = PATHS.makeCopyPlan;
          path = path.replace(':id', data.planId);
          history.push({ pathname: path, search: dataToSearch({planId: data.planId, masterPlanId: data.mId}) });
        },
        onInterface: data => {
          let path = PATHS.forecast;
          path = path.replace(':id', data.planId);
          history.push({ pathname: path, search: dataToSearch({planId: data.planId, masterPlanId: data.mId}) });
        },
        onResult: data => {
          let path = PATHS.masterplanplanResult;
          path = path.replace(':id', data.planId);
          history.push({ pathname: path, search: dataToSearch({planId: data.planId, masterPlanId: data.mId}) });
        },
        addValidity: (d) =>{
          const currentData = [...formik.values.masterPlan.plans];
          const newData = currentData.map((data) => {
            return ((data.validFrom === null && data.validTo === null) && ((data.planId === d.planId && data.id === d.id) || (data.planId === d.planId && data.uuid === d.uuid))) ? { ...data, 'validFrom': DateTime.local(), 'validTo': DateTime.local(2100, 1, 1) } : data;
          }
          )
          
          formik.setFieldValue(`masterPlan.plans`, newData);
        }
      },
      colId: 'actions',
      field: 'actions',
      headerName: 'Actions',
      width: mappingTable? 300: 175,
      suppressMenu: true,
    },
    {
      colId: 'planningArea.name',
      field: 'planningArea.name',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.planListPlanningAreaBase),
      cellRendererFramework: params => {
        //const path = `${PATHS.masterplanplanningAreaDetail.replace(':id', `${params?.data?.planningArea?.id}`)}?isEdit=true&masterPlanId=${params?.data?.mId}`;
        //return <Link rel="noopener" target='_blank' href = {path}><a><span>{params?.value}</span></a></Link>
        return <Link style={{cursor:'pointer'}} onClick={() => goToPADetail(params)}>{params?.data?.planningArea?.name}</Link>
      },
      sortable: true,
      resizable: true,
    },
    {
      colId: 'facility',
      field: 'facility',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      filter: 'setFilter',
      valueGetter: ({ data }) => {
        const units =
          data.planningArea && data.planningArea.facilities
            ? data.planningArea.facilities.map(fac => fac.code).join(', ')
            : [];
        return units;
      },
      headerName: intl.formatMessage(messages.planListFacility),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'managementUnit',
      field: 'managementUnit',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueGetter: ({ data }) =>
        data.planningArea && data.planningArea?.managementUnits
          ? data.planningArea?.managementUnits?.map(c => `${c.code}: ${c.description}`).join(', ')
          : '',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.planListManagementUnit),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'customer',
      field: 'customer',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueGetter: ({ data }) =>
        data.planningArea && data.planningArea.customers
          ? data.planningArea.customers.map(c => `${c.code}: ${c.name}`).join(', ')
          : '',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.planListCustomer),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'audit.updated',
      field: 'audit.updated',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: dateTimeCellFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      headerName: intl.formatMessage(messages.planListModified),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'audit.updatedBy',
      field: 'audit.updatedBy',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueGetter: ({ data }) => `${data.audit?.updatedBy?.firstName || ''} ${data.audit?.updatedBy?.lastName || ''}`,
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.planListModifiedBy),
      sortable: true,
      resizable: true,
    },
    {
      headerName: intl.formatMessage(messages.status),
      width: 120,
      field: 'deleted',
      colId: 'deleted',
      suppressMenu: true,
      resizable: true,
      valueGetter: DisableValueGetter,
    },
  ];
  const validFromColumn = {
    colId: 'validFrom',
    field: 'validFrom',
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    valueFormatter: dateTimeCellFormatter,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      comparator: dateComparator,
    },
    onCellValueChanged: params => {
      const currentData = [...formik.values.masterPlan.plans];
      const newData = currentData.map((data)=> {
        return (((data.planId === params.data.planId) && (data.id === params.data.id))) ? {...data, [params.colDef.field] : params.newValue} : data;
      }
      )
      formik.setFieldValue(`masterPlan.plans`, newData);
    },
    cellRendererFramework: DateTimePickerCellEditor,
    editable: editable && handleFrom,
    cellRendererParams: params => ({
      editable: validFromToStyle(params, isAdmin, 'validFrom'),
      minDate: isAdmin ? undefined : DateTime.now().minus({ days: 1 }),
    }),
    headerName: intl.formatMessage(messages.planListValidFrom),
    sortable: true,
    resizable: true,
  };
  const validToColumn = {
    colId: 'validTo',
    field: 'validTo',
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    valueFormatter: dateTimeCellFormatter,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      comparator: dateComparator,
    },
    onCellValueChanged: params => {
      const currentData = [...formik.values.masterPlan.plans];
      const newData = currentData.map((data)=> 
        (((data.planId === params.data.planId) && (data.id === params.data.id))) ? {...data, [params.colDef.field] : params.newValue} : data,   
      )
      formik.setFieldValue(`masterPlan.plans`, newData);
    },
    cellRendererFramework: DateTimePickerCellEditor,
    cellRendererParams: params => ({
      editable: validFromToStyle(params, isAdmin, 'validTo'),
      minDate: isAdmin ? undefined : DateTime.now().minus({ days: 1 }),
    }),
    editable: editable && handleTo,
    headerName: intl.formatMessage(messages.planListValidTo),
    sortable: true,
    resizable: true,
  };
  !mappingTable && columns.splice(6, 0, validFromColumn);
  !mappingTable && columns.splice(7 , 0, validToColumn);
  
  const actionColumn: ColDef = {
    cellRendererFramework: DeleteCellRenderer,
    cellRendererParams: {
      onDelete: params => {
        if (params.validFrom === null && params.validTo === null) {
          formik.setFieldValue('masterPlan.plans', formik.values.masterPlan.plans.filter(it => it.uuid !== params.uuid));
        } else {
          const currentData = [...formik.values.masterPlan.plans];
          const newData = currentData.map((data) => {
            const newData = {
              planId: data.planId,
              uuid: data.uuid,
              validFrom: null,
              validTo: null,
            }
            return (((data.uuid === params.uuid))) ? newData : data;
          }
          )
          formik.setFieldValue(`masterPlan.plans`, newData);
        }

      },
      hide: row => row.uuid,
    },
    colId: 'removeButton',
    field: 'id',
    headerName: '',
    width: 50,
    pinned: true,
    suppressMenu: true,
  };
  return withAction ? [actionColumn, ...columns] : columns;
};

type Props = {
  formik: any;
  intl: InjectedIntl;
  data: (ApiMasterPlanToPlanDTO & { uuid?: string })[];
  plans: ApiPlanDTO[];
  fullPlans: ApiPlanDTO[];
  editable: boolean;
  user: any;
  mappingTable: boolean;
  history: any;
  canEditAddValidity?: boolean;
};

const PlansTable: React.FC<Props> = ({ intl, formik, data, plans, editable, user, fullPlans, mappingTable, history, canEditAddValidity }) => {
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };
  const userData = user.toJS();
  const isAdmin = userData.roles.find(r => r.role === ROLES.ADMIN.name);

  const ROW_HEIGHT = 30;

  let withAction = false;
  const rowsData = data.map(row => {
    const plan = fullPlans?.find(p => p.id === row.planId);
    withAction = withAction || row.uuid !== undefined;
    return {
      ...row,
      validFrom: row.validFrom ? parseDate(row.validFrom) : row.validFrom,
      validTo: row.validTo ? parseDate(row.validTo) : row.validTo,
      ...plan,
      id: row.id,
      mId: formik?.values?.masterPlan?.id
    };
  });

  rowsData?.sort((a,b) => a?.name < b?.name ? 1 : -1)?.sort((a,b) => a?.deleted < b?.deleted ? -1 : 1);
  useEffect(() => {
    if (gridApi) {
      const colDefs = columnDefs(intl, formik, editable, isAdmin, withAction, mappingTable, history, canEditAddValidity);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
    }
  }, [gridApi, data, plans, withAction]);

  return (
    <Table
      masterDetail
      defaultColDef={{
        flex: 1,
      }}
      sortable
      pagination={false}
      columnDefs={columnDefs(intl, formik, editable, isAdmin, withAction, mappingTable, history, canEditAddValidity)}
      height={80 + ROW_HEIGHT * Math.min(10, rowsData?.length || 0)}
      rowData={rowsData}
      rowHeight={ROW_HEIGHT}
      onGridReady={onGridReady}
      onCellMouseDown={params => {
        if (params?.event?.button === 1 && (params?.colDef?.field === "planningArea.name" || params?.colDef?.field === "name")) {
          let path = `${PATHS.masterplanplanDetailId.replace(':id', `${params?.data?.planId}`)}?isEdit=true&masterPlanId=${params?.data?.mId}`;
          if (params?.colDef?.field === "planningArea.name") path = `${PATHS.masterplanplanningAreaDetail.replace(':id', `${params?.data?.planningArea?.id}`)}?isEdit=true&masterPlanId=${params?.data?.mId}`;
          window.open(path);
        }
      }}
      getRowClass={params => {
        if(mappingTable || params?.colDef?.colId === 'actions'){
          return;
        }
        const classes = [];
        if (params.data.validTo <= DateTime.now().minus({ days: 1 })) {
          classes.push('past-row');
        } else if (params.data.validTo <= DateTime.now().plus({ days: 40 })) {
          classes.push('future-row');
        }
        return classes.join(' ');
      }}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});
const withConnect = connect(mapStateToProps);
export default injectIntl(withConnect(PlansTable));
