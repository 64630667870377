/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ShiftAddLine from 'components/ShiftAddLine';
import ShiftSettings from 'containers/ShiftSettings/LabourShiftSettings';
import ShiftsGraph from 'containers/ShiftsGraph';
import messages from './messages';

const ShiftsGraphStyled = styled(ShiftsGraph)`
  position: relative;
  z-index: 0;
`;

/* eslint-disable react/prefer-stateless-function */
class PlanDetailShifts extends React.PureComponent {
  render() {
    const { values, formik, isShift, roles } = this.props;
    return (
      <div style={{ marginTop: '10px' }}>
        <FormattedMessage {...messages.header} />
        {!isShift && <ShiftAddLine {...{ values }} />}
        <ShiftSettings isShift={isShift} formik={formik} roles={roles}/>
        <FormattedMessage {...messages.headerGraphicalView} />
        <ShiftsGraphStyled data={values} periodIndex={this.props.periodIndex} />
      </div>
    );
  }
}

PlanDetailShifts.propTypes = {
  values: PropTypes.object,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};

export default PlanDetailShifts;
