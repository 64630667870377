/**
 *
 * AdminUpload
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import saga from '../../containers/PlanDetailPage/saga';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle } from '@material-ui/core';
import Select from 'components/StyledSelect';
import styled from 'styled-components';
import { withUrl, api } from 'utils/api';
import { getToken } from 'containers/App/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'utils/reduxApi';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { PATHS } from 'containers/App/constants';

const ConfirmButton = withStyles({
    root: {
        borderRadius: 0,
        border: 0,
        height: 48,
        padding: '0 30px',
        color: '#FFF',
        backgroundColor: '#D40511',
        '&:hover': {
            backgroundColor: '#D40511',
            color: '#FFF',
        },
    },
})(Button);

const CancelButton = withStyles({
    root: {
        borderRadius: 0,
        border: 0,
        height: 48,
        padding: '0 30px',
        color: '#000',
        backgroundColor: '#FFCC00',
        '&:hover': {
            backgroundColor: '#FFCC00',
            color: '#000',
        },
    },
})(Button);

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 31px;
`;

const WarningDiv = styled.div`
  padding-left: 31px;
  color: red;
`;

const BackToPlansButton = withStyles({
    root: {
        borderRadius: 0,
        border: 0,
        height: 48,
        padding: '0 30px',
        color: 'black',
        backgroundColor: '#FFCC00',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFCC00',
            color: 'black',
        },
    },
})(Button);

export const materialTheme = () =>
    createMuiTheme({
        overrides: {
            MuiDialogPaper: {
                overflowY: 'scroll'
            },
            MuiDialogTitle: {
                root: {
                    paddingBottom: '5px'
                }
            },
            MuiRadio: {
                colorSecondary: {
                    '&.Mui-checked': { color: '#3a74ce !important' }
                }
            },
            MuiButton: {
                root: {
                    '&.Mui-disabled': { backgroundColor: 'rgb(153, 153, 153)' }
                }
            },
        },
    });


/* eslint-disable react/prefer-stateless-function */
const MasterPlanDialog = (props) => {
    const muiTheme = materialTheme();
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('');
    const [selectedPlan, setSelectedPlan] = React.useState(null);
    const [response, setResponse] = React.useState([]);
    const facilityFlag = props.plan.planningArea.facilities.length === 0;
    const customerFlag = props.plan.planningArea.customers.length === 0;
    const [masterPlanFlag,setMasterPlanFlag] = React.useState(props.selectPlanDetailMasterPlan.length > 0);
    React.useEffect(() => {
        const getMasterPlans = async () => {
            const filterMasterPlanOptions = [];
            const facilities = props.plan.planningArea.facilities.map(f => f.id);
            const facilitiesPayload = facilities.length > 0 ? facilities.toString() : '';
            const planningAreaId = props.plan.planningArea.id;
            const customers = props.plan.planningArea.customers.map(f => f.id);
            const customerIdPayload = customers.length > 0 ? customers.toString() : '';
            if(props.plan){
                const response = await fetchData(withUrl(`/masterPlan/checkMasterPlan/?planningAreaId=${planningAreaId}`).andToken(token), dispatch);
                if (response.isOk) {
                    setMasterPlanFlag(response.data)
                }
            }
            if(props.selectPlanDetailMasterPlan.length > 0){
                const response = await fetchData(withUrl(`/masterPlan/planId/${props.plan.id}`).andToken(token), dispatch);
                if(response.isOk){
                    response && response.data && response.data.map((mp) => {
                        if(!mp.deleted){
                            filterMasterPlanOptions.push({ value: mp.id, label: mp.name })
                        } 
                    })
                }
            }
            else if (facilitiesPayload) {
                const response = await fetchData(withUrl(`/masterPlan/pureMasterPlans?facilityIds=${facilitiesPayload}&planningAreaId=${planningAreaId}&customerIds=${customerIdPayload}`).andToken(token), dispatch);
                if (response.isOk) {
                    response && response.data.map((fmp) => {
                        filterMasterPlanOptions.push({ value: fmp.id, label: fmp.name })
                    });
                }
            }
          setResponse(filterMasterPlanOptions);
        };
        getMasterPlans();
    }, []);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setSelectedPlan(null);
    };

    const handleAssignPlanToMP = () => {
        props.assignMasterPlan(selectedPlan);
        props.closeMasterPlanDialog();
    }

    const handleConfirmDialog = () => {
        if (value === 'newMP') {
            props.createMasterPlan();
            props.closeMasterPlanDialog();
        } else if (value === 'assign') {
            handleAssignPlanToMP();
        }
    };

    const handleCloseDialog = () => {
        props.closeMasterPlanDialog();
    };
    const handleBackToListOfPlans = () => {
        props.history.push(PATHS.planList)
      }
    return (
        <MuiThemeProvider theme={muiTheme}>
            <Dialog
                open={props.masterPlanDialog}
                onBackdropClick={props.isAdmin ? props.closeMasterPlanDialog : undefined}
                PaperProps={{ style: { overflowY: 'visible', maxWidth: '400px' } }}
            >
                <DialogTitle><b>Create/Update Master Plan</b></DialogTitle>
                <DialogContent style={{ overflow: "visible" }}>
                    <RadioGroup value={value} onChange={handleRadioChange}>
                        <FormControlLabel disabled={masterPlanFlag} value="newMP" control={<Radio />} label="Create a new master plan out of the plan" />
                        {value === 'newMP' && facilityFlag && <WarningDiv>Plan must have atleast one Facility to create Master Plan</WarningDiv>}
                        {value === 'newMP' && customerFlag && <WarningDiv>Plan must have atleast one Customer to create Master Plan</WarningDiv>}
                        <FormControlLabel disabled={facilityFlag || customerFlag} value="assign" control={<Radio />} label="Assign the plan to existing master plan" />
                        {
                            value === 'assign' &&
                            <StyledSelect
                                style={{ height: '300px' }}
                                options={response}
                                onChange={option => {
                                    setSelectedPlan(option);
                                    sessionStorage.setItem('planIdToAddInMasterPlan', props.plan.id)
                                }}
                                value={selectedPlan}
                            />
                        }
                    </RadioGroup>

                </DialogContent>
                <DialogActions>
                    {!props.isAdmin && <BackToPlansButton onClick={handleBackToListOfPlans}>{'Back to list of Plans'}</BackToPlansButton>}
                    <ConfirmButton disabled={!value || customerFlag || facilityFlag || (value === 'assign' && !selectedPlan) } onClick={handleConfirmDialog}>Confirm</ConfirmButton>
                    {props.isAdmin && <CancelButton onClick={handleCloseDialog}>Cancel</CancelButton>}
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {},
            dispatch,
        ),
        dispatch,
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: `uploadModal`, saga });
export default compose(withConnect, withSaga)(MasterPlanDialog);
