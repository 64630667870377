import React, { Dispatch, SetStateAction } from 'react';
import Button from '@material-ui/core/Button';
import { FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';

import DialogDHL from 'components/Dialog';

import messages from './messages';

import ShiftDialogProportionally from '../ShiftDialog/ShiftDialogProportionally';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PlanOrAreaFormikPros = FormikProps<any>;

type ShiftDistributionWarningDialogProps = {
  stateHook: [boolean, Dispatch<SetStateAction<boolean>>];
  formikBag: PlanOrAreaFormikPros;
};

const createShiftRedistributionHandler = (
  formikBag: PlanOrAreaFormikPros,
  optionValue: string,
  stateSetter: Dispatch<SetStateAction<boolean>>,
) => () => {
  stateSetter(false);
  formikBag.setFieldValue('planningParameters.redistributeActivityTransformation', optionValue);
  formikBag.submitForm();
};

export const ShiftDistributionWarningDialog = (props: ShiftDistributionWarningDialogProps) => (
  <DialogDHL
    open={props.stateHook[0]}
    headerLabel={props?.formikBag?.values?.planningParameters?.transformationType === 'SHIFT' ? messages.shiftRedistribution : messages.wzpRedistribution}
    confirmLabel={messages.shiftRedistributionEqually}
    onCancel={() => props.stateHook[1](false)}
    onConfirm={createShiftRedistributionHandler(props.formikBag, 'EQUALLY', props.stateHook[1])}
    additionalButtons={
      <Button
        key="equally"
        color="primary"
        onClick={createShiftRedistributionHandler(props.formikBag, 'PROPORTIONALLY', props.stateHook[1])}
        variant="contained"
      >
        <FormattedMessage {...messages.shiftRedistributionProportionally} />
        <ShiftDialogProportionally/>
      </Button>
    }
  >
    {
      props?.formikBag?.values?.planningParameters?.transformationType === 'SHIFT' ?
        <FormattedMessage {...messages.shiftRedistributionDescription} /> :
        <FormattedMessage {...messages.wzpRedistributionDescription} />
    }
  </DialogDHL>
);
