/**
 *
 * WZPDefination
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import WZPAddline from 'components/ShiftAddLine/WZPAddline';
import WZPSettings from 'containers/ShiftSettings/WZPSettings';

/* eslint-disable react/prefer-stateless-function */
class WZPDefination extends React.PureComponent {
  render() {
    const { values, formik, isLoading, isEdit, shiftDaysRemovedWarning } = this.props;
    return (
      <div>
        <WZPAddline {...{ values, isEdit }} />
        <WZPSettings tableKey="shift-definition" labourCategory={false} formik={formik} isLoading={isLoading} shiftDaysRemovedWarning={shiftDaysRemovedWarning}/>
      </div>
    );
  }
}

WZPDefination.propTypes = {
  values: PropTypes.object,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};

export default WZPDefination;
