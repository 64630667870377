import get from 'lodash/get';
import { createSelector } from 'reselect';

import { getUomsForActivites, selectActivities, selectUOMS } from 'containers/PlanningAreaDetailPage/selectors';
import { T_TYPE } from 'utils/constants';

import { initialState } from './reducer';

/**
 * Direct selector to the planDetailPage state domain
 */

const selectPlanDetailPageDomain = state => state.get('masterPlanDetailPage', initialState);
const selectPeriodIndex = state => state.getIn(['guiState', 'periodIndex'], initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PlanDetailPage
 */

export const selectPlan = createSelector(selectPlanDetailPageDomain, substate => substate.get('masterPlan'));

export const selectPlanId = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['masterPlan', 'id']),
);

export const selectPlanningParameters = createSelector(
  selectPlan,
  masterPlan => masterPlan && masterPlan.planningParameters,
);

export const selectPlanningParametersId = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['masterPlan', 'planningParameters', 'id']),
);

export const selectPlanningAreaFromPlan = createSelector(
  selectPlan,
  masterPlan => masterPlan && masterPlan.planningArea,
);

export const selectOffDays = createSelector(
  selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['masterPlan', 'planningParameters', 'offDays']),
);
export const selectFirstday =
  (selectPlanDetailPageDomain,
  substate => substate && substate.getIn(['masterPlan', 'planningParameters', 'firstDayOfWeek']));

export const selectGuiState = createSelector(selectPlanDetailPageDomain, substate => substate.get('guiState'));
export const selectEditFromplan = createSelector(selectPlanDetailPageDomain, substate =>
  substate.getIn(['guiState', 'edit']),
);

export const selectPeriodIndexFromPlan = createSelector(selectPlanDetailPageDomain, selectPeriodIndex);

export const selectPeriodIdFromPlan = createSelector(
  selectPlanningParameters,
  selectPeriodIndexFromPlan,
  (pp, periodIndex) => pp && pp.periods && pp.periods[periodIndex] && pp.periods[periodIndex].id,
);

export const makeSelectLoadingFromPlan = () =>
  createSelector(selectPlanDetailPageDomain, substate => substate.getIn(['guiState', 'loading']));

export const makeSelectOpenedMhesFromPlan = () =>
  createSelector(selectPlanDetailPageDomain, substate => substate.get('activityIdsMheOpen').toJS());

export const makeSelectShiftCountFromPlan = () =>
  createSelector(selectPlanningParameters, selectPeriodIndexFromPlan, (pp, periodIndex) => {
    const shifts = pp && get(pp, `periods[${periodIndex}].realShiftsWithShift`);
    return (shifts && shifts.length) || 0;
  });

export const makeSelectIsShiftFromPlan = () =>
  createSelector(selectPlanningParameters, pp => ((pp && pp.transformationType) || T_TYPE.WZP) === T_TYPE.SHIFT);

export const selectWzpCountFromPlan = createSelector(
  selectPlanningParameters,
  selectPeriodIndexFromPlan,
  (pp, periodIndex) => {
    const wzp = pp && get(pp, `periods[${periodIndex}].realShiftsWithWZP`);
    return (wzp && wzp.length) || 0;
  },
);

export const selectPlanDepartments = createSelector(
  selectPlanningParameters,
  params => (params && params.departments) || [],
);

export const uomsForActitivies = createSelector(
  selectPlanningParameters,
  selectActivities,
  selectPeriodIndexFromPlan,
  (pp, activities, periodIndex) => {
    const aParams = pp && get(pp, `periods[${periodIndex}].activityParameters`);
    return getUomsForActivites(activities, aParams);
  },
);
