/*
 * MasterPlanMultipleReportRuns Messages
 *
 * This contains all the text for the MasterPlanMultipleReportRuns component.
 */

import {defineMessages} from 'react-intl';

export default defineMessages({
  uploadStartDate: {
    id: 'app.components.MasterPlanMultipleReportRuns.uploadStartDate',
    defaultMessage: 'Start date',
  },
  uploadEndDate: {
    id: 'app.components.MasterPlanMultipleReportRuns.uploadEndDate',
    defaultMessage: 'End date',
  },
  smartCodeTitle: {
    id: 'app.containers.MasterPlanDetailPage.smartCodeTitle',
    defaultMessage: 'Smart Code',
  },
  smartCodeMappingTitle: {
    id: 'app.containers.MasterPlanDetailPage.smartCodeMappingTitle',
    defaultMessage: 'Mapping',
  },
  smartCodeHeader: {
    id: 'app.containers.MasterPlanDetailPage.smartCodeHeader',
    defaultMessage: 'Smart Code Header ID',
  },
  smartCodeHeaderRemId: {
    id: 'app.containers.MasterPlanDetailPage.smartCodeHeaderRemId',
    defaultMessage: 'Smart Code Rem ID',
  },
  smartCodeHeaderID: {
    id: 'app.containers.MasterPlanDetailPage.smartCodeHeaderID',
    defaultMessage: 'SmartCode OMS Code',
  },
  smartProdJobHeader: {
    id: 'app.containers.MasterPlanDetailPage.smartProdJobHeader',
    defaultMessage: 'Smart Prod Job (Customer)',
  },
  smartProdJobOMSHeader: {
    id: 'app.containers.MasterPlanDetailPage.smartProdJobOMSHeader',
    defaultMessage: 'SmartProd OMS Code',
  },
  smartPlanOmsCodeHeader: {
    id: 'app.containers.MasterPlanDetailPage.smartPlanOmsCodeHeader',
    defaultMessage: 'SmartPlan OMS Code',
  },
  smartPlanActivityHeader: {
    id: 'app.containers.MasterPlanDetailPage.smartPlanActivityHeader',
    defaultMessage: 'SmartPlan Activity',
  },
  plansWhereExistHeader: {
    id: 'app.containers.MasterPlanDetailPage.plansWhereExistHeader',
    defaultMessage: 'Plans where exist',
  },
  pAreaWhereExist: {
    id: 'app.containers.MasterPlanDetailPage.pAreaWhereExist',
    defaultMessage: 'PA where exist',
  },
  pAreaBaseWhereExist: {
    id: 'app.containers.MasterPlanDetailPage.pAreaBaseWhereExist',
    defaultMessage: 'PA (Baseline) where exist',
  },
  jobCustomerPlan: {
    id: 'app.containers.MasterPlanDetailPage.jobCustomerPlan',
    defaultMessage: 'Plan JobCode Customer',
  },
  customerHeader: {
    id: 'app.containers.MasterPlanDetailPage.customerHeader',
    defaultMessage: 'Customer',
  },
  facilityHeader: {
    id: 'app.containers.MasterPlanDetailPage.facilityHeader',
    defaultMessage: 'Facility',
  },
  managementUnit: {
    id: 'app.containers.MasterPlanDetailPage.managementUnit',
    defaultMessage: 'Management Unit',
  },
  lastSynchronized: {
    id: 'app.containers.MasterPlanDetailPage.lastSynchronized',
    defaultMessage: 'Last Synchronized',
  },
  smartProdInstance: {
    id: 'app.containers.MasterPlanDetailPage.smartProdInstance',
    defaultMessage: 'SmartProd Instance',
  },
  smartProdWHID: {
    id: 'app.containers.MasterPlanDetailPage.smartProdWHID',
    defaultMessage: 'SmartProd WHID',
  },
  refresh: {
    id: 'app.containers.MasterPlanDetailPage.refresh',
    defaultMessage: 'Refresh',
  },
  showInSmartCode: {
    id: 'app.containers.MasterPlanDetailPage.showInSmartCode',
    defaultMessage: 'Show in SmartCode',
  },
});
