/* eslint-disable no-plusplus */
/**
 *
 * ActivitySettingsDirect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FastField, getIn } from 'formik';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import omit from 'lodash/omit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import uuid from 'uuid/v4';

import CheckboxCellRenderer from 'components/CheckboxCellRenderer';
import { isDayOfWeekTransformationActivity } from 'components/DayOfWeekTransformation';
import NameWithToolTip, { UOMWithToolTip } from 'components/NameWithToolTip';
import { groupByDepartmentProps } from 'components/Table/utils';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { selectUOMS } from 'containers/App/selectors';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { addPlanMHEOpenAction, addPlanShiftOpenAction, addPlanRoleOpenAction, deletePlanActivityLines } from 'containers/PlanDetailPage/actions';
import {
  makeSelectOpenedMhesFromPlan,
  makeSelectOpenedShiftsFromPlan,
  makeSelectOpenedRolesFromPlan,
  selectEditFromplan,
  selectPeriodIndexFromPlan,
  selectPlanDepartments,
  selectOpenedButtonFlag,
  selectPRPerShiftFromPlan
} from 'containers/PlanDetailPage/selectors';
import { addPaMHEOpenAction, addPaShiftOpenAction, addPaROleOpenAction, deletePaActivityLines } from 'containers/PlanningAreaDetailPage/actions';
import {
  selectAreaDepartments,
  selectEditFromPa,
  selectOpenedMhesFromPa,
  selectOpenedShiftsFromPa,
  selectOpenedRolesFromPa,
  selectPeriodIndexFromPa,
  selectOpenedButtonFlagFromPa,
  selectPRPerShiftFromPa,
} from 'containers/PlanningAreaDetailPage/selectors';
import TableControlled from 'containers/TableControlled';
import { checkVariables, formulaToText, textToFormulaActivity } from 'utils/formulas';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { numberCellFormatter, toNumber } from 'utils/utils';

import CustomerCellRenderer, { getCustomers } from '../CustomerCellRenderer';
import DeleteCellRenderer from '../DeleteCellRenderer';
import { cellEditDirtyMarker, SimpleHeader } from '../DetailPageShared';
import { DeleteDialog, withDeleteDialog } from '../Dialog';
import { isDaily } from '../PlannedVolumeTable/volume';
import DetailsButtons from './DetailsButtons';
import FormulaRenderer from './FormulaRenderer';
import FullWidthCellRenderer from './FullWidthCellRenderer';
import directMessages from './messages';
import DetailsButtonShifts from './DetailsButtonShifts';
import CustomCellRender from './CustomCellRender';
import DetailsButtonRoles from './DetailsButtonRoles';

const ErrorMessage = styled.div`
  color: ${props => props.theme.color.red};
`;

export const nameValueGetter = params => {
  if (params.data) {
    return `${params.data.activity.name} (${params.data.variableName})`;
  }
  return '';
};

export const nameValueFormatter = params => {
  if (!params.data) {
    return params.value;
  }
  return nameValueGetter(params);
};

export const departmentValueGetter = (self, allDepartmentsLabel) => params => {
  if (!self || !self.props) return '';
  const { departments } = self.props;
  if (!params.data) return null;
  const departmentId = params.data && params.data.departmentId;
  if (!departmentId) return allDepartmentsLabel;
  const department = find(departments, { id: departmentId });
  return (department && department.name) || departmentId;
};

function createDirectColumnDef(self, messages, isIndirect, customerOptions) {
  const { props } = self;
  const {
    intl: { formatMessage },
    viewSettings,
    productivityRatePerShiftFlag,
    formik
  } = props;
  const editable = props.edit;
  const isForecastEditor = props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  let columnDefs = [
    {
      headerName: formatMessage(messages.department),
      children: [{
        headerName: formatMessage(messages.department),
        valueGetter: departmentValueGetter(self, formatMessage(directMessages.allDepartments)),
        width: 160,
        field: 'departmentId',
        colId: 'departmentId',
        rowGroup: true,
        hide: true,
      }],
    },
  ];

  if (editable) {
    columnDefs.push({
      headerName: formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: payload => props.openDeleteDialog(props.deleteActivityLines, payload, payload.activity),
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
      headerComponentFramework: SimpleHeader,
    });
  }

  columnDefs = columnDefs.concat([
    {
      headerName: formatMessage(messages.activity),
      width: 160,
      field: 'customName',
      colId: 'customName',
      editable: false,
      menuTabs: ['filterMenuTab'],
      valueFormatter: nameValueFormatter,
      filterValueGetter: nameValueGetter,
      cellRendererFramework: NameWithToolTip('activity.regionalConfigurationName'),
      rowDrag: editable ? params => !params.node.group : false,
      sortable: false,
    },
  ]);

  columnDefs.push(
    {
      headerName: formatMessage(messages.uphDefault),
      width: 160,
      field: 'hourlyProductivityRate',
      colId: 'hourlyProductivityRate',
      valueFormatter: numberCellFormatter,
      editable: editable,
      menuTabs: ['filterMenuTab'],
      sortable: false,
    });

  if (isForecastEditor) {
    if (!isDayOfWeekTransformationActivity(props.formik)) {
      columnDefs = columnDefs.concat([
        {
          headerName: formatMessage(messages.isEffortForecastHour),
          width: 80,
          field: 'isEffortForecast',
          colId: 'isEffortForecast',
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: ({ value }) => {
            // value is forced to be true
            if (viewSettings && viewSettings.inputType === 'hours' && value === true) {
              return { editable: false };
            }
            // value is forced to be false
            if (viewSettings && viewSettings.inputType === 'volume' && value === false) {
              return { editable: false };
            }
            return { editable };
          },
          menuTabs: ['filterMenuTab'],
          sortable: false,
        },
      ]);
    }
    columnDefs = columnDefs.concat([
      {
        headerName: formatMessage(messages.omsCode),
        width: 160,
        field: 'activity.omsName',
        valueGetter: params => {
          if (params.data?.omsCode && params.data?.omsName) {
            return `${params.data?.omsCode} - ${params.data?.omsName}`
          }
          return (
            params.data &&
            params.data.activity &&
            params.data.activity.omsCode &&
            `${params.data.activity.omsCode} - ${params.data.activity.omsName}`
          )
        },
        colId: 'omsName',
        editable: false,
        menuTabs: ['filterMenuTab'],
        sortable: false,
      },
    ]);
  }
  columnDefs = columnDefs.concat([
    {
      headerName: formatMessage(messages.customerId),
      minWidth: 80,
      field: 'customerName',
      colId: 'customerName',
      editable,
      menuTabs: ['filterMenuTab'],
      valueFormatter: params => params.value && params.value.label,
      filterValueGetter: params => {
        return params.data && params.data.customerName;
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: customerOptions,
      },
      sortable: false,
    },
    {
      headerName: formatMessage(messages.uom),
      field: 'uomName',
      colId: 'uomName',
      editable,
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: UOMWithToolTip,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: params => params.value && `${params.value.name} / ${params.value.regionalConfigurationName}`,
      filterValueGetter: params => {
        return params.data && params.data.uomName;
      },
      cellEditorParams: {
        values: props.uoms,
      },
      sortable: false,
    },
    {
      headerName: formatMessage(messages.noOfEmployees),
      field: 'numberOfEmployees',
      type: 'numericColumn',
      colId: 'numberOfEmployees',
      menuTabs: ['filterMenuTab'],
      valueFormatter: numberCellFormatter,
      width: 100,
      sortable: false,
      editable,
    },
    {
      headerName: formatMessage(messages.formula),
      field: 'formulaText',
      colId: 'formulaText',
      menuTabs: ['filterMenuTab'],
      sortable: false,
      editable,
      cellRendererFramework: FormulaRenderer,
      cellRendererParams: {
        commentTitle: formatMessage(messages.comment),
        isDayOfWeekTransformationActivity: isDayOfWeekTransformationActivity(props.formik),
      },
    },
  ]);

  if (editable) {
    columnDefs.push({
      headerName: formatMessage(messages.comment),
      field: 'comment',
      colId: 'comment',
      menuTabs: ['filterMenuTab'],
      sortable: false,
      editable,
      cellEditor: 'agLargeTextCellEditor',
      valueGetter: params => {
        // Hide the comment is effort forecast is enabled
        if (params.data && !params.data.isEffortForecast) {
          return params.data.comment;
        }
        return '';
      },
      cellEditorParams: {
        maxLength: 300,
      },
      suppressKeyboardEvent: params => {
        const KEY_ENTER = 13;
        return params.editing && params.event.keyCode === KEY_ENTER;
      },
    });
  }
  if (viewSettings.checkboxMHE) {
    columnDefs.push({
      headerName: (productivityRatePerShiftFlag || viewSettings.checkboxRole) ? '' : formatMessage(messages.details),
      field: 'detailsButtons',
      colId: 'detailsButtons',
      cellRendererFramework: DetailsButtons,
      sortable: false,
      suppressMenu: true,
      cellRendererParams: {
        onClickHandler: props.addMHEOpenAction,
        editable,
      },
    });
  }
  if(productivityRatePerShiftFlag){
  columnDefs.push({
    headerName: viewSettings.checkboxRole ? '' : formatMessage(messages.details),
    field: 'detailsButtonsShift',
    colId: 'detailsButtonsShift',
    cellRendererFramework: DetailsButtonShifts,
    sortable: false,
    suppressMenu: true,
    cellRendererParams: {
      onClickHandler: props.addShiftOpenAction,
      editable,
    },
  })
}
if(viewSettings.checkboxRole){
  columnDefs.push({
    headerName: formatMessage(messages.details),
    field: 'detailsButtonsRole',
    colId: 'detailsButtonsRole',
    cellRendererFramework: DetailsButtonRoles,
    sortable: false,
    suppressMenu: true,
    cellRendererParams: {
      onClickHandler: props.addRoleOpenAction,
      editable,
    },
  })
}
  return { columnDefs };
}

const isFullWidthCell = rowNode => (rowNode.data && rowNode.data.fullWidth) || false;

const getRowHeight = props => params => {
  // you can have normal rows and full width rows any height that you want
  const isBodyRow = params.node.rowPinned === undefined;
  const isFullWidth = (params.node.data && params.node.data.fullWidth) || false;
  if (isBodyRow && isFullWidth) {
    const items = params.node.data.mhes || [];
    const itemsShifts = params.node.data.shifts || [];
    return (props.edit ? 120 : 70) + (((items.length > 0 ? items.length : itemsShifts.length) || 1) + 1) * 40;
  }
  return 35;
};

const Wrap = styled.div`
  height: ${props => props.height}px;

  .ag-react-container {
    width: 100%;
  }

  > span {
    line-height: 39px;
  }
`;

const Table = styled(TableControlled)`
  height: ${props => props.height}px;
`;

/* eslint-disable react/prefer-stateless-function */
export const ActivitySettings = (createColumnDef, isIndirect, messages) => {
  class ActivitySettingsInner extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        redrawTable: false,
      };
      this.rowData = [];
    }

    activitiesVariableNames = props => {
      const vars = {};
      const {
        intl: { formatMessage },
      } = this.props;

      getIn(props.formik.values, `planningParameters.periods.${props.periodIndex}.apCalculated.indirect`, []).forEach(
        it => {
          vars[it.variableName] = formatMessage(directMessages.activityLabel, { name: it.activity && it.activity.name });
        },
      );
      getIn(props.formik.values, `planningParameters.periods.${props.periodIndex}.apCalculated.direct`, []).forEach(
        it => {
          vars[it.variableName] = formatMessage(directMessages.activityLabel, { name: it.activity && it.activity.name });
        },
      );
      return vars;
    };

    volumeCategoryVariableNames = props => {
      const {
        intl: { formatMessage },
      } = this.props;
      const vars = {};
      const dataKey = isDaily(props) ? 'dailyRowData' : 'rowData';
      ['', 'Var'].forEach(postFix => {
        getIn(props.formik.values, `planningParameters.volumeCategoryParameters.${dataKey}${postFix}`, []).forEach(
          it => {
            vars[it.volumeCategory.variableName] = formatMessage(directMessages.vcLabel, {
              name: it.volumeCategory.name,
            });
          },
        );
      });
      return vars;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext): boolean {
      const thisValues = this.props.formik.values;
      const nextValues = nextProps.formik.values;
      if(!isEqual(this.props.productivityRatePerShiftFlag, nextProps.productivityRatePerShiftFlag)){
        return true;
      }
      const transformationChanged =
        isDayOfWeekTransformationActivity(this.props.formik) !== isDayOfWeekTransformationActivity(nextProps.formik);
        if(!isEqual(this.props.viewSettings !== nextProps.viewSettings)){
          return true;
        }
      return (
        this.props.edit !== nextProps.edit ||
        this.props.deleteDialogOpen !== nextProps.deleteDialogOpen ||
        this.props.viewSettings !== nextProps.viewSettings ||
        !isEqual(this.props.activityIdsMheOpen, nextProps.activityIdsMheOpen) ||
        !isEqual(this.props.activityIdsShiftOpen, nextProps.activityIdsShiftOpen) ||
        !isEqual(this.props.activityIdsRoleOpen, nextProps.activityIdsRoleOpen) ||
        !isEqual(thisValues.planningParameters.periods, nextValues.planningParameters.periods) ||
        this.props.periodIndex !== nextProps.periodIndex ||
        transformationChanged
      );
    }

    tableKey(props) {
      return `${props.edit}-${props.deleteDialogOpen}-${props.activityIdsMheOpen}-${props.activityIdsShiftOpen}-${props.activityIdsRoleOpen}-${
        props.periodIndex
      }-${isDayOfWeekTransformationActivity(props.formik)}`;
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.formik.initialValues !== this.props.formik.initialValues) {
        const oldVars = isIndirect
          ? this.activitiesVariableNames(prevProps)
          : { ...this.volumeCategoryVariableNames(prevProps), ...this.activitiesVariableNames(prevProps) };
        const vars = isIndirect
          ? this.activitiesVariableNames(this.props)
          : { ...this.volumeCategoryVariableNames(this.props), ...this.activitiesVariableNames(this.props) };

        // forcing fast field to rerender is variables names has been changed
        if (JSON.stringify(oldVars) !== JSON.stringify(vars)) {
          this.setState({ redrawTable: uuid() });
        }
      } else if (!isEqual(this.props.viewSettings, prevProps.viewSettings)) {
        this.setState({ redrawTable: uuid() });
      }
    }

    validateFormula(selfVar, formula, variableNames) {
      const {
        intl: { formatMessage },
        formik,
      } = this.props;
      if (!variableNames) return;
      const variablesOk = checkVariables(formula, variableNames, formatMessage, directMessages, selfVar);
      if (variablesOk !== true) {
        const errorMessage = formatMessage(directMessages.warningMessage, { warning: variablesOk });
        return errorMessage;
      }
      return false;
    }

    isActivitesValid = errors => {
      const { formik } = this.props;
      const path = this.getFormikDataPath();
      let errorMessage;
      const errNum = errors.length;
      if (errNum > 0) {
        formik.setFieldError(path, `${errNum} errors`);
      } else {
        const error = getIn(formik.errors, path);
        if (error) {
          const newErrors = omit(cloneDeep(formik.errors), path.split('.'));
          formik.setErrors(newErrors);
        }
      }
      return errorMessage;
    };

    render() {
      const {
        intl: { formatMessage },
        activityIdsMheOpen,
        activityIdsShiftOpen,
        activityIdsRoleOpen,
        buttonOpenedFlag,
        edit,
        periodIndex,
        productivityRatePerShiftFlag
      } = this.props;
      const { redrawTable } = this.state;
      const tableKey = `${redrawTable}_${edit}_${periodIndex}_${productivityRatePerShiftFlag}_${(activityIdsMheOpen || []).join('-')}_${(activityIdsShiftOpen || []).join('-')}_${(activityIdsRoleOpen|| []).join('-')}}`;
      return (
        <>
          <FastField
            name={this.getFormikDataPath()}
            key={tableKey}
            render={({ field, form }) => this.renderTable(field, form, formatMessage)}
          />
          <DeleteDialog {...this.props} text={directMessages.dialogDeleteText} />
        </>
      );
    }

    getTableConfig = () => {
      const isForecastEditor = this.props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
      let tableConfig;
      if (isIndirect) {
        tableConfig = this.props.edit
          ? TABLE_DEFAULTS.activityIndirectEditTableConfig
          : TABLE_DEFAULTS.activityIndirectTableConfig;
      } else {
        tableConfig = this.props.edit
          ? TABLE_DEFAULTS.activityDirectEditTableConfig
          : TABLE_DEFAULTS.activityDirectTableConfig;
      }
      return {
        ...tableConfig,
        columnState: tableConfig.columnState.filter(item => {
          if (!isForecastEditor) {
            if (item.colId === 'isEffortForecast' || item.colId === 'omsName') {
              return false;
            }
          }
          return true;
        }),
      };
    };

    getTableName = () => {
      if (isIndirect) {
        return this.props.edit ? 'activitiesIndirectEdit' : 'activitiesIndirect';
      }
      return this.props.edit ? 'acitvitiesDirectEdit' : 'acitvitiesDirect';
    };

    renderTable = (field, form, formatMessage) => {
      const vn = isIndirect
        ? this.activitiesVariableNames(this.props)
        : { ...this.volumeCategoryVariableNames(this.props), ...this.activitiesVariableNames(this.props) };

      const { formik, viewSettings } = this.props;
      let customerOptions = getCustomers(formik);
      customerOptions = (customerOptions && customerOptions.map(c => ({ ...c, label: c.name, value: c.id }))) || [];
      customerOptions.unshift({ id: null, label: formatMessage(messages.all), value: null });
      const { columnDefs } = createColumnDef(this, messages, isIndirect, customerOptions);
      const dataPath = this.getFormikDataPath();
      const warnings = {};
      const errors = [];
      this.rowData = field.value
        ? cloneDeep(field.value)
            .sort((a, b) => a.order - b.order)
            .map((row, index) => {
              const warning = this.validateFormula(row.variableName, JSON.parse(row.formula), vn);
              const errKey = `${dataPath}.${index}.formula`;
              const formulaArray = formulaToText(JSON.parse(row.formula), vn);
              let formulaText = '';
              formulaArray.forEach(a => {
                formulaText += `${(typeof a === 'object' && a.text) || a}`;
              });
              if (warning && !row.isEffortForecast) {
                warnings[errKey] = warning;
              }
              if (row.parseError) {
                errors.push(row.parseError);
              }
              const getCustomerName = (row) => {
                if (row.customerId && typeof row.customerId === 'object') return row.customerId;
                const customerDetails = find(customerOptions, { value: row.customerId });
                if(customerDetails === undefined) return 'All';
                return customerDetails && customerDetails.name;
              };
              const getActivityName = (row) => {
                if(!row.activity) return '';
                return `${row && row.activity && row.activity.name} (${row && row.variableName})`
              }
              const customerName = getCustomerName(row);
              const activityName = getActivityName(row);
              return {
                ...row,
                formulaArray,
                customerName,
                customName: activityName,
                uomName: row.uom && row.uom.name,
                formulaText: row.isEffortForecast ? '': row.formulaText || formulaText,
                parseError: row.parseError || false,
                err: (row.formula && warning) || row.parseError || false,
                detailsButtons: true,
                isMHEOpen: this.props.activityIdsMheOpen && this.props.activityIdsMheOpen.includes(row.id),
                isShiftOpen: this.props.activityIdsShiftOpen && this.props.activityIdsShiftOpen.includes(row.id),
                isRoleOpen: this.props.activityIdsRoleOpen && this.props.activityIdsRoleOpen.includes(row.id),
                viewSettings: viewSettings,
              };
            })
        : [];
      this.isActivitesValid(errors);
      const uniqueDepartments = [...new Set( this.rowData && this.rowData.map(obj => obj.departmentId)) ] || [];
      const totalRows = this.rowData.length;
      let details = 0;
      let detailItems = 0;
      let detailsShift = 0;
      let detailItemsShift = 0;
      const rowDataWithMhe = [];
      this.rowData.forEach(item => {
        // eslint-disable-line
        rowDataWithMhe.push(item);
        if (item && item.isMHEOpen) {
          details += 1;
          detailItems += (item.mhes || []).length || 2;
          rowDataWithMhe.push({
            fullWidth: true,
            mhes: item.mhes,
            editable: this.props.edit,
            activityId: item.activity.id,
            id: item.id,
            activityIndex: item.index,
            departmentId: item.departmentId,
            viewSettings: viewSettings,
          });
        }
        if (item && item.isShiftOpen) {
          details += 1;
          detailItems += (item.mhes || []).length || 2;
          rowDataWithMhe.push({
            fullWidth: true,
            shifts: item.perShift,
            editable: this.props.edit,
            activityId: item.activity.id,
            id: item.id,
            activityIndex: item.index,
            departmentId: item.departmentId,
            viewSettings: viewSettings,
          });
        }
        if (item && item.isRoleOpen) {
          details += 1;
          detailItems += (item.mhes || []).length || 2;
          rowDataWithMhe.push({
            fullWidth: true,
            roles: item.roles,
            editable: this.props.edit,
            activityId: item.activity.id,
            id: item.id,
            activityIndex: item.index,
            departmentId: item.departmentId,
            viewSettings: viewSettings,
          });
        }
      });
      this.rowData = rowDataWithMhe;
      if (this.api) {
        this.api.updateRowData(this.rowData);
        this.api.refreshCells({ force: true });
      }
      return (
        <>
          {isIndirect && <FormattedMessage {...messages.InDirectheader} />}
          <Table
            key={this.tableKey(this.props)}
            showCollapseButtonsInsideTable
            defaultConfig={this.getTableConfig()}
            columnDefs={columnDefs}
            rowData={rowDataWithMhe}
            messages={messages}
            name={this.getTableName()}
            domLayout="autoHeight"
            pagination={false}
            getRowNodeId={data => (data.fullWidth ? -data.id : data.id)}
            deltaRowDataMode
            onCellValueChanged={params => {
              this.onCellValueChanged(params, form);
            }}
            fullWidthCellRendererFramework={FullWidthCellRenderer}
            fullWidthCellRendererParams={{
              token: this.props.token,
              formik: form,
              periodIndex: this.props.periodIndex,
              isIndirect,
              buttonDetails: this.props,
              roles: this.props.roles
            }}
            isFullWidthCell={isFullWidthCell}
            getRowHeight={getRowHeight(this.props)}
            singleClickEdit
            stopEditingWhenGridLosesFocus
            {...cellEditDirtyMarker(form.setFieldValue)}
            // Grouping
            {...groupByDepartmentProps(
              formatMessage,
              directMessages,
              departmentValueGetter(this, formatMessage(directMessages.allDepartments)),
            )}
            animateRows
            onRowDragMove={this.onRowDragMove}
            onRowDragEnd={event => this.onRowDragEnd(event, form)}
            onGridReady={params => {
              params.api.sizeColumnsToFit();
              this.api = params.api;
            }}
          />
          {warnings && (
            <Wrap>
              {Object.keys(warnings).map(key => (
                <ErrorMessage key={key}>{warnings[key]}</ErrorMessage>
              ))}
            </Wrap>
          )}
          {errors && (
            <Wrap>
              {errors.map((err, index) => (
                <ErrorMessage key={`err${index}`}>{err}</ErrorMessage>
              ))}
            </Wrap>
          )}
        </>
      );
    };

    onRowDragMove = event => {
      const movingNode = event.node;
      const movingData = movingNode.data;
      const { overNode } = event;
      const rowNeedsToMove = !(overNode.data && overNode.data.fullWidth) && movingNode !== overNode;
      if (rowNeedsToMove) {
        const departmentId = overNode.group ? overNode.key : overNode.data.departmentId;
        const needToChangeParent = movingNode.departmentId !== departmentId;
        if (needToChangeParent) {
          movingData.departmentId = departmentId;
        }
        const fromIndex = this.rowData.indexOf(movingData);
        const toIndex = this.rowData.indexOf(overNode.data);
        const newRowData = [...this.rowData];
        const element = newRowData[fromIndex];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, element);

        this.rowData = newRowData;
        event.api.setRowData(newRowData);
        event.api.refreshClientSideRowModel('group');
        event.api.clearFocusedCell();
      }
    };

    onRowDragEnd = (event, formik) => {
      const movingNode = event.node;
      const { overNode } = event;
      const departmentId = overNode.group ? overNode.key : overNode.data.departmentId;
      const needToChangeParent = movingNode.departmentId !== departmentId;
      if (needToChangeParent) {
        const movingData = movingNode.data;
        const fieldPath = [...this.getFormikDataPath().split('.'), movingData.index, 'departmentId'];
        formik.setFieldValue(fieldPath, isNaN(departmentId) ? null : departmentId);
      }
      this.updateOrderFlagsInFormikIfNeeded(formik);
    };

    updateOrderFlagsInFormikIfNeeded = formik => {
      const maxOrderPerDepartmentId = {};
      const departmentIdsToBeRenumbered = [];

      this.rowData
        .filter(r => !r.fullWidth)
        .forEach(row => {
          const depId = row.departmentId || -1; // -1 for null = all deps
          if (!departmentIdsToBeRenumbered.includes(depId)) {
            const maxOrder = maxOrderPerDepartmentId[depId];
            if (maxOrder) {
              // we already had some rows, check that current order number is higher (= no reordering needed)
              if (row.order > maxOrder) {
                // all ok, just move the max value
                maxOrderPerDepartmentId[depId] = row.order;
              } else {
                departmentIdsToBeRenumbered.push(depId);
              }
            } else {
              maxOrderPerDepartmentId[depId] = row.order;
            }
          }
        });
      // now do the reordering
      if (departmentIdsToBeRenumbered.length > 0) {
        const currentOrderPerDepId = {};
        departmentIdsToBeRenumbered.forEach(depId => {
          currentOrderPerDepId[depId] = 1;
        });
        this.rowData
          .filter(r => !r.fullWidth)
          .forEach((row, index) => {
            const depId = row.departmentId || -1; // -1 for null = all deps
            const currentOrder = currentOrderPerDepId[depId];
            if (currentOrder) {
              // value exists = this dep is in reordering scope
              if (row.order !== currentOrder) {
                // current rowdata change - until next render
                this.rowData[index].order = currentOrder;
              }
              currentOrderPerDepId[depId] = currentOrder + 1;
            }
          });
        // formik change
        const formikData = this.gridDataToFormikData();
        formik.setFieldValue(this.getFormikDataPath(), formikData);
      }
    };

    gridDataToFormikData = () =>
      this.rowData
        .filter(row => !row.fullWidth)
        .map(row => {
          // remove visual fields added in render
          const { detailsButtons, isMHEOpen, ...trueData } = row;
          return { ...trueData };
        })
        .sort((a, b) => a.index - b.index);

    onCellValueChanged = (params, form) => {
      const {
        intl: { formatMessage },
      } = this.props;
      if (form.setFieldValue) {
        if (params.colDef.field === 'formulaText') {
          const dataPath = this.getFormikDataPath().split('.');
          const pathFormula = [...dataPath, params.data.index, 'formula'];
          const pathError = [...dataPath, params.data.index, 'parseError'];
          const pathFormulaText = [...dataPath, params.data.index, 'formulaText'];
          const errPath = pathFormula.join('.');
          try {
            const parsed = textToFormulaActivity(params.newValue.trim());
            form.setFieldValue(pathError, false);
            form.setFieldValue(pathFormula, JSON.stringify({ r: parsed }));
            form.setFieldValue(pathFormulaText, params.newValue);
          } catch (err) {
            const errorMessage = formatMessage(directMessages.errorMessage, { error: err.message });
            form.setFieldValue(pathError, errorMessage);
            form.setFieldValue(pathFormulaText, params.newValue);
          }
        } else {
          const fieldToUpdate = params.colDef.field === 'uomName' ? 'uom' : params.colDef.field === 'customerName' ? 'customerId' : params.colDef.field;
          const converted =
            params.colDef.field === 'numberOfEmployees' || params.colDef.field === 'hourlyProductivityRate'
              ? toNumber(params.newValue)
              : params.newValue;
          form.setFieldValue(
            [...this.getFormikDataPath().split('.'), params.data.index, fieldToUpdate],
            converted,
          );
        }
      } else {
        console.warn('ActivitySettingsDirect setFieldValue property not set, cannot propagate value update', params);
      }
    };

    getFormikDataPath = () =>
      `planningParameters.periods.${this.props.periodIndex}.apCalculated.${isIndirect ? 'indirect' : 'direct'}`;
  }

  ActivitySettingsInner.propTypes = {
    intl: PropTypes.object,
    edit: PropTypes.bool,
    deleteActivityLines: PropTypes.func,
    messages: PropTypes.object,
    createColumnDef: PropTypes.func,
    setFieldValue: PropTypes.func,
    formik: PropTypes.object,
    token: PropTypes.string,
    activityIdsMheOpen: PropTypes.array,
    periodIndex: PropTypes.number,
  };
  return withSecurity()(ActivitySettingsInner);
};

ActivitySettings.propTypes = {
  intl: PropTypes.object,
  edit: PropTypes.bool,
  deleteActivityLines: PropTypes.func,
  messages: PropTypes.object,
  formik: PropTypes.object,
  createColumnDef: PropTypes.func,
  token: PropTypes.string,
  activityIdsMheOpen: PropTypes.array,
  periodIndex: PropTypes.number,
};

// Plan
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  token: makeSelectToken(),
  uoms: selectUOMS,
  activityIdsMheOpen: makeSelectOpenedMhesFromPlan(),
  activityIdsShiftOpen: makeSelectOpenedShiftsFromPlan(),
  activityIdsRoleOpen: makeSelectOpenedRolesFromPlan(),
  buttonOpenedFlag: selectOpenedButtonFlag,
  periodIndex: selectPeriodIndexFromPlan,
  departments: selectPlanDepartments,
  viewSettings: selectViewModeStoredData,
  productivityRatePerShiftFlag: selectPRPerShiftFromPlan,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteActivityLines: deletePlanActivityLines,
      addMHEOpenAction: addPlanMHEOpenAction,
      addShiftOpenAction: addPlanShiftOpenAction,
      addRoleOpenAction: addPlanRoleOpenAction
    },
    dispatch,
  );
}

const withPlanConnect = connect(mapPlanStateToProps, mapPlanDispatchToProps);

// Planning area
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  token: makeSelectToken(),
  activityIdsMheOpen: selectOpenedMhesFromPa,
  activityIdsShiftOpen: selectOpenedShiftsFromPa,
  activityIdsRoleOpen: selectOpenedRolesFromPa,
  buttonOpenedFlag: selectOpenedButtonFlagFromPa,
  uoms: selectUOMS,
  periodIndex: selectPeriodIndexFromPa,
  departments: selectAreaDepartments,
  viewSettings: selectViewModeStoredData,
  productivityRatePerShiftFlag: selectPRPerShiftFromPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteActivityLines: deletePaActivityLines,
      addMHEOpenAction: addPaMHEOpenAction,
      addShiftOpenAction: addPaShiftOpenAction,
      addRoleOpenAction: addPaROleOpenAction
    },
    dispatch,
  );
}

const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export default injectIntl(
  withPlanConnect(withDeleteDialog(ActivitySettings(createDirectColumnDef, false, directMessages))),
);
export const PaActivitySettingsDirect = injectIntl(
  withPaConnect(withDeleteDialog(ActivitySettings(createDirectColumnDef, false, directMessages))),
);
