/**
 *
 * DayToWzpTransformation
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import { getIn, connect as formikConnect } from 'formik';
import { numericSetter, toNumber, percentageCellFormatterNull as percentageFormatter } from 'utils/utils';
import { groupByDepartmentProps } from 'components/Table/utils';
import CustomerCellRenderer, { customerFilterParams } from 'components/CustomerCellRenderer';
import { makeSelectRunningApiCalls } from 'containers/App/selectors';

import styled from 'styled-components';
import injectReducer from 'utils/injectReducer';

import {
  selectEditFromPa,
  selectFirstdayPA,
  selectOffDaysPA,
  selectPeriodIndexFromPa,
  selectIsShiftFromPa,
} from 'containers/PlanningAreaDetailPage/selectors';

import {
  DayTransformationButtonsWrap,
  DayTransformationToggleButtonStyled,
  DayTransformationCopyButtonWrapper,
  DayTransformationCopyButtonStyled,
} from 'containers/DayToHourTransformation';
import {
  selectEditFromplan,
  selectFirstday,
  selectOffDays,
  selectPeriodIndexFromPlan,
  makeSelectIsShiftFromPlan,
} from 'containers/PlanDetailPage/selectors';
import TableControlled from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';

import { DAY_NAMES } from 'utils/calendar/constants';

import { DeleteDialog, withDeleteDialog } from 'components/Dialog';
import { IndirectValueGetter } from 'components/ListGrid/utils';

import calendarMessages from 'utils/calendar/messages';
import { checksumPercentageFormatter, checkSumValidationStyle, makeChecksumGetterByFilter } from 'utils/checksum';

import NameWithToolTip, { UOMNameWithToolTip } from 'components/NameWithToolTip';
import { disableOffDays, sortByWeekStart } from './utils';
import { copyDay, fixHeaders } from './helpers/dayToWzp';
import { toggleDayAction } from './actions';
import makeSelectDayToWzpTransformation from './selectors';
import reducer from './reducer';
import messages from './messages';

const Wrap = styled.div`
  .validation-error {
    color: ${props => props.theme.color.white};
    font-weight: bold;
    background-color: ${props => props.theme.color.red};
  }
   .MuiBox-root {
   padding-top : 5px !important;
   } 
`;

const Header = styled.div`
  font-weight: bold;
  padding-top: 25px;
  padding-bottom: 5px;
`

const TableWithHeight = styled(TableControlled)`
  height: ${props => props.height}px;
`;

const departmentValueGetter = formatMessage => params => {
  if (params.data) {
    return params.data.department ? params.data.department.name : formatMessage(messages.allDepartments);
  }
  return null;
};

class DayToWzpTransformation extends React.Component {
  copyDayHandler = (fieldName, fromToDays) => {
    this.props.openDeleteDialog(() => {
      const newValue = copyDay(cloneDeep(getIn(this.props.formik.values, fieldName)), fromToDays);
      this.props.formik.setFieldValue(fieldName, newValue);
    }, fromToDays);
  };

  componentDidMount() {
    disableOffDays(this.props.offdays, this.props.toggleDayAction);
  }

  componentDidUpdate(prevProps) {
    const { offdays } = this.props;
    const { offdays: prevOffDays } = prevProps;
    if (prevOffDays !== offdays) {
      disableOffDays(this.props.offdays, this.props.toggleDayAction);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const buildGuiStateKey = props => {
      const key = [];
      key.push(props.edit);
      key.push(props.selectedDays.join(':'));
      key.push(props.deleteDialogOpen);
      key.push(props.periodIndex);
      key.push(props.firstDay);
      key.push(props.offDays ? props.offDays.join(':') : '');
      key.push(getIn(props.formik, 'values.reloadCount', 0));
      key.push(props.isLoading)
      return key.join('-');
    };
    const shouldUpdate = buildGuiStateKey(this.props) !== buildGuiStateKey(nextProps);
    // console.log('DayToHoutTrans SCU', shouldUpdate);
    // console.log(nextProps);
    return shouldUpdate;
  }

  render() {
    const index = this.props.periodIndex;
    const {
      selectedDays,
      intl: { formatMessage },
      isShift,
    } = this.props;
    const dataField = `planningParameters.periods.${index}.apCalculated.wzpTransitions`;

    const transitions = getIn(this.props.formik.values, dataField);
    if (!transitions) {
      return <span />;
    }
    const { headers } = transitions;
    // agGrid IS MUTATING row data - without cloneDeep formik's initial data gets overridden on paste
    const rowData = cloneDeep(transitions.rowData);
    const values = [ 'STAFF_UNPRODUCTIVE', 'LOCATION_UNPRODUCTIVE' ];
    const filteredRowData = rowData && rowData.filter(item => item && item.activity && item.activity.productivityType && !values.includes(item.activity.productivityType));
    filteredRowData && filteredRowData.forEach((fd) => {
      const activityName = fd && fd.activity && fd.activity.name;
      const variableName = fd && fd.variableName
      const uomName = fd && fd.uom && fd.uom.name;
      fd.activityName = `${activityName} (${variableName})`;
      fd.uomName = uomName;
    })
    const totalRows = filteredRowData.length;
    const aproxHeight = Math.min(totalRows || 1, 15) * 12 + 150;
    return (
      <Wrap>
        <Header>{isShift ? <FormattedMessage {...messages.headerShift} /> : <FormattedMessage {...messages.header} />}</Header>
        <div>
          <DayTransformationButtonsWrap>
            {this.renderDaySwitchButtons((...args) => this.copyDayHandler(dataField, ...args))}
          </DayTransformationButtonsWrap>
          <TableWithHeight
            showCollapseButtonsInsideTable
            defaultConfig={this.props.edit ? TABLE_DEFAULTS.dayToWzpEditConfig : TABLE_DEFAULTS.dayToWzpConfig}
            showCOG={false}
            name={this.props.edit ? 'dayToWzpEdit' : 'dayToWzp'}
            columnDefs={this.createColumnsDef(headers, this.props.edit, selectedDays, isShift)}
            rowData={filteredRowData}
            height={aproxHeight}
            onGridReady={() => {
              // do nothing - suppress params.api.sizeColumnsToFit();
            }}
            getRowNodeId={data => data.id}
            deltaRowDataMode
            onCellValueChanged={this.props.edit ? this.onCellValueChanged : null}
            pagination={false}
            onPaste={this.props.edit ? this.onPaste : null}
            // Grouping
            {...groupByDepartmentProps(formatMessage, messages, departmentValueGetter(formatMessage))}
          />
        </div>
        <DeleteDialog
          {...this.props}
          text={messages.dayCopyConfirmationText}
          title={messages.dayCopyConfirmationTitle}
          confirmLabel={messages.dayCopyConfirmationYes}
        />
      </Wrap>
    );
  }

  renderDaySwitchButtons(copyDayHandler) {
    const {
      isShift,
      intl: { formatMessage },
    } = this.props;
    const showCopyToButtons = !isShift && this.props.selectedDays.length === 1;

    const selectedDay = this.props.selectedDays[0];
    return sortByWeekStart(DAY_NAMES, this.props.firstDay).map(dayName => {
      const handler = e => {
        e.stopPropagation();
        copyDayHandler({ dayFrom: selectedDay, dayTo: dayName });
      };
      const isDaySelected = this.props.selectedDays.includes(dayName);
      return (
        <DayTransformationToggleButtonStyled
          key={dayName}
          value={isDaySelected}
          onClick={() => {
            this.props.toggleDayAction(dayName);
          }}
        >
          {showCopyToButtons && !isDaySelected && this.props.edit
            ? this.renderDayCopyToButton(selectedDay, handler)
            : null}
          {dayName && formatMessage(calendarMessages[dayName.toLowerCase()])}
        </DayTransformationToggleButtonStyled>
      );
    });
  }

  renderDayCopyToButton(dayTo, onClickHandler) {
    return (
      <DayTransformationCopyButtonWrapper>
        <DayTransformationCopyButtonStyled key={dayTo} onClick={onClickHandler}>
          <FontAwesomeIcon icon="copy" />
        </DayTransformationCopyButtonStyled>
      </DayTransformationCopyButtonWrapper>
    );
  }

  createColumnsDef(headers, editable, selectedDays, isShift) {
    const getFormikDataPath = () => `planningParameters.periods.${this.props.periodIndex}.realShiftsWithShift`;
    const tableKey = 'shift-volume';
    const form = this.props.formik;
    const value = getIn(form.values, getFormikDataPath());
    let rowDataShift = [];
    const concatDays = (shifts) => {
      const days = [];
      shifts?.forEach((s)=> days.push(...s.days));
      return [...new Set(days)];
    };
    if (value) {
      if (tableKey === 'shift-volume') {
        rowDataShift = value.map(row => (
          {
          name: row.name,
          days: concatDays(row.shifts),
        }));
      } else {
        rowDataShift = cloneDeep(value);
      }
    }
    const headersLocal = fixHeaders(headers);
    rowDataShift && rowDataShift.forEach(mItem => {
      headersLocal && headersLocal.forEach(sItem => {
              if(mItem.days.includes(sItem.label)){
                sItem.children.forEach((cItem)=>{
                if(cItem.label === mItem.name || cItem?.canEdit){
                  cItem.editable = editable;
                }
               })
              }
          });
      });
    const {
      intl,
      formik: {
        values: { planningArea, customers },
      },
    } = this.props;
    const cList = customers === undefined ? planningArea.customers : customers;

    return [
      {
        headerName: '',
        children: [{
          width: 100,
          headerName: '',
          colId: 'department',
          pinned: true,
          valueGetter: departmentValueGetter(intl.formatMessage),
          hide: true,
          rowGroup: true,
        }]
      },
      {
        headerName: intl.formatMessage(messages.activity),
        width: 160,
        colId: 'activityName',
        cellRendererFramework: NameWithToolTip('activity.regionalConfigurationName'),
        menuTabs: ['filterMenuTab'],
        pinned: true,
        field: 'activityName',
      },
      {
        headerName: intl.formatMessage(messages.customerId),
        width: 100,
        colId: 'customerId',
        field: 'customerId',
        menuTabs: ['filterMenuTab'],
        filterParams: customerFilterParams(cList, intl.formatMessage),
        cellRendererFramework: CustomerCellRenderer(cList),
      },
      {
        headerName: intl.formatMessage(messages.uom),
        width: 100,
        colId: 'uomName',
        field: 'uomName',
        menuTabs: ['filterMenuTab'],
        cellRendererFramework: UOMNameWithToolTip,
      },
      {
        headerName: this.props.intl.formatMessage(messages.indirect),
        field: 'activity.indirect',
        colId: 'activity.indirect',
        valueGetter: IndirectValueGetter,
        width: 100,
        pinned: true,
      },
      ...headersLocal
        .filter(day => selectedDays.includes(day.label))
        .map(day => ({
          headerName: intl.formatMessage(calendarMessages[day.label.toLowerCase()]),
          children: [
            ...day.children.map(wzp => ({
              headerName: wzp.label,
              headerTooltip: wzp.label,
              field: wzp.field,
              colId: `${day.label}_${wzp.field}`,
              valueFormatter: percentageFormatter,
              valueSetter: numericSetter,
              width: 120,
              hide: !wzp.canEdit,
              editable: isShift ? wzp.editable : (wzp.canEdit && editable),
            })),
            {
              headerName: this.props.intl.formatMessage(messages.checkSum),
              colId: `${day.label}_checksum`,
              valueFormatter: checksumPercentageFormatter,
              valueGetter: makeChecksumGetterByFilter(key => key.startsWith(day.label)),
              cellStyle: checkSumValidationStyle,
              width: 140,
            },
          ],
        })),
    ];
  }

  onCellValueChanged = params => {
    const { setFieldValue } = this.props.formik;
    const index = this.props.periodIndex;
    if (setFieldValue) {
      const fieldName = `planningParameters.periods.${index}.apCalculated.wzpTransitions.rowData.${params.data.index}.${
        params.colDef.field
      }`;
      setFieldValue(fieldName, toNumber(params.newValue));
    } else {
      console.warn('DayToWzpTransformation setFieldValue property not set, cannot propagate value update', params);
      console.log('DayToWzpTransformation props are', this.props);
    }
  };

  onPaste = data => {
    const { setFieldValue } = this.props.formik;
    const index = this.props.periodIndex;
    data.sort((a, b) => (a.index < b.index ? -1 : 1));
    if (setFieldValue) {
      const fieldName = `planningParameters.periods.${index}.apCalculated.wzpTransitions.rowData`;
      setFieldValue(fieldName, data);
    } else {
      console.warn('DayToWzpTransformation setFieldValue property not set, cannot propagate value update', data);
    }
  };
}

DayToWzpTransformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  toggleDayAction: PropTypes.func,
  openDeleteDialog: PropTypes.func,
  intl: PropTypes.object,
  activityColumnConfig: PropTypes.object,
  edit: PropTypes.bool,
  periodIndex: PropTypes.number,
  selectedDays: PropTypes.array,
  offdays: PropTypes.object,
  firstDay: PropTypes.string,
  formik: PropTypes.object,
  isShift: PropTypes.bool,
};

const mapStateToPropsPlan = createStructuredSelector({
  selectedDays: makeSelectDayToWzpTransformation(),
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
  offdays: selectOffDays,
  firstDay: selectFirstday,
  isShift: makeSelectIsShiftFromPlan(),
  isLoading: makeSelectRunningApiCalls(),
});

const mapStateToPropsPa = createStructuredSelector({
  selectedDays: makeSelectDayToWzpTransformation(),
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
  offdays: selectOffDaysPA,
  firstDay: selectFirstdayPA,
  isShift: selectIsShiftFromPa,
  isLoading: makeSelectRunningApiCalls(),
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ toggleDayAction }, dispatch),
    dispatch,
  };
}

const withPlanConnect = connect(
  mapStateToPropsPlan,
  mapDispatchToProps,
);

const withPaConnect = connect(
  mapStateToPropsPa,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'dayToWzpTransformation', reducer });

export const DayToWzpTransformationPlan = compose(
  withReducer,
  injectIntl,
  withPlanConnect,
  withDeleteDialog,
  formikConnect,
)(DayToWzpTransformation);

export const DayToWzpTransformationPa = compose(
  withReducer,
  injectIntl,
  withPaConnect,
  withDeleteDialog,
  formikConnect,
)(DayToWzpTransformation);
