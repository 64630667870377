import * as React from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { injectIntl, InjectedIntl } from 'react-intl';
import styled from 'styled-components';
import messages from './messages';
import { isEmpty } from 'lodash';
import { toNumber, numberFormat } from 'utils/utils';
import { parseDateAndFormatToLocalString, parseDate } from 'utils/dateTime';
import { compose } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import AgTable from 'components/Table';
import { GroupWithToolTip } from 'components/NameWithToolTip';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { calculateSmartProdJobTableData, calculateIndirectData } from './utils';
import { camelCase, startCase } from 'lodash';
import calendarMessages from 'utils/calendar/messages';

const TableWithHeight = styled(AgTable)`
  height: ${props => props.height}px;

  .cell-emptyAdjustment {
    background-color: lightgray;
    border-right-color: coral;
  }
`;

type Props = {
  intl: InjectedIntl;
  smartProdJobAdjustments: any;
}

const SmartProdJobAdjustments: React.FC<Props> = ({ intl, smartProdJobAdjustments }) => {

  const [gridApi, setGridApi] = React.useState(null);
  const onGridReady = params => {
    setGridApi(params);
  };

  React.useEffect(()=>{
    const filterModel = JSON.parse(sessionStorage.getItem('smpjaiFilterModel'));
      if(gridApi) gridApi.api.setFilterModel(filterModel);
  },[gridApi, smartProdJobAdjustments])

  function calculateTableData(data) {
    const colDefs: (ColDef | ColGroupDef)[] = [
      {
        colId: 'source',
        field: 'source',
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.source),
        headerTooltip: intl.formatMessage(messages.source),
        tooltipValueGetter : params => params.value,
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
        width: 100,
        suppressSizeToFit: true,
        pinned: 'left',
        rowGroup: true,
        hide: true,
        resizable: true
      },
      {
        colId: 'jobCodeAdjustmentOmsName',
        field: 'jobCodeAdjustmentOmsName',
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.jobCodeOmsName),
        headerTooltip: intl.formatMessage(messages.jobCodeOmsName),
        tooltipValueGetter : params => params.value,
        cellRenderer: 'agGroupCellRenderer',
        width: 200,
        sortable: true,
        suppressSizeToFit: true,
        pinned: 'left',
        rowGroup: false,
        hide: true,
        resizable: true
      },
      {
        colId: 'jobCodeAdjustmentName',
        field: 'jobCodeAdjustmentName',
        tooltipValueGetter : params => params.value,
        filter: 'setFilter',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: intl.formatMessage(messages.jobCodeAdjustmentName),
        headerTooltip: intl.formatMessage(messages.jobCodeAdjustmentName),
        width: 350,
        sortable: true,
        suppressSizeToFit: true,
        pinned: true,
        resizable: true
      },
    ];
    const { rowData, days } = data;
    const sortedDays = [...days].sort();
    sortedDays.forEach(day => {
        var d = new Date(day);
        var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        var dayName = days[d.getUTCDay()];
        const dayOfWeek = intl.formatMessage(calendarMessages[`${dayName.toLowerCase()}Short`]);
      colDefs.push({
        headerName: `${dayOfWeek} ${parseDateAndFormatToLocalString(day)}`,
        headerTooltip: `${dayOfWeek} ${parseDateAndFormatToLocalString(day)}`,
        children: [
          {
            sortable: false,
            suppressMenu: true,
            suppressMovable: true,
            resizable: true,
            colId: day,
            headerName: intl.formatMessage(messages.remaining),
            headerTooltip: intl.formatMessage(messages.remaining),
            width: 90,
            minWidth: 90,
            pinned: false,
            valueGetter: params => {
              const row = params && params.data && params?.data[day];
              if(row?.adjustment >= 0){
                const value = numberFormat(toNumber(100 - row?.adjustment).toFixed(2));
                if(value == -0){
                  return 0;
                }
                return 100 - row?.adjustment;
              }else{
                row?.adjustment
              }
            },
            cellClass: params => {
              const row = params && params.data && params?.data[day];
              if(params.data === undefined){
                return ''
              }
              if((row === undefined || row.adjustment === undefined)){
                return 'cell-emptyAdjustment'
              }
            },
            valueFormatter: params =>
              // eslint-disable-next-line no-restricted-globals
              params.value === null || params.value === undefined || params.value === '' || isNaN(params.value)
                ? ''
                : `${numberFormat(toNumber(params.value).toFixed(2))}%`,
          },
        ],
      });
    });
    return { colDefs, rowData: cloneDeep(rowData) };
  }

  const handleFilterChanged = params => {
    if(!isEmpty(params.api.getFilterModel())) sessionStorage.setItem('smpjaiFilterModel', JSON.stringify(params.api.getFilterModel()));
  }

  const indirectTableData = calculateIndirectData(cloneDeep(smartProdJobAdjustments));
  const calculateIndirectRowData = calculateSmartProdJobTableData(indirectTableData);
  const tableIndirectData = calculateTableData(calculateIndirectRowData);
  const ROW_HEIGHT = 35;

  return (
    <div>
      <div>
      <TableWithHeight
          name="SmartProdJobIndirectAdjustment"
          defaultColDef={{
            flex: 1,
          }}
          frameworkComponents={{
            acWithToolTip: GroupWithToolTip(),
            groupFilter: GroupFilter,
          }}
          sortable
          pagination={false}
          applyColumnDefOrder
          columnDefs={tableIndirectData.colDefs}
          rowData={tableIndirectData.rowData}
          onGridReady={onGridReady}
          onFilterChanged = {handleFilterChanged}
          height={150 + ROW_HEIGHT * Math.min(10, tableIndirectData.rowData.length)}
          groupDefaultExpanded={1}
          showCollapseButtonsInsideTable={tableIndirectData.rowData.length ? true: false}
          autoGroupColumnDef={{
            colId: 'groupping',
            headerName: `${intl.formatMessage(messages.source)} \u2192 ${intl.formatMessage(
              messages.omsCodesTableOMSCode,
            )}`,
            tooltipValueGetter : params => params.value,
            field: 'jobCodeAdjustmentOmsName',
            width: 220,
            suppressSizeToFit: true,
            pinned: true,
            resizable: true,
            cellRenderer: 'agGroupCellRenderer',
            menuTabs: ['filterMenuTab'],
            filter: 'groupFilter',
            filterParams: {
              columns: ['source', 'jobCodeAdjustmentOmsName'],
              applyButton: true,
              clearButton: true,
            },
          }}
        />
      </div>
    </div>
  );
}

export default compose(injectIntl)(SmartProdJobAdjustments);
