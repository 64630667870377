/**
 *
 * WZPDefination
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {PaWZPAddline} from 'components/ShiftAddLine/WZPAddline';
import {PaWZPSettings} from 'containers/ShiftSettings/WZPSettings';

/* eslint-disable react/prefer-stateless-function */
class WZPDefinationPa extends React.PureComponent {
  render() {
    const { values, formik, isLoading, isEdit, shiftDaysRemovedWarning } = this.props;
    return (
      <div>
        <PaWZPAddline {...{ values, isEdit }} />
        <PaWZPSettings tableKey="shift-definition" labourCategory={false} formik={formik} isLoading={isLoading} shiftDaysRemovedWarning={shiftDaysRemovedWarning}/>
      </div>
    );
  }
}

WZPDefinationPa.propTypes = {
  values: PropTypes.object,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};

export default WZPDefinationPa;
