/*
 *
 * Page list reducer
 *
 */

import { fromJS } from 'immutable';
import hash from 'object-hash';
import uniqid from 'uniqid';

import { CHANGE_FILTER, CHANGE_VIEW, DEFAULT_ACTION, LOAD_LIST_SUC, ROW_SELECTED, MASTER_PLAN_REPORT_RUNS, RESET_MASTER_PLAN_REPORT_RUNS, LIST_LOADER, ROW_CHANGED } from './constants';

const makeReducer = config => {
  const initialState = fromJS({
    enabled: false,
    data: false,
    selectedRows: {},
    defs: { name: {}, facility: {}, customer: {} },
    rowHeight: 0,
    version: uniqid(),
    filter: {
      deleted: {
        filterType: 'set',
        values: ['Enabled'],
      },
    },
    view: 'userView',
  });

  function filterItem(item, filter) {
    const del = item.get !== undefined ? item.get('deleted') : item.deleted;
    if (filter.find(current => current === (del === true ? 'Disabled' : 'Enabled'))) {
      return item;
    }
    return false;
  }

  function filterData(data, deletedFilter) {
    const newPlans = data.filter(current => filterItem(current, deletedFilter));
    return newPlans;
  }

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case CHANGE_VIEW:
        return state.set('view', fromJS(action.payload));
      case MASTER_PLAN_REPORT_RUNS:
        return state.set('masterPlanReportRunsFlag', true);
      case RESET_MASTER_PLAN_REPORT_RUNS:
        return state.set('masterPlanReportRunsFlag', false);  
      case CHANGE_FILTER: {
        const currentValues = state.getIn(['filter']).toJS();
        const newValues = action.payload;
        if (hash(newValues) !== hash(currentValues)) {
          let newPlans;
          if (newValues && newValues.deleted && newValues.deleted.values) {
            newPlans = filterData(state.get('payload'), newValues.deleted.values);
          } else {
            newPlans = state.get('payload');
          }
          return state.setIn(['filter'], fromJS(action.payload)).set('data', newPlans);
        }
        return state;
      }
      case ROW_SELECTED: {
        if (state.getIn(['selectedRows', action.data.id]) !== undefined) {
          return state.deleteIn(['selectedRows', action.data.id]);
        }
        return state.setIn(['selectedRows', action.data.id], fromJS(action.data));
      }
      case ROW_CHANGED: {
        return state.set('selectedRows', fromJS(action.data));
      }
      case LOAD_LIST_SUC: {
        let nameWidth = 0;
        let customerWidth = 0;
        let facilityWidth = 0;
        let planningAreaName = 15;
        let rowHeight = 3;
        let { data } = action;
        try {
          data = data.map(config.parseData);
        } catch (e) {
          return state;
        }
        // we reset filter
        const result = data.filter(item => {
          nameWidth = item.name && item.name.length > nameWidth ? item.name.length : nameWidth;
          rowHeight = item.facilities && item.facilities.length > rowHeight ? item.facilities.length : rowHeight;
          if (item.area) {
            planningAreaName = item.area.length > planningAreaName ? item.area.length : planningAreaName;
          }
          const { facilities } = item;
          if (facilities) {
            facilities.forEach(facility => {
              const cellLength = (facility.name || '').length + (facility.code || '').length + 3;
              facilityWidth = cellLength > facilityWidth ? cellLength : facilityWidth;
            });
          }
          const { customers } = item;
          if (customers) {
            customers.forEach(customer => {
              const cellLength = (customer.name || '').length + (customer.code || '').length + 3;
              customerWidth = cellLength > customerWidth ? cellLength : customerWidth;
            });
          }
          rowHeight = item.customers && item.customers.length > rowHeight ? item.customers.length : rowHeight;
          return item;
        });

        return state
          .set('data', fromJS(result))
          .set('payload', fromJS(data))
          .set('selectedRows', initialState.get('selectedRows'))
          .setIn(['defs', 'name', 'width'], nameWidth * 6 + 22)
          .setIn(['defs', 'facility', 'width'], facilityWidth * 6 + 22)
          .setIn(['defs', 'customer', 'width'], customerWidth * 6 + 22)
          .setIn(
            ['defs', 'planningAreaName'],
            fromJS({
              width: planningAreaName * 6 + 22,
            }),
          )
          .set('rowHeight', rowHeight * 26)
          .set('version', uniqid());
      }
      case LIST_LOADER: {
        return state.set('listLoaderFlag',action.payload)
      }
      case '@@router/LOCATION_CHANGE': {
        const {
          payload: { pathname },
        } = action;
        if (pathname === config.pathToSelf) {
          return state.set('version', uniqid());
        }
        return state;
      }
      case DEFAULT_ACTION: {
        const { params, enabled } = action;
        if (params.reset === 'false') {
          return state.set('enabled', enabled);
        }
        return initialState.set('enabled', enabled);
      }
      default:
        return state;
    }
  };
};
export default makeReducer;
