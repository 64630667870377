import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled, { Theme } from 'styled-components';

import Button from 'components/Button';
import StyledLabel from 'components/Label';
import { withUrl } from 'utils/api';
import { formatDate, formatDateTime } from 'utils/dateTime';
import { fetchData } from 'utils/reduxApi';
import { ApiSmartVolumeRunDTO } from 'types/drep-backend.d';

import messages from './messages';
import { EntityEntry } from './types';

const CompactButton = styled(Button)``;
const Space = styled.div`
  height: 20px;
`;

const MessagesContainer = styled.div`
  height: 240px;
  overflow-y: scroll;
`;

const DetailWrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  z-index: 100;

  ${CompactButton} {
    white-space: normal;
    max-width: 120px;
    height: auto;
  }

  div > span {
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const CenteredLabel = styled(StyledLabel)``;

type Props = {
  intl: InjectedIntl;
  theme: Theme;
  data: ApiSmartVolumeRunDTO;
  forecastEdit: boolean;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  planningParametersId: number;
  handleReloadData: () => void;
};

const SmartProdVolumeRunsTableDetailCellRenderer: React.FC<Props> = ({ intl, data }) => {
  const dispatch = useDispatch();

  const downloadResponseFile = () => {
    const name = `SmartVolume Run ${data.id} - Response File Id ${data.taskId}.json`;
    const url = `/smartVolume/runs/${data.id}/download/${data.taskId}`;
    fetchData(withUrl(url).setFileDownload(name), dispatch);
  };

  const requestFileDownload = data.taskId ? (
    <span style={{ cursor: 'pointer', width: '25px' }}>
      <FontAwesomeIcon icon="file-upload" color="green" size="lg" onClick={downloadResponseFile} />
    </span>
  ) : (
    <span />
  );

  return (
    <DetailWrapper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CenteredLabel {...messages.smartVolumeRunsForecastPeriod} />
            </Grid>
            <Grid item xs={2}>
              <CenteredLabel {...messages.smartVolumeRunsStartDate} />
            </Grid>
            <Grid item xs={2}>
              {formatDate(data.startDate)}
            </Grid>
            <Grid item xs={2}>
              <CenteredLabel {...messages.smartVolumeRunsEndDate} />
            </Grid>
            <Grid item xs={2}>
              {formatDate(data.endDate)}
              <Space />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <CenteredLabel {...messages.smartVolumeRunsLearningDate} />
            </Grid>
            <Grid item xs={4}>
              {formatDateTime(data.learningDate)}
              <Space />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <CenteredLabel {...messages.smartVolumeRunsDownloadFile} />
            </Grid>
            <Grid item xs={4}>
              <Tooltip title={intl.formatMessage(messages.downloadResponseTooltip)}>{requestFileDownload}</Tooltip>
              <Space />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CenteredLabel {...messages.smartVolumeRunsFinished} />
            </Grid>
            <Grid item xs={6}>
              {formatDateTime(data.finished)}
            </Grid>
            <Grid item xs={6}>
              <CenteredLabel {...messages.smartVolumeRunsStatus} />
            </Grid>
            <Grid item xs={6}>
              {data.status}
            </Grid>
            <Grid item xs={6}>
              <CenteredLabel {...messages.smartVolumeRunsRequested} />
            </Grid>
            <Grid item xs={6}>
              {formatDateTime(data.created)}
            </Grid>
            <Grid item xs={6}>
              <CenteredLabel {...messages.smartVolumeRunsRequestedBy} />
            </Grid>
            <Grid item xs={6}>
              {`${data.createdBy.firstName || ''} ${data.createdBy.lastName || ''}`}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CenteredLabel {...messages.smartVolumeRunsMessages} />
          <Space />
          <MessagesContainer>
            {data.messages?.map((message, i) => (
              <div key={i}>
                <span style={{ marginRight: 8 }}>{formatDateTime(message.created)}</span>&nbsp;{message.message}
              </div>
            ))}
          </MessagesContainer>
        </Grid>
      </Grid>
    </DetailWrapper>
  );
};

export default injectIntl(SmartProdVolumeRunsTableDetailCellRenderer);
