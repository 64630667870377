import { findByKey } from "utils/utils";
import calcColDefs from "./calculateColDef";
import { findBestPeriod } from "./calculateCommon";
import {
  activityToRow,
  addFormatters,
  labourCategoryToRow,
  labourDiscrepancyRow,
  labourSumsToRow,
} from "./calculateData";
import labourCategoryToRowDepartment from './calculateLabourRowToDepartment';
import labourCategoryToRowDepartmentBaseLine from './calculateLabourRowToDepartmentBaseLine';
import { DATASET, Filter, Props, TablesDataResult, TablesResult } from "./types";
import {
  ApiActivityParametersCalculationResultDTO,
  ApiActivityParametersMultipleDaysCalculationResultDTO,
  ApiComplexCalculationResultDTO,
} from "types/drep-backend.d";

function extractWzpNamesFromAverages(activities: ApiActivityParametersCalculationResultDTO[]): string[] {
  const result: Set<string> = new Set();
  activities.forEach(activity => {
    activity.wzpNumberOfStaffTotal?.forEach(wzpAvg => result.add(wzpAvg.wzpName));
  });
  return [...result];
}

export function calculateColDefs(data: ApiComplexCalculationResultDTO, props: Props, filter: Filter, rows: TablesResult): TablesResult {
  const budgetFlag = filter.includeBaseLine && filter.showBudget;
  const periods: ApiActivityParametersMultipleDaysCalculationResultDTO[] = findBestPeriod(budgetFlag ? data.plan.activities : data.plan.activities);
  const wzpNames: string[] = filter.showNumberOfStaff ? extractWzpNamesFromAverages(budgetFlag ? data.plan.activities : data.plan.activities) : [];
  return calcColDefs(filter, props, periods, rows, wzpNames, data);
}

function isActivityVisible(activity: ApiActivityParametersCalculationResultDTO, filter: Filter) {
  if (activity.indirect) {
    return filter.showIndirect;
  }
  return filter.showDirect;
}

function activityUniqueKey(activity: ApiActivityParametersCalculationResultDTO) {
  return `${activity.activity.id}_${activity.department?.id}_${activity.customer?.id}_${activity.uom?.id}`;
}

// TODO temp workaround: WZP with the same name have different IDs
function fixDifferentIdsInPlanVsBudget(data: ApiComplexCalculationResultDTO) {
  if (!data.budget) { return; }

  data.plan.activities
    .forEach((a, aIdx) => {
      const budgetA = data.budget.activities[aIdx];
      if (budgetA) {
        a.periods.forEach((p, pIdx) => {
          const budgetP = budgetA.periods[pIdx];
          if (budgetP) {
            p.days.forEach((day, dIdx) => {
              const budgetD = budgetP.days[dIdx];
              if (budgetD) {
                day.wzps.forEach((wzp) => {
                  const budgetWzp = budgetD.wzps
                    .find((candidate) => candidate.workZonePeriodName === wzp.workZonePeriodName);
                  if (budgetWzp) {
                    budgetWzp.workZonePeriodId = wzp.workZonePeriodId;
                  }
                });
              }
            });
          }
        });
      }
    });
}

export function calculateData(data: ApiComplexCalculationResultDTO, props: Props, filter: Filter): TablesResult {
  const result = { activity: { data: [], totals: [], formatters: {} }, mhe: { data: [], totals: [], formatters: {} }, role: { data: [], totals: [], formatters: {} } };
  // activities
  // data
  const numberFormatter = {};
  const mheNumberFormatter = {};

  fixDifferentIdsInPlanVsBudget(data);

  let rowIndex = 0;
  data.plan.activities.forEach((activity, index) => {
    if (isActivityVisible(activity, filter)) {
      const aKey = activityUniqueKey(activity);
      const budget = data.budget && findByKey(data.budget.activities, (a) => {
        return (activityUniqueKey(a) === aKey);
      });
      const forecast = data.forecast && findByKey(data.forecast.activities, (a) => {
        return (activityUniqueKey(a) === aKey);
      });
      const smartVolume = data.smartVolume && findByKey(data.smartVolume.activities, (a) => {
        return (activityUniqueKey(a) === aKey);
      });
      const row = activityToRow(activity, budget, forecast, smartVolume, filter, numberFormatter, mheNumberFormatter, props);
      if (!filter.byHour) {
        // @ts-ignore
        row.activity.data.index = index;
        result.activity.data.push(row.activity.data);
      } else {
        row.activity.data.map((r: any) => {
          r.index = rowIndex;
          result.activity.data.push(r);
          rowIndex += 1;
        });
      }
    }
  });


  // availability
  // sum
  result.activity.totals = [];

  let discrepancies = {};
  let addDiscrepancies = false;
  if (data.planDiscrepancy) {
    addDiscrepancies = true;
    discrepancies = labourDiscrepancyRow(data.planDiscrepancy.sums, data.planDiscrepancy.totals, filter, props, DATASET.planned);
  }

  if (data.budgetDiscrepancy) {
    addDiscrepancies = true;
    discrepancies = {
      ...discrepancies,
      ...labourDiscrepancyRow(data.budgetDiscrepancy.sums, data.budgetDiscrepancy.totals, filter, props, DATASET.budget),
    };
  }

  if (addDiscrepancies) {
    result.activity.totals.push(discrepancies);
  }

  let totalRow = {};
  let addTotal = false;
  if (data.planAvailability && data.planAvailability.sums[0]) {
    totalRow = labourSumsToRow(data.planAvailability.sums, data.planAvailability.totals, filter, props, DATASET.planned);
    addTotal = true;
  }

  if (data.budgetAvailability && data.budgetAvailability.sums[0]) {
    totalRow = {
      ...totalRow,
      ...labourSumsToRow(data.budgetAvailability.sums, data.budgetAvailability.totals, filter, props, DATASET.budget),
    };
    addTotal = true;
  }
  if (addTotal) {
    result.activity.totals.push(totalRow);
  }

  if (filter.showLabourAvailability) {
    result.activity.totals.push({ isPreSumRow: true });
    let planLabourAvailability = {};
    let budgetLabourAvailability = {};
    let allLabourAvailability = {};

    if (data.planAvailability) {
      const result = labourCategoryToRowDepartment(data, filter);
      planLabourAvailability = result.planLabourAvailability;
      allLabourAvailability = result.allLabourAvailability;
    }
    if (data.budgetAvailability) {
      const result = labourCategoryToRowDepartmentBaseLine(data, filter);
      budgetLabourAvailability = result.budgetLabourAvailability;
      Object.keys(result.allLabourAvailability) && Object.keys(result.allLabourAvailability).forEach((key)=>{
        if(!allLabourAvailability.hasOwnProperty(key)){  
          allLabourAvailability[key] = key;
        }
      })
    }

    Object.keys(allLabourAvailability).map((id: string) => {
      result.activity.totals.push({ ...(planLabourAvailability[id] || {}), ...(budgetLabourAvailability[id] || {}) });
    });
    // LOG_RESULT.d(JSON.stringify(result))
  }

  // result.activity.totals.push
  addFormatters(numberFormatter, result.activity.totals);
  result.activity.formatters = numberFormatter;
  return result;
}

export function calculateSubSfiftData(data: ApiComplexCalculationResultDTO, props: Props, filter: Filter): TablesResult {
  const result = { activity: { data: [], totals: [], formatters: {} }, mhe: { data: [], totals: [], formatters: {} }, role: { data: [], totals: [], formatters: {} } };
  // activities
  // data
  const numberFormatter = {};
  const mheNumberFormatter = {};

  fixDifferentIdsInPlanVsBudget(data);

  let rowIndex = 0;
  data.plan.activities.forEach((activity, index) => {
    if (isActivityVisible(activity, filter)) {
      const aKey = activityUniqueKey(activity);
      const budget = data.budget && findByKey(data.budget.activities, (a) => {
        return (activityUniqueKey(a) === aKey);
      });
      const forecast = data.forecast && findByKey(data.forecast.activities, (a) => {
        return (activityUniqueKey(a) === aKey);
      });
      const smartVolume = data.smartVolume && findByKey(data.smartVolume.activities, (a) => {
        return (activityUniqueKey(a) === aKey);
      });
      const row = activityToRow(activity, budget, forecast, smartVolume, filter, numberFormatter, mheNumberFormatter, props);
      if (!filter.byHour) {
        // @ts-ignore
        row.activity.data.index = index;
        result.activity.data.push(row.activity.data);
      } else {
        row.activity.data.map((r: any) => {
          r.index = rowIndex;
          result.activity.data.push(r);
          rowIndex += 1;
        });
      }
    }
  });


  // availability
  // sum
  result.activity.totals = [];

  let discrepancies = {};
  let addDiscrepancies = false;
  if (data.planDiscrepancy) {
    addDiscrepancies = true;
    discrepancies = labourDiscrepancyRow(data.planDiscrepancy.sums, data.planDiscrepancy.totals, filter, props, DATASET.planned);
  }

  if (data.budgetDiscrepancy) {
    addDiscrepancies = true;
    discrepancies = {
      ...discrepancies,
      ...labourDiscrepancyRow(data.budgetDiscrepancy.sums, data.budgetDiscrepancy.totals, filter, props, DATASET.budget),
    };
  }

  if (addDiscrepancies) {
    result.activity.totals.push(discrepancies);
  }

  let totalRow = {};
  let addTotal = false;
  if (data.planAvailability && data.planAvailability.sums[0]) {
    totalRow = labourSumsToRow(data.planAvailability.sums, data.planAvailability.totals, filter, props, DATASET.planned);
    addTotal = true;
  }

  if (data.budgetAvailability && data.budgetAvailability.sums[0]) {
    totalRow = {
      ...totalRow,
      ...labourSumsToRow(data.budgetAvailability.sums, data.budgetAvailability.totals, filter, props, DATASET.budget),
    };
    addTotal = true;
  }
  if (addTotal) {
    result.activity.totals.push(totalRow);
  }

  if (filter.showShiftLabourAvailability) {
    result.activity.totals.push({ isPreSumRow: true });
    let planLabourAvailability = {};
    let budgetLabourAvailability = {};
    let allLabourAvailability = {};

    if (data.planAvailability) {
      const result = labourCategoryToRowDepartment(data, filter);
      planLabourAvailability = result.planLabourAvailability;
      allLabourAvailability = result.allLabourAvailability;
    }
    if (data.budgetAvailability) {
      const result = labourCategoryToRowDepartmentBaseLine(data, filter);
      budgetLabourAvailability = result.budgetLabourAvailability;
      Object.keys(result.allLabourAvailability) && Object.keys(result.allLabourAvailability).forEach((key)=>{
        if(!allLabourAvailability.hasOwnProperty(key)){  
          allLabourAvailability[key] = key;
        }
      })
    }

    Object.keys(allLabourAvailability).map((id: string) => {
      result.activity.totals.push({ ...(planLabourAvailability[id] || {}), ...(budgetLabourAvailability[id] || {}) });
    });
    // LOG_RESULT.d(JSON.stringify(result))
  }

  // result.activity.totals.push
  addFormatters(numberFormatter, result.activity.totals);
  result.activity.formatters = numberFormatter;
  return result;
}

export function mergeDataAndDefs(data: ApiComplexCalculationResultDTO, props: Props, filter: Filter, resultData: TablesResult) {
  const result: TablesResult = calculateColDefs(data, props, filter, resultData);
  result.activity.data = resultData.activity.data;
  result.activity.totals = resultData.activity.totals;
  result.mhe.data = resultData.mhe.data;
  result.mhe.totals = resultData.mhe.totals;
  result.role.data = resultData.role.data;
  result.role.totals = resultData.role.totals;
  return result;
}
