/**
 *
 * Switch
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { setViewAction } from '../../containers/App/actions';
import { USER_VIEWS } from '../../containers/App/constants';
import { makeSelectView } from '../../containers/App/selectors';
import { loadDefaultsAction, loadUserAction, saveConfigToFavAction } from '../../containers/TableControlled/actions';
import { FavoritesButton } from '../IconButton';
import messages from './messages';
import Uncontrolled from './Uncontrolled';

/* eslint-disable react/prefer-stateless-function */
class UserViewToggleButton extends React.PureComponent {
  render() {
    const { setViewAction, value, intl, defaultViewAction, userViewAction, saveConfigToFavAction, handleUserViewToggleButton } = this.props; // eslint-disable-line
    return (
      <>
        <FavoritesButton onClick={saveConfigToFavAction} />
        <Uncontrolled
          id="button-toggleViewState"
          onChange={() => {
            if (value === USER_VIEWS.DEFAULT_VIEW) {
              userViewAction();
              handleUserViewToggleButton();
              setViewAction(USER_VIEWS.USER_VIEW);
            } else {
              defaultViewAction();
              handleUserViewToggleButton();
              setViewAction(USER_VIEWS.DEFAULT_VIEW);
            }
          }}
          renderChildren={() => {
            switch (value) {
              case USER_VIEWS.USER_VIEW:
                return (
                  <FontAwesomeIcon
                    id="button-switchToDefaultView"
                    icon="book-open"
                    size="1x"
                    fixedWidth
                    title={intl.formatMessage(messages.defaultView)}
                  />
                );
              case USER_VIEWS.DEFAULT_VIEW:
              default:
                return (
                  <FontAwesomeIcon
                    id="button-switchToUserView"
                    icon="book-reader"
                    size="1x"
                    fixedWidth
                    title={intl.formatMessage(messages.userView)}
                  />
                );
            }
          }}
        />
      </>
    );
  }
}

UserViewToggleButton.propTypes = {
  userViewAction: PropTypes.func,
  defaultViewAction: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({ value: makeSelectView() });

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      defaultViewAction: loadDefaultsAction,
      userViewAction: loadUserAction,
      setViewAction,
      saveConfigToFavAction,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(UserViewToggleButton);
