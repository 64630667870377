import React from 'react';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { isDayOfWeekTransformationActivity } from 'components/DayOfWeekTransformation';
import { InputError } from 'components/FormikTextInput';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { ViewSettings } from 'components/ViewModePopover/types';
import { GRANULARITY } from 'containers/PlanResultPage/constants';
import { formatDate } from 'utils/dateTime';
import withSecurity, { PERMISSIONS } from 'utils/security';

import FormikDatePicker from '../FormikDatePicker';
import { ConfirmButton, ExportButton } from '../IconButton';
import { LabelStyled } from '../Label';
import { FormikCheckbox, FormikCheckboxSunday } from '../Menu/ItemSelectable';
import messages from './messages';

const Space = styled.div`
  padding-right: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin: 0 10px 0 0;
  }
  @media screen and (min-width: 1400px) {
    > * {
      margin: 0 20px 0 0;
    }
  }
  @media screen and (min-width: 1700px) {
    > * {
      margin: 0 20px 0 20px;
    }
  }

  padding-bottom: 5px;

  ${ExportButton} {
    margin-left: auto;
  }

  ${InputError} {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  ${LabelStyled} {
    word-break: normal;
  }
`;

const FieldWrapper = styled(Field)`
  width: 100px;
`;

const WrapperOne = styled.div`
  display: flex;
  padding-left: 10px;
  flex-direction: row;
  align-items: center;

  > * {
    margin: 0 10px 0 0;
  }
  @media screen and (min-width: 1400px) {
    > * {
      margin: 0 20px 0 0;
    }
  }
  @media screen and (min-width: 1700px) {
    > * {
      margin: 0 20px 0 20px;
    }
  }

  padding-bottom: 5px;

  ${ExportButton} {
    margin-left: auto;
  }

  ${InputError} {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  ${LabelStyled} {
    word-break: normal;
  }
`;

export const SaveButton: React.FC<{ isSubmitting: boolean; onClick: () => void }> = buttonProps => (
  <ConfirmButton
    id="result-matrix-confirm"
    type="submit"
    disabled={buttonProps.isSubmitting}
    onClick={buttonProps.onClick}
  />
);

type FormikSelectProps = {
  field: any;
  children: any;
  form: FormikActions<any>;
  onChange?: Function;
};

export function FormikSelect(props: FormikSelectProps) {
  const { children, field, onChange, ...other } = props;
  return (
    <Select
      {...other}
      {...field}
      onChange={c => {
        props.form.setFieldValue(field.name, c.target.value);
        if (onChange) onChange(c.target.value);
      }}
    >
      {children}
    </Select>
  );
}

function maxDateByGranularity(gran, startDate) {
  switch (gran) {
    case GRANULARITY.MONTH:
    case GRANULARITY.WEEK:
      return (startDate && startDate.plus({ years: 1 })) || null;
    case GRANULARITY.DAY:
    case GRANULARITY.WZP:
      return (startDate && startDate.plus({ months: 1 })) || null;
    case GRANULARITY.HOUR:
    default:
      return (startDate && startDate.plus({ days: 14 })) || null;
  }
}

const startDateValidation = endDate => value =>
  value.startOf('day') && value.startOf('day') > endDate.startOf('day') ? `Must not be more than ${formatDate(endDate)}` : undefined;

const endDateValidation = values => value => {
  if (value.startOf('day') && value.startOf('day') < values.startDate.startOf('day')) return `Must be at least ${formatDate(values.startDate)}`;

  const max = getMaxDate(values);
  if (value && value > max) return `Must not be more than ${formatDate(max)}`;

  return undefined;
};

function getMaxDate(values) {
  const { granularity, startDate } = values;
  let date = startDate;
  if (typeof date === 'string') {
    date = DateTime.fromFormat(startDate, 'dd/MM/yyyy');
  }
  return maxDateByGranularity(granularity, date);
}

/* eslint-disable react/prefer-stateless-function */
type ResultMatrixDateOptionsType = {
  token: string;
  dispatch: any;
  formikBag: FormikProps<Object>;
  startDate: DateTime;
  endDate: DateTime;
  granularity: string;
  granularityOptions: Array<string>;
  onConfirm: () => {};
  mheCheckbox?: boolean;
  includeMhe: boolean;
  includeRole: boolean;
  isShift: boolean;
  showEndDate: boolean;
  limitStartDate: boolean;
  includeBaseLine: boolean;
  includeForecast: boolean;
  includeSmartVolume: boolean;
  includeWeekStartAsSunday: boolean;
  includeVolumeCategory: boolean;
  showBaseLine: boolean;
  showGranularity: boolean;
  showForecast: boolean;
  showSmartVolumeForecast: boolean;
  showCsvExport: boolean;
  plan: any;
  planningParametersId: string | number;
  intl: InjectedIntl;
  hasPerm: Function;
  onResultSettingsChange: Function;
  viewSettings: ViewSettings;
  granularityValue: string;
};

function ResultMatrixDateOptions(props: ResultMatrixDateOptionsType) {
  const {
    endDate,
    startDate,
    granularity = GRANULARITY.WEEK,
    granularityOptions = GRANULARITY,
    includeMhe = false,
    includeRole = false,
    includeBaseLine = false,
    includeWeekStartAsSunday = false,
    includeVolumeCategory = false,
    includeForecast = false,
    includeSmartVolume = false,
    mheCheckbox = true,
    planningParametersId,
    isShift = false,
    showEndDate = true,
    showBaseLine = false,
    showGranularity = true,
    showForecast = true,
    showSmartVolumeForecast = true,
    onConfirm,
    limitStartDate = true,
    hasPerm,
    onResultSettingsChange,
    viewSettings,
    granularityValue
  } = props;
  const hasDayOfWeekTransformationActivity = !isDayOfWeekTransformationActivity(props.formikBag);
  const isForecastEditor = hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  return (
    <Formik
      initialValues={{
        endDate,
        startDate,
        granularity,
        includeMhe: includeMhe && !!viewSettings?.checkboxMHE,
        includeRole: includeRole && !!viewSettings?.checkboxRole,
        planningParametersId,
        includeBaseLine,
        includeWeekStartAsSunday,
        includeVolumeCategory,
        includeForecast: includeForecast && viewSettings && viewSettings.inputType !== 'volume',
        includeSmartVolume: includeSmartVolume && viewSettings && viewSettings.inputType !== 'volume',
      }}
      isInitialValid
      enableReinitialize
      validateOnChange
      onSubmit={onConfirm}
      render={formikBag => (
        <>
        <WrapperOne>
          <LabelStyled {...messages.startDate} />
          <div>
            <FieldWrapper
              onAccept={newStartDate => {
                const update = { ...formikBag.values, startDate: newStartDate };
                if (newStartDate.startOf('day') > formikBag.values.endDate.startOf('day')) {
                  update.endDate = newStartDate;
                  formikBag.setFieldValue('endDate', newStartDate);
                }
                formikBag.setFieldValue('startDate', newStartDate);
                onResultSettingsChange && onResultSettingsChange(update);
              }}
              component={FormikDatePicker}
              okLabel={props.intl.formatMessage(messages.setstartDate)}
              name="startDate"
              {...(limitStartDate
                ? {
                  validate: startDateValidation(formikBag.values.endDate),
                }
                : {})}
            />
            <InputError>{formikBag.errors.startDate}</InputError>
          </div>
          {showEndDate && <LabelStyled {...messages.endDate} />}
          {showEndDate && (
            <div>
              <FieldWrapper
                onAccept={newEndDate => {
                  const update = { ...formikBag.values, endDate: newEndDate };
                  if (newEndDate.startOf('day') < formikBag.values.startDate.startOf('day')) {
                    update.startDate = newEndDate;
                    formikBag.setFieldValue('startDate', newEndDate);
                  }
                  formikBag.setFieldValue('endDate', newEndDate);
                  onResultSettingsChange && onResultSettingsChange(update);
                }}
                component={FormikDatePicker}
                name="endDate"
                okLabel={props.intl.formatMessage(messages.setendDate)}
                validate={endDateValidation(formikBag.values)}
              />
              <InputError>{formikBag.errors.endDate}</InputError>
            </div>
          )}
          {showGranularity && (
            <>
              <LabelStyled {...messages.granularity} />
              <Field
                onChange={c => {
                  if(c != 'WEEK'){
                    formikBag.setFieldValue('includeWeekStartAsSunday', false);
                  }
                  onResultSettingsChange && onResultSettingsChange({ ...formikBag.values, granularity: c })
                }}
                component={FormikSelect}
                name="granularity"
              >
                {Object.keys(granularityOptions).map(key => (
                  <MenuItem key={key} value={key}>
                    {isShift && key === GRANULARITY.WZP ? (
                      <FormattedMessage {...messages.shift} />
                    ) : (
                      <FormattedMessage {...messages[key.toLocaleLowerCase()]} />
                    )}
                  </MenuItem>
                ))}
              </Field>
            </>
          )}
          </WrapperOne>
          <Wrapper>
          {mheCheckbox && viewSettings?.checkboxMHE && !formikBag.values.includeWeekStartAsSunday &&(
            <div style={{ display: 'flex ' }}>
              <Field component={FormikCheckbox} name="includeMhe" />
              <Space/>
              <LabelStyled {...messages.includeMhe} />
            </div>
          )}
          {showBaseLine && (
            <div style={{ display: 'flex ' }}>
              <Field component={FormikCheckbox} name="includeBaseLine" />
              <Space/>
              <LabelStyled {...messages.includeBaseLine} />
            </div>
          )}

          {showForecast &&
            isForecastEditor &&
            hasDayOfWeekTransformationActivity &&
            viewSettings &&
            viewSettings.inputType !== 'volume' && (
              <div style={{ display: 'flex ' }}>
                <Field component={FormikCheckbox} name="includeForecast" />
                <Space/>
                <LabelStyled {...messages.includeForecast} />
              </div>
            )}
          {showSmartVolumeForecast &&
            isForecastEditor &&
            hasDayOfWeekTransformationActivity &&
            viewSettings &&
            viewSettings.inputType !== 'volume' && (
              <div style={{ display: 'flex ' }}>
                <Field component={FormikCheckbox} name="includeSmartVolume" />
                <Space/>
                <LabelStyled {...messages.includeSmartVolume} />
              </div>
            )}  
          {viewSettings?.checkboxRole && !formikBag.values.includeWeekStartAsSunday &&(
            <div style={{ display: 'flex ' }}>
              <Field component={FormikCheckbox} name="includeRole" />
              <Space/>
              <LabelStyled {...messages.includeRole} />
            </div>
          )}
          {granularityValue === 'WEEK' && (
            <div style={{ display: 'flex '}}>
              <Field component={FormikCheckboxSunday} name="includeWeekStartAsSunday" />
              <Space/>
              <LabelStyled {...messages.includeWeekStartAsSunday} />
            </div>
          )}
           {(viewSettings.showVolumeSections && !formikBag.values.includeWeekStartAsSunday) && granularityValue !== 'MONTH' &&(
            <div style={{ display: 'flex '}}>
              <Field component={FormikCheckbox} name="includeVolumeCategory" />
              <Space/>
              <LabelStyled {...messages.includeVolumeCategory} />
            </div>
          )}
          <SaveButton isSubmitting={!formikBag.isValid} onClick={formikBag.handleSubmit} />
        </Wrapper>
        </>
      )}
    />
  );
}

const viewSettingsConnect = connect(
  createStructuredSelector({
    viewSettings: selectViewModeStoredData,
  }),
);

export default withSecurity()(viewSettingsConnect(injectIntl(ResultMatrixDateOptions)));
