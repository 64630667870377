import React from "react";
import styled from 'styled-components';
import { getIn } from 'formik';

import messages from './messages';
import TableControlled from '../TableControlled';
import DaysPickerCellEditor from 'components/DaysPickerCellEditor';
import TimePickerCellEditor from 'components/TimePickerCellEditor';
import { getWeekdays } from 'utils/calendar/localize';
import CheckboxCellRenderer from 'components/CheckboxCellRenderer';

const Table = styled(TableControlled)`
  margin-left: ${props => props?.editable ? 0 : 20}px;
  height: ${props => props.height}px;
`;

const Wrap = styled.div`
    margin-left: 20px;
    height: ${props => props.height}px;
`;

const SubShiftRenderer = ({ formik, intl, editable, periodIndex, params, handleParentMouseOut }) => {
  const getFormikDataPath = () => `planningParameters.periods.${periodIndex}.realShiftsWithShift`;
  const value = getIn(formik.values, getFormikDataPath());
  const selectShift = value?.findIndex((s) => s.id === params?.data?.id);
  const getFormikSubShiftDataPath = () => `planningParameters.periods.${periodIndex}.realShiftsWithShift.${selectShift}.shifts`;
  let rowData = getIn(formik.values, getFormikSubShiftDataPath());
  const rowDataTemp = rowData;
  rowData = [];
  rowDataTemp && rowDataTemp.forEach(item => {
    rowData.push(item);
    if (item.isOpen) {
      details += 1;
      detailItems += (item.labourCategoryTransitions || []).length || 2;
      rowData.push({
        fullWidth: true,
        categories: item.labourCategoryTransitions,
        editable: this.props.edit,
        shiftId: item.id,
        shiftIndex: item.index,
        id: -item.id,
      });
    }
  });
  rowData.sort((a,b) => a?.startTime < b?.startTime ? 1 : -1);
  const columnDefs = [
    {
      colId: 'businessDay',
      field: 'businessDay',
      editable: editable,
      width: editable ? 130 : 115,
      headerName: intl.formatMessage(messages.reportDate),
      headerTooltip: intl.formatMessage(messages.reportDate),
      cellRendererFramework: CheckboxCellRenderer,
      cellRendererParams: params => ({
        editable: editable,
      }),
      valueSetter: params => {
        let shiftIndex = 0;
        let subShiftIndex = 0;
        const getFormikDataPath = () => `planningParameters.periods.${periodIndex}.realShiftsWithShift`;
        const periodShiftValue = getIn(formik.values, getFormikDataPath());
        periodShiftValue?.forEach((s, sIndex) => {
          s?.shifts?.forEach((ss, ssIndex) => {
            if (ss.id === params?.data?.id) {
              shiftIndex = sIndex;
              subShiftIndex = ssIndex;
            }
          })
        })
        const subShiftDays = getIn(formik.values, `planningParameters.periods.${periodIndex}.realShiftsWithShift.${shiftIndex}.shifts.${subShiftIndex}.days`);
        const dataPath = `planningParameters.periods.${periodIndex}.realShiftsWithShift.${shiftIndex}.shifts.${subShiftIndex}.businessDay`;
        const remdataPath = `planningParameters.periods.${periodIndex}.realShiftsWithShift.${shiftIndex}.shifts.${subShiftIndex === 0 ? 1 : 0}.businessDay`;
        const shiftDataPath = `planningParameters.periods.${periodIndex}.realShiftsWithShift.${shiftIndex}.days`;
        const modifiedShiftId = getIn(formik.values, `planningParameters.periods.${periodIndex}.realShiftsWithShift.${shiftIndex}.id`);
        const currentIds = JSON.parse(sessionStorage.getItem('businessDateChangedIds')) || [];
        const index = currentIds.indexOf(modifiedShiftId);
        if(index === -1){
          currentIds.push(modifiedShiftId)
        }else{
          currentIds.splice(index, 1)
        }
        sessionStorage.setItem('businessDateChangedIds', JSON.stringify(currentIds))
        formik.setFieldValue(shiftDataPath, subShiftDays)
        formik.setFieldValue(dataPath, !params.oldValue);
        formik.setFieldValue(remdataPath, params.oldValue);
      },
    },
    {
      headerName: intl.formatMessage(messages.name),
      width: editable ? 110 : 85,
      field: 'name',
      colId: 'name',
      editable: false,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: intl.formatMessage(messages.days),
      field: 'days',
      colId: 'days',
      width: editable ? 500 : 200,
      editable: false,
      menuTabs: ['filterMenuTab'],
      valueFormatter: params => Object.values(getWeekdays(intl.formatMessage, params.value, true)).join(', '),
      cellEditorFramework: DaysPickerCellEditor,
    },
    {
      headerName: intl.formatMessage(messages.startTime),
      field: 'startTime',
      colId: 'startTime',
      suppressMenu: true,
      width: 135,
      valueFormatter: params => {
        const splitTime = params?.value?.split(':');
        return splitTime[0] + ':' + splitTime[1];
      }
    },
    {
      headerName: intl.formatMessage(messages.endTime),
      field: 'endTime',
      colId: 'endTime',
      suppressMenu: true,
      width: 135,
      valueFormatter: params => {
        const splitTime = params?.value?.split(':');
        return splitTime[0] + ':' + splitTime[1];
      }
    }
  ];

  return (
    <Wrap key={`${editable}_${rowData?.length}`} height={rowData?.length === 1 ? 0 : 150} editable>
      { rowData?.length > 1 &&
      <Table height={120}
        key={`${editable}_${rowData?.length}`}
        //domLayout="autoHeight"
        //defaultConfig={config}
        messages={messages}
        pagination={false}
        columnDefs={columnDefs}
        rowHeight={40}
        rowData={rowData}
        showCOG={false}
        suppressClickEdit
        onCellMouseOut={handleParentMouseOut}
      //   getRowNodeId={data => data.id}
      //   deltaRowDataMode
      //   onCellValueChanged={params => {
      //     this.onCellValueChanged(params, form.setFieldValue);
      //   }}
      //   isFullWidthCell={isFullWidthCell}
      //   getRowHeight={getRowHeight(this.props)}
      //   onGridReady={params => this.setState({ grid: params })}
      //   singleClickEdit
      />
      }
    </Wrap>
  )
}

export default SubShiftRenderer;