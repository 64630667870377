/**
 *
 * MheAvailability
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FieldArray, getIn } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import uuid from 'uuid/v4';
import { toast } from 'react-toastify'

import {
  selectEditFromplan,
  selectPeriodIndexFromPlan,
  selectPlanDepartments,
  makeSelectIsShiftFromPlan,
} from 'containers/PlanDetailPage/selectors';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import TableControlled from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { DAY_NAMES } from 'utils/calendar/constants';
import {
  forceCapitalize,
  numberCellFormatter,
  numericSetter,
  percentageCellFormatterNull,
  percentageFormatter as pFormat,
  percentageFormatter,
} from 'utils/utils';
import {
  selectEditFromPa,
  selectPeriodIndexFromPa,
  selectAreaDepartments,
  selectIsShiftFromPa,
} from 'containers/PlanningAreaDetailPage/selectors';
import { departmentValueGetter } from 'components/ActivitySettingsDirect';
import { groupByDepartmentProps } from 'components/Table/utils';

import NameWithToolTip from 'components/NameWithToolTip';
import { SimpleHeader } from '../DetailPageShared';
import DeleteCellRenderer from '../DeleteCellRenderer';
import MheAddLine from './MheAddLine';
import messages from './messages';

const StyledTable = styled(TableControlled)`
  height: ${props => props.height}px;
`;

const errorCellStyle = field => params => {
  if (params.data && params.data.errors && params.data.errors[field] && params.data.maintenanceDay === null) {
    return { backgroundColor: '#FCC', color: 'black' };
  }else{
    return { backgroundColor: 'white', color: 'black' };
  }
};

const errorCellStyleWzp = field => params => {
  if (params.data && params.data.errors && params.data.errors[field] && params.data.wzp === null) {
    return { backgroundColor: '#FCC', color: 'black' };
  }else{
    return { backgroundColor: 'white', color: 'black' };
  }
};

/* eslint-disable react/prefer-stateless-function */
class MheAvailability extends React.PureComponent {
  state = { selectedMHE: null, departmentId: null };

  fieldPath = () => `planningParameters.periods.${this.props.periodIndex}.mheAvailabilities`;

  wzpsPath = () => `planningParameters.periods.${this.props.periodIndex}.realShiftsWithWZP`;

  shiftsPath = () => `planningParameters.periods.${this.props.periodIndex}.realShiftsWithShift`;

  prepareValidShiftsForDay = (day, wzps) => {
    if (!wzps) {
      return wzps;
    }
    return wzps.filter(a => a.days && a.days.includes(day));
  };

  currentArrayHelpers = null;

  columnDefs = (self, remove, allWzps, isShift, form) => {
    const { props } = self;
    const editable = props.edit;
    const handleKeyValueFormatter = (params) =>{
      if(params?.data === undefined){
        return '';
      }
      const dayToUppercase = params.data && params.data.maintenanceDay && params.data.maintenanceDay.toUpperCase();
      const wzpId = params.data && params.data.wzp && params.data.wzp.id;
      const dayKey = DAY_NAMES.findIndex( day => day == dayToUppercase)
      const allWzp = form.values && form.values.planningParameters && form.values.planningParameters.periods.map(p => p.realShiftsWithWZP) || [];
      var flattened = [].concat.apply([],allWzp);
      const subWzps = flattened?.map((w)=> [...w.workZonePeriods]).flat(2);
      var result = flattened && flattened.reduce((unique, o) => {
        if (!unique.some(obj => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      const wzpKey = subWzps && subWzps.findIndex(wzp => wzp.id == wzpId)
      if(dayKey === -1 || wzpKey === -1){
        return '';
      }
      return `${params.data && params.data.maintenanceRate && parseInt(params.data.maintenanceRate)}${dayKey + 1}${wzpKey + 1}`
    }
    const colDefs = [
      {
        headerName: props.intl.formatMessage(messages.name),
        field: 'mhe.name',
        colId: 'name',
        cellRendererFramework: NameWithToolTip('mhe.regionalConfigurationName'),
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: props.intl.formatMessage(messages.key),
        field: 'key',
        colId: 'key',
        valueGetter: handleKeyValueFormatter,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: props.intl.formatMessage(messages.piecesDefault),
        field: 'pieces',
        colId: 'pieces',
        editable,
        valueSetter: numericSetter,
        menuTabs: ['filterMenuTab'],
        valueFormatter: numberCellFormatter,
      },
      {
        headerName: props.intl.formatMessage(messages.maintenance),
        field: 'maintenanceRate',
        colId: 'maintenance',
        valueFormatter: percentageCellFormatterNull,
        valueSetter: numericSetter,
        editable,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: props.intl.formatMessage(messages.day),
        field: 'maintenanceDay',
        colId: 'day',
        editable,
        menuTabs: ['filterMenuTab'],
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: DAY_NAMES.map(forceCapitalize),
        },
        tooltipField: 'errors.maintenanceDay',
        cellStyle: errorCellStyle('maintenanceDay'),
      },
      {
        headerName: isShift ? props.intl.formatMessage(messages.shift) : props.intl.formatMessage(messages.wzp),
        field: 'wzp',
        colId: 'wzp',
        editable,
        menuTabs: ['filterMenuTab'],
        cellEditor: 'agRichSelectCellEditor',
        valueFormatter: params => params.value && params.value.name,
        cellEditorParams: params => {
          const { data: { maintenanceDay } = {} } = params;
          if (this.currentArrayHelpers) {
            const wzps = getIn(this.currentArrayHelpers.form.values, isShift ? this.shiftsPath() : this.wzpsPath());
            const subWzps = wzps?.map((w)=> isShift ? [...w.shifts] : [...w.workZonePeriods]).flat(2);
            if (maintenanceDay) {
              return { values: this.prepareValidShiftsForDay(maintenanceDay.toUpperCase(), subWzps) };
            }
            return { values: subWzps };
          }
          return null;
        },
        valueSetter: params => {
          if (params && params.newValue && typeof params.newValue === 'string' && params.newValue.match(/^[0-9]+:/)) {
            const wzpId = Number(params.newValue.split(':')[0]);
            const wzp = allWzps.filter(i => i.id === wzpId);
            if (wzp.length === 1) {
              params.data[params.colDef.field] = wzp[0];
              return true;
            }
          }
          params.data[params.colDef.field] = params.newValue;
          return true;
        },
        tooltipField: 'errors.wzp',
        cellStyle: errorCellStyleWzp('wzp'),
      },
    ];
    if (editable) {
      colDefs.unshift({
        headerName: props.intl.formatMessage(messages.action),
        headerTooltip: props.intl.formatMessage(messages.action),
        field: 'delete',
        colId: 'delete',
        cellRendererFramework: DeleteCellRenderer,
        width: 80,
        cellRendererParams: {
          onDelete: payload => {
            remove(payload.id);
          },
        },
        sortable: false,
        suppressMenu: true,
        pinned: true,
        headerComponentFramework: SimpleHeader,
      });
    }

    colDefs.unshift({
      headerName: props.intl.formatMessage(messages.departmentId),
      valueGetter: departmentValueGetter(self, props.intl.formatMessage(messages.allDepartments)),
      width: 160,
      field: 'departmentId',
      colId: 'departmentId',
      rowGroup: true,
      hide: true,
    });
    return colDefs;
  };

  renderFieldContent = arrayHelpers => {
    const {
      isShift,
      intl: { formatMessage },
      departments = [],
    } = this.props;
    this.currentArrayHelpers = arrayHelpers;
    const { push, form } = arrayHelpers;
    // be very careful here, we still get .length of undefined error here
    // maybe getIn find the property but the property itself is undefined. So let's add || [] even after def value
    let rowData = (getIn(form.values, this.fieldPath(), []) || []).map((it, index) => ({ ...it, index }));
    const errors = getIn(form.errors, this.fieldPath(), []) || [];
    const wzps = getIn(form.values, this.wzpsPath(), []) || [];
    const shifts = getIn(form.values, this.shiftsPath(), []) || [];
    const { reloadCount } = form.initialValues;
    const debugLog = `MheAvailabilityIndex.renderFieldContent debug log: Rowdata='${JSON.stringify(
      rowData,
    )}', errors='${JSON.stringify(errors)}', wzps='${JSON.stringify(wzps)}'`;
    try {
      const rowCount = rowData.length || 0;
      const wzpsLength = wzps.length;
      if (errors && errors.length === rowData.length) {
        rowData = rowData.map((it, index) => ({ ...it, errors: errors[index] }));
        if (this.gridApi) {
          this.gridApi.redrawRows();
        }
      }
      const aproxHeight = (rowCount > 10 ? 10 : rowCount) * 25 + 160;
      const tableKey = `${this.props.edit}_${wzpsLength}_${this.props.periodIndex}_${rowData.length}`;
      return (
        <>
          {this.props.edit && (
            <MheAddLine
              key={reloadCount}
              onAdd={vals => push(this.createNewItem(vals))}
              departments={departments}
              token={this.props.token}
              planningParametersId={form.initialValues.planningParameters.id}
            />
          )}
          <StyledTable
            key={tableKey}
            autosize
            pagination={false}
            height={aproxHeight}
            name={this.props.edit ? 'mheAvailabilityTableEdit' : 'mheAvailabilityTable'}
            messages={messages}
            defaultConfig={
              this.props.edit ? TABLE_DEFAULTS.mheAvailabilityTableEditConfig : TABLE_DEFAULTS.mheAvailabilityTableConfig
            }
            columnDefs={this.columnDefs(this, this.deleteRow, wzps, isShift, form)}
            rowData={rowData}
            onCellValueChanged={cellInfo => this.handleCellValueChanged(cellInfo, form.setFieldValue, form.validateForm, isShift, isShift ? shifts : wzps)}
            onPaste={data => this.onPaste(data, form.setFieldValue, form.validateForm)}
            getRowNodeId={data => data.id}
            //onGridReady={params => this.onGridReady(params)}
            deltaRowDataMode
            singleClickEdit
            processCellForClipboard={params => {
              if (params.column.colId === 'wzp' && params.value && params.value.id) {
                return `${params.value.id}:${params.value.name}`;
              }
              return params.value;
            }}
            // Grouping
            {...groupByDepartmentProps(
              formatMessage,
              messages,
              departmentValueGetter(this, formatMessage(messages.allDepartments)),
            )}
          />
        </>
      );
    } catch (e) {
      if (window) {
        window.__DREPdebugInfo__ = debugLog;
        throw e;
      }
    }
  };

  render() {
    return (
      <div>
        <FieldArray name={this.fieldPath()} validateOnChange render={this.renderFieldContent} />
      </div>
    );
  }

  onGridReady = params => {
    this.gridApi = params.api;
  };

  handleCellValueChanged = (params, setFieldValue, validateForm, isShift, wzps) => {
    const path = this.fieldPath();
    const rowPath = `${path}.${params.data.index}`;
    if(params && params.column && params.column.colId === 'wzp'){
      if(!params.value.id){
        toast.error(`Please save ${this.props.isShift ? 'shifts' : 'work zone periods'} before adding it to Maintenance ${this.props.isShift ? 'SHIFT' : 'WZP'}`);
        setFieldValue(`${rowPath}.${params.colDef.field}`, null);
        params.api.refreshCells({ force: true });
        return ;
      }
    }
    if(params && params.column && params.column.colId === 'day' && (params.newValue !== params.oldValue)){
      const shiftDays = wzps?.filter((wzp) => wzp.id === params?.data?.wzp?.id)[0]?.days || [];
      if(!shiftDays?.includes(params.newValue.toUpperCase())){
        setFieldValue(`${rowPath}.wzp`, null);
      }
    }
    setFieldValue(`${rowPath}.${params.colDef.field}`, params.newValue);
    validateForm();
    const focusedCell =  params.api.getFocusedCell();
    const rowNode = params.api.getRowNode(focusedCell.rowIndex);
    const column = focusedCell.column.colDef.field;
    params.api.refreshCells({
        force: true,
        columns: [column, 'key'],
        rowNodes: [rowNode]
    });
  };

  onPaste = (data, setFieldValue, validateForm) => {
    setFieldValue(this.fieldPath(), data);
    validateForm();
  };

  createNewItem = props => {
    const { mhe, departmentId } = props;
    const newItem = {
      id: uuid(),
      mhe,
      departmentId,
      maintenanceRate: 0,
      pieces: 0,
      maintenanceDay: null,
      wzp: null,
    };
    return newItem;
  };

  deleteRow = id => {
    const { remove, form } = this.currentArrayHelpers;
    const rowData = getIn(form.values, this.fieldPath());
    for (let i = 0; i < rowData.length; i++) {
      if (rowData[i].id === id) {
        remove(i);
        break;
      }
    }
  };
}

MheAvailability.propTypes = {
  periodIndex: PropTypes.number,
  edit: PropTypes.bool,
  token: PropTypes.string,
};

// Plan
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
  token: makeSelectToken(),
  departments: selectPlanDepartments,
  isShift: makeSelectIsShiftFromPlan(),
});
const withPlanConnect = connect(mapPlanStateToProps);

// Planning area
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
  token: makeSelectToken(),
  departments: selectAreaDepartments,
  isShift: selectIsShiftFromPa,
});
const withPaConnect = connect(mapPaStateToProps);

export const PlanMheAvailability = compose(withPlanConnect, injectIntl)(MheAvailability);

export const PaMheAvailability = compose(withPaConnect, injectIntl)(MheAvailability);
