import { T_TYPE } from 'utils/constants';

const prepareRowData = data => ({
  title: data.name,
  from:
    data &&
    data.startTime &&
    ((typeof data.startTime === 'string' && data.startTime.substr(0, 5)) || data.startTime.toFormat('h:mm')),
  to:
    data &&
    data.endTime &&
    ((typeof data.endTime === 'string' && data.endTime.substr(0, 5)) || data.endTime.toFormat('h:mm')),
  id: data.id,
});

function equalGroupData(group1, group2) {
  if (group1.length !== group2.length) {
    return false;
  }
  for (let i = 0; i < group1.length; i += 1) {
    if (group1[i].title !== group2[i].title || group1[i].from !== group2[i].from || group1[i].to !== group2[i].to) {
      return false;
    }
  }
  return true;
}

function calculateWzpsRowData(values) {
  const calculateWzps = period => period.realShiftsWithWZP.map(wzp => prepareRowData(wzp));
  return calculateRowData(values, 'wzps', calculateWzps, equalGroupData);
}

function calculateRowData(pp, key, calculateFunc, equalFunc) {
  const result = [];
  if (pp && pp.periods) {
    let defaultSection;
    // eslint-disable-next-line no-restricted-syntax
    for (const period of pp.periods) {
      const section = {};
      const fromDate = period.startDay ? period.startDay.toLocaleString() : null;
      const toDate = period.endDay ? period.endDay.toLocaleString() : null;
      section.isDefault = !(fromDate && toDate);
      section[key] = calculateFunc(period);
      section.key = period.id;
      if (section.isDefault) {
        defaultSection = section;
        result.push(section);
      } else {
        section.title = `${fromDate} - ${toDate}`;
        if (!defaultSection || !equalFunc(defaultSection[key], section[key])) {
          result.push(section);
        }
      }
    }
  }
  return result;
}

function calculateShiftRowData(values) {
  const groupByDays = period => {
    const dayMap = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const shift of period.realShiftsWithShift) {
      const { days } = shift;
      const key = days.join(',');
      if (!(key in dayMap)) {
        dayMap[key] = { title: key, shifts: [], key: `${period.id}_${key}` };
      }
      dayMap[key].shifts.push(prepareRowData(shift));
    }
    return Object.values(dayMap);
  };

  const equalShifts = (days1, days2) => {
    if (days1.length !== days2.length) {
      return false;
    }
    for (let i = 0; i < days1.length; i += 1) {
      if (
        days1[i].title !== days2[i].title ||
        days1[i].shifts.length !== days2[i].shifts.length ||
        !equalGroupData(days1[i].shifts, days2[i].shifts)
      ) {
        return false;
      }
    }
    return true;
  };

  return calculateRowData(values, 'days', groupByDays, equalShifts);
}

export default function calculateWorkingHours(pp) {
  const tTypeValue = pp.transformationType || T_TYPE.WZP;
  const isShift = tTypeValue === T_TYPE.SHIFT;
  const data = isShift ? calculateShiftRowData(pp) : calculateWzpsRowData(pp);
  return data;
}
