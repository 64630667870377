import { makeSelectFormByName } from 'utils/form/selectors';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

/**
 * Direct selector to the planResultPage state domain
 */

const selectPlanResultPageDomain = state => state.get('planResultPage', initialState);
export const selectEdit = state => state.getIn(['planResultPage', 'guiState', 'edit']);
const resultEditing = state => state.getIn(['planResultPage', 'table', 'editing']);
const resultIsShift = state => state.getIn(['planResultPage', 'table', 'isShift']);
const resultByHour = state => state.getIn(['planResultPage', 'table', 'byHour']);

const resultCalculationStatus = state => state.getIn(['planResultPage', 'table', 'calculationStatus']);
const resultShiftCalculationStatus = state => state.getIn(['planResultPage', 'subshifttable', 'calculationStatus']);
const selectSettings = state => state.getIn(['planResultPage', 'table', 'settings']);
const selectShiftSettings = state => state.getIn(['planResultPage', 'subshifttable', 'settings']);
const selectPlanStatistics = state => state.getIn(['planResultPage', 'statistics']);

const selectTableMhe = state => state.getIn(['planResultPage', 'table', 'mhe']) || {};
const selectTableRole = state => state.getIn(['planResultPage', 'table', 'role']) || {};
const selectResultTableMhe = state => state.getIn(['planResultPage', 'table']) || {};
const selectResultTableRole = state => state.getIn(['planResultPage', 'table']) || {};
export const selectResults = createSelector(
  selectPlanResultPageDomain,
  substate => substate.getIn(['results']),
);

export const selectTableKey = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('tableKey'),
);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PlanResultPage
 */
export const selectPlanResultEditing = () =>
  createSelector(
    selectPlanResultPageDomain,
    substate => substate.get('isEditing'),
  );

export const selectPlanResultOptions = () =>
  createSelector(
    selectPlanResultPageDomain,
    substate => substate.get('options'),
  );

export const makeSelectResultGuiState = () =>
  createSelector(
    selectPlanResultPageDomain,
    substate => substate.get('guiState'),
  );

export const selectPlanStatisticsJS = createSelector(
  selectPlanStatistics,
  substate => substate && substate.toJS(),
);
const selectTableActivity = createSelector(
  selectPlanResultPageDomain,
  substate => substate && substate.getIn(['table', 'activity']),
);

const selectSubShiftTableActivity = createSelector(
  selectPlanResultPageDomain,
  substate => {
    return substate && substate.getIn(['subshifttable', 'activity'])
  },
);

export const selectTableSettings = createSelector(
  selectTableActivity,
  resultEditing,
  resultIsShift,
  resultByHour,
  resultCalculationStatus,
  selectSettings,
  (tableActivity, editing, isShift, byHour, calculationStatus, settings) =>
    tableActivity && { ...tableActivity, editing, isShift, byHour, calculationStatus, settings },
);

export const selectSubShiftTableSettings = createSelector(
  selectSubShiftTableActivity,
  resultEditing,
  resultIsShift,
  resultByHour,
  resultShiftCalculationStatus,
  selectShiftSettings,
  (tableActivity, editing, isShift, byHour, calculationStatus, settings) =>
    tableActivity && { ...tableActivity, editing, isShift, byHour, calculationStatus, settings },
);

const selectResultTableActivity = createSelector(
  selectPlanResultPageDomain,
  substate => substate && substate.get('table'),
);

export const selectResultTableSettings = createSelector(
  selectResultTableActivity,
  (tableActivity) => tableActivity,
);

export const makeSelectTableMHE = () =>
  createSelector(
    selectTableMhe,
    resultCalculationStatus,
    resultIsShift,
    resultByHour,
    (substate, calculationStatus, isShift, byHour) => ({ ...substate, calculationStatus, isShift, byHour }),
  );

  export const makeSelectTableRole = () =>
  createSelector(
    selectTableRole,
    resultCalculationStatus,
    resultIsShift,
    resultByHour,
    (substate, calculationStatus, isShift, byHour) => ({ ...substate, calculationStatus, isShift, byHour }),
  );

  export const makeSelectResultTableMHE = () =>
    createSelector(
      selectResultTableMhe,
      resultCalculationStatus,
      resultIsShift,
      resultByHour,
      (substate, calculationStatus, isShift, byHour) => ({ ...substate, calculationStatus, isShift, byHour }),
    );
  
    export const makeSelectResultTableRole = () =>
    createSelector(
      selectResultTableRole,
      resultCalculationStatus,
      resultIsShift,
      resultByHour,
      (substate, calculationStatus, isShift, byHour) => ({ ...substate, calculationStatus, isShift, byHour }),
    );

export const makeSelectTrendData = () =>
  createSelector(
    selectPlanResultPageDomain,
    substate => substate.get('graphDataTrendFiltered'),
  );

export const selectTrendSettings = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('graphDataTrendSettings'),
);

export const selectActivityData = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('graphDataActivityFiltered'),
);

export const makeSelectDiloData = () =>
  createSelector(
    selectPlanResultPageDomain,
    substate => substate.get('graphDataDiloFiltered'),
  );

export const makeSelectorByPath = path =>
  createSelector(
    state => state.getIn(path),
    substate => substate,
  );

export const selectPlan = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('plan'),
);

export const selectPlanId = createSelector(
  selectPlan,
  plan => (plan && plan.id) || null,
);

export const selectActivitiesByPlan = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('activitiesByPlan'),
);

export const getResult = state => state.getIn(['planResultPage', 'results']);

export const getSubShiftResult = state => state.getIn(['planResultPage', 'subshiftresults']);

export const formDataSelector = makeSelectFormByName(FORM_NAME);

export const getHeadsGraphData = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('graphHeahs'),
);

export const mappingOMSCodesState = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('mappingOMSCodes'),
);

export const selectShowValueResult = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('showValue'),
);

export const selectShowFormulaResult = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('showFormula'),
);

export const getVolumeRawData = createSelector(
  selectPlanResultPageDomain,
  substate => substate.getIn(['plan', 'planningParameters', 'volumeCategoryParametersRaw'])
)

export const getMasterPlanVolumeRawData = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('masterPlanvolumeCategoryParametersRaw')
)

export const selectMasterPlan = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('masterPlan'),
);

export const selectPlanBasicDetails = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('planBasicDetails')
);

export const selectFetchStatus = createSelector(
  selectPlanResultPageDomain,
  substate => substate.get('fetchStatus'),
);