/*
 *
 *  List Page
 *
 */

import {
  DEFAULT_ACTION,
  LOAD_LIST,
  LOAD_LIST_SUC,
  REMOVE_SELECTED,
  REMOVE_SELECTED_SUC,
  ROW_SELECTED,
  ROW_CHANGED,
  ENABLE_SELECTED,
  CHANGE_FILTER,
  CHANGE_VIEW,
  MASTER_PLAN_REPORT_RUNS,
  SMARTPROD_SOURCES_REPORT_RUNS,
  RESET_MASTER_PLAN_REPORT_RUNS,
  CONFIRM_MASTER_PLAN_REPORT_RUNS,
  LIST_LOADER,
} from './constants';

export function changeViewAction(payload) {
  return {
    type: CHANGE_VIEW,
    payload,
  };
}

export function enableSelectedAction(payload) {
  return {
    type: ENABLE_SELECTED,
    payload,
  };
}

export function defaultAction(params, enabled) {
  return {
    type: DEFAULT_ACTION,
    params,
    enabled,
  };
}

export function loadListSucAction(data) {
  return {
    type: LOAD_LIST_SUC,
    data,
  };
}

export function listLoader(payload){
  return {
    type: LIST_LOADER,
    payload,
  };
}

export function loadListAction(payload) {
  return {
    type: LOAD_LIST,
    payload
  };
}

export function removeSelectedAction() {
  return {
    type: REMOVE_SELECTED,
  };
}

export function removeSelectedSucAction() {
  return {
    type: REMOVE_SELECTED_SUC,
  };
}

export function rowSelectedAction(data) {
  return {
    type: ROW_SELECTED,
    data: data.data,
  };
}

export function rowChangedAction(data) {
  return {
    type: ROW_CHANGED,
    data: data,
  };
}

export function changeFilterAction(payload) {
  return {
    type: CHANGE_FILTER,
    payload,
  };
}

export function masterPlanReportsRun( params){
  return {
    type: MASTER_PLAN_REPORT_RUNS,
    params,
  }
};

export function resetmasterPlanReportsRun(){
  return {
    type: RESET_MASTER_PLAN_REPORT_RUNS,
  }
};

export function smartProdSourcesRun(params){
  return {
    type: SMARTPROD_SOURCES_REPORT_RUNS,
    params,
  }
};

export function confirmMasterPlanReportRuns(start, end){
  return {
    type: CONFIRM_MASTER_PLAN_REPORT_RUNS,
    start,
    end
  }
};
