/*
 *
 * PlanningAreaDetailPage actions
 *
 */

import {
  ADD_BUDGET_VOLUME_LINE,
  ADD_PA_ACTIVITY_LINE,
  ADD_PA_MHE_OPEN,
  DELETE_BUDGET_VOLUME,
  DELETE_PA_ACTIVITY_LINES,
  LOAD_PA,
  LOAD_PA_DETAILS,
  LOAD_PA_OVERVIEW_DATA,
  MODIFY_PA_ACTIVITY_LINE,
  SAVE_PA_BASIC_SETTINGS,
  STORE_PA,
  STORE_PA_GUI_STATE_ITEM,
  NAVIGATE_TO_PLANS,
  CREATE_PLAN,
  START_PA_COPY,
  NAVIGATE_PA_COPY,
  SAVE_PA_PLANNING_PARAMETERS,
  CLEAR_AREA_MHE,
  SAVE_PA,
  SAVE_PA_DONE,
  SWITCH_TO_DISPLAY_PA,
  TOGGLE_SECTION_PA,
  ADD_PERIOD_PA,
  DELETE_PERIOD_PA,
  STORE_PA_PERIOD_INDEX,
  COPY_PERIOD_PA,
  ADD_LABOUR_AVAILABILITY_CATEGORY_LINE_PA,
  DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE_PA,
  ADD_SHIFT_SETTINGS_LINE_PA,
  DELETE_SHIFT_SETTINGS_LINE_PA,
  ADD_WZP_SETTINGS_LINE_PA,
  DELETE_WZP_SETTINGS_LINE_PA,
  SUBMIT_ERROR,
  SAVE_MASTER_PLAN_CONFLICT,
  CLEAR_MASTER_PLAN_CONFLICT,
  SAVE_LABOUR_OVERVIEW,
  SAVE_MHE_OVERVIEW,
  PLANNINGAREA_DETAILS_LOADER,
  RESET_PLANNING_AREA,
  ADD_PA_SHIFT_OPEN,
  ADD_ADJUSTMENT_LINE,
  DELETE_ADJUSTMENT_LINE,
  SAVE_ADJUSTMENT_OVERVIEW,
  SAVE_ROLES,
  ADD_PA_ROLE_OPEN,
  SAVE_PLANINGAREA_SMART_CODE_MAPPINGS,
  PLANNINGAREA_DETAILS_LOADER_FLAG,
  PLANNINGAREA_DETAILS_LOADER_OVERVIEW_FLAG,
  TOGGLE_VOLUME_VALUE,
  TOGGLE_VOLUME_FORMULA,
  TOGGLE_VOLUME_VALUE_VAR,
  TOGGLE_VOLUME_FORMULA_VAR,
  OMS_CHANGE_HISTORY,
  SAVE_OMS_CHANGE_HISTORY,
} from './constants';

export function clearAreaMHEAction() {
  return { type: CLEAR_AREA_MHE };
}

export function loadPlanningArea(id, flags) {
  return {
    type: LOAD_PA,
    payload: { id, ...flags },
  };
}

export function loadPlanningAreaDetails(payload){
  return {
    type: LOAD_PA_DETAILS,
    payload
  }
}

export function loadPlanningAreaOverviewData(payload){
  return {
    type: LOAD_PA_OVERVIEW_DATA,
    payload
  }
}

export function storePlanningArea(payload) {
  return {
    type: STORE_PA,
    payload,
  };
}

export function storePaEdit(payload) {
  return {
    type: STORE_PA_GUI_STATE_ITEM,
    payload: { field: 'edit', value: payload },
  };
}

export function storePaLoading(value) {
  return {
    type: STORE_PA_GUI_STATE_ITEM,
    payload: { field: 'loading', value },
  };
}

export function savePlanningAreaBasicSettings(payload) {
  return {
    type: SAVE_PA_BASIC_SETTINGS,
    payload,
  };
}

export function savePlanningAreaPlanningParameters(payload) {
  return {
    type: SAVE_PA_PLANNING_PARAMETERS,
    payload,
  };
}

export function clearMasterPlanConflict() {
  return {
    type: CLEAR_MASTER_PLAN_CONFLICT,
  };
}

export function saveMasterPlanConflict(payload) {
  return {
    type: SAVE_MASTER_PLAN_CONFLICT,
    payload,
  };
}

export function savePlanningAreaError() {
  return { type: SUBMIT_ERROR };
}

export function addBudgetVolumeLine(payload) {
  return {
    type: ADD_BUDGET_VOLUME_LINE,
    payload,
  };
}

export function deleteBudgetVolume(payload) {
  return {
    type: DELETE_BUDGET_VOLUME,
    payload,
  };
}

export function addPaActivityLine(payload) {
  return {
    type: ADD_PA_ACTIVITY_LINE,
    payload,
  };
}

export function modifyPaActivityLine(payload) {
  return {
    type: MODIFY_PA_ACTIVITY_LINE,
    payload,
  };
}

export function deletePaActivityLines(payload) {
  return {
    type: DELETE_PA_ACTIVITY_LINES,
    payload,
  };
}

export function addPaMHEOpenAction(payload) {
  return {
    type: ADD_PA_MHE_OPEN,
    payload,
    mhe: true
  };
}

export function addPaShiftOpenAction(payload) {
  return {
    type: ADD_PA_SHIFT_OPEN,
    payload,
    shift: true
  };
}

export function addPaROleOpenAction(payload) {
  return {
    type: ADD_PA_ROLE_OPEN,
    payload,
    role: true
  };
}

export function navigateToPLansAction(payload) {
  return {
    type: NAVIGATE_TO_PLANS,
    payload,
  };
}

export function createPlanAction(payload) {
  return {
    type: CREATE_PLAN,
    payload,
  };
}

export function startPaCopyAction(payload) {
  return {
    type: START_PA_COPY,
    payload,
  };
}

export function navigatePaCopy(payload) {
  return {
    type: NAVIGATE_PA_COPY,
    payload,
  };
}

export function savePlanningArea(payload) {
  return {
    type: SAVE_PA,
    payload,
  };
}

export function savePlanningAreaDone(payload) {
  return {
    type: SAVE_PA_DONE,
    payload,
  };
}

export function switchPaToDisplayMode(payload) {
  return {
    type: SWITCH_TO_DISPLAY_PA,
    payload,
  };
}

export function togglePaSection(payload) {
  return {
    type: TOGGLE_SECTION_PA,
    payload,
  };
}

export function addPeriodPaAction(payload) {
  return {
    type: ADD_PERIOD_PA,
    payload,
  };
}

export function deletePeriodPaAction(payload) {
  return {
    type: DELETE_PERIOD_PA,
    payload,
  };
}

export function setPeriodIndexPaAction(payload) {
  return {
    type: STORE_PA_PERIOD_INDEX,
    payload,
  };
}

export function copyPeriodPaAction(payload) {
  return {
    type: COPY_PERIOD_PA,
    payload,
  };
}
export function addLabourAvailabilityCategoryLinePa(payload) {
  return {
    type: ADD_LABOUR_AVAILABILITY_CATEGORY_LINE_PA,
    payload,
  };
}
export function deleteLabourAvailabilityCategoryLinePa(payload) {
  return {
    type: DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE_PA,
    payload,
  };
}
export function addShiftSettingsLinePa(payload) {
  return {
    type: ADD_SHIFT_SETTINGS_LINE_PA,
    payload,
  };
}
export function deleteShiftSettingsLinePa(payload) {
  return {
    type: DELETE_SHIFT_SETTINGS_LINE_PA,
    payload,
  };
}

export function addWZPSettingsLinePa(payload) {
  return {
    type: ADD_WZP_SETTINGS_LINE_PA,
    payload,
  };
}
export function deleteWZPSettingsLinePa(payload) {
  return {
    type: DELETE_WZP_SETTINGS_LINE_PA,
    payload,
  };
}

export function saveLabourOverView(payload, id, granularity){
  return {
    type : SAVE_LABOUR_OVERVIEW,
    payload,
    id,
    granularity
  }
}

export function saveMheOverview(payload, id, granularity){
  return {
    type : SAVE_MHE_OVERVIEW,
    payload,
    id,
    granularity
  }
}

export function planningAreaDetailsLoader(payload){
  return {
    type : PLANNINGAREA_DETAILS_LOADER,
    payload
  }
}

export function planningAreaDetailsLoaderFlag(payload){
  return {
    type : PLANNINGAREA_DETAILS_LOADER_FLAG,
    payload
  }
}

export function planningAreaDetailsOverviewFlag(payload){
  return {
    type : PLANNINGAREA_DETAILS_LOADER_OVERVIEW_FLAG,
    payload
  }
}

export function resetPlanningArea(){
  return {
    type: RESET_PLANNING_AREA
  }
}


export function deletePaAdjustmentLines(payload){
  return {
    type: DELETE_ADJUSTMENT_LINE,
    payload,
  };
}

export function addPaAdjustmentsline(payload) {
  return {
    type: ADD_ADJUSTMENT_LINE,
    payload,
  };
}

export function saveAdjustmentOverview(payload, id, granularity){
  return {
    type : SAVE_ADJUSTMENT_OVERVIEW,
    payload,
    id,
    granularity
  }
}

export function storeRole(payload){
  return {
    type: SAVE_ROLES,
    payload
  }
}

export function savePlanSmartCodeMappings(payload) {
  return {
    type: SAVE_PLANINGAREA_SMART_CODE_MAPPINGS,
    payload,
  };
}

export function toggleVolumeValuePa(payload){
  return {
    type: TOGGLE_VOLUME_VALUE,
    payload
  }
}

export function toggleVolumeFormulaPa(payload){
  return {
    type: TOGGLE_VOLUME_FORMULA,
    payload
  }
}

export function toggleVolumeValuePaVar(payload){
  return {
    type: TOGGLE_VOLUME_VALUE_VAR,
    payload
  }
}

export function toggleVolumeFormulaPaVar(payload){
  return {
    type: TOGGLE_VOLUME_FORMULA_VAR,
    payload
  }
}

export function omChangeHistory(){
  return {
    type: OMS_CHANGE_HISTORY
  }
}

export function saveomsChangeHistory(payload){
  return {
    type: SAVE_OMS_CHANGE_HISTORY,
    payload
  }
}