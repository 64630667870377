import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

/* eslint-disable react/prefer-stateless-function */
export class UrlUpdateWatcher extends React.PureComponent {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return <>{this.props.children}</>;
  }
}

UrlUpdateWatcher.propTypes = {
  onMount: PropTypes.func,
  children: PropTypes.node,
};

export function loadSearch(props) {
  return { get: key => parseSearch(key, props), keys: parseKeys(props) };
}

export const parserPlanResult = {
  startDate: val => val && DateTime.fromISO(val),
  endDate: val => val && DateTime.fromISO(val),
  planId: val => Number(val),
  planningParametersId: val => Number(val),
  showBudget: val => val === 'true',
  showDirect: val => val === 'true',
  showEffort: val => val === 'true',
  showFte: val => val === 'true',
  showIndirect: val => val === 'true',
  showPlanned: val => val === 'true',
  showProductivityRate: val => val === 'true',
  showVolume: val => val === 'true',
  showLabourAvailability: val => val === 'true',
  showShiftLabourAvailability: val => val === 'true',
  showHeads: val => val === 'true',
  showHeadsOpt: val => val === 'true',
  showActuals: val => val === 'true',
  showForecast: val => val === 'true',
  includeMhe: val => val === 'true',
  includeRole: val => val === 'true',
};

export function dataToSearch(dataInput, merge = false, parser) {
  let data;
  if (merge) {
    const searchCurrent = global.location.search;
    data = { ...searchToData({ location: { search: searchCurrent } }, parser), ...dataInput };
  } else {
    data = dataInput;
  }
  if (data) {
    const items = [];
    Object.keys(data).forEach(key => {
      const value = data[key];
      if (value !== null) items.push(`${encodeURI(key)}=${encodeURI(value)}`);
    });
    return `?${items.join('&')}`;
  }
  return '';
}

export function searchToData(props, parser) {
  const result = {};
  parseKeys(props, (key, value) => {
    if (parser && parser[key]) {
      result[key] = parser[key](value);
    } else {
      result[key] = value;
    }
  });
  return result;
}

export function parseKeys(props, itemCb) {
  const result = [];
  const search = global.location.search || (props && props.location && props.location.search);
  if (search) {
    const regex = new RegExp(`\\?*([^=]+)=[^&]+&*`, 'g');
    const match = search.match(regex);
    const regexItem = new RegExp(`\\?*([^=]+)=([^&]+)&*`);
    match.forEach(item => {
      const matchItem = item.match(regexItem);
      const key = matchItem[1];
      const value = matchItem[2];
      result.push(key);
      if (itemCb) itemCb(key, decodeURIComponent(value));
    });
  }
  return result;
}

export function parseSearch(key, props) {
  if (props && props.location && props.location.search) {
    const regex = new RegExp(`^\\?.*${key}=([^&]+).*$`);
    const match = props.location.search.match(regex);
    if (match) {
      const value = match[1];
      return decodeURIComponent(value);
    }
  }
  return undefined;
}
